import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getKeycloak from 'utils/keycloak/keycloak'
import { getTenantSubdomain } from 'utils/tenant'
import { getTenantRealm } from 'utils/auth'
import { storeUserFirstLogin, useRhssoRefreshTokenListener } from './keycloakEvents'
import { logout, refreshRHSSOToken } from 'redux/actions/auth'
import { TOKEN_ERROR } from 'utils/api'
import { handleMainErrors } from 'redux/middlewares/enhancedThunk'
import { removeItemFromLocalStorage } from 'utils/storage'

export const KeycloakCTX = createContext(null)

export const KeycloakProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null)
  const reduxState = useSelector((state) => state)
  const hasRHSSO = useSelector((state) => state.config.migratedToRHSSO)
  const subdomain = getTenantSubdomain()
  const tenantType = useSelector((state) => state.config.tenantType)
  const realm = tenantType && getTenantRealm(tenantType)
  const dispatch = useDispatch()

  const onRhssoError = (keycloakInstance) => (e) => {
    console.error('Error interacting with keycloak', e)
    handleMainErrors({ message: TOKEN_ERROR }, dispatch, reduxState)
    dispatch(logout(keycloakInstance))
  }

  const handleRefreshToken = (keycloakInstance) => () => {
    console.log('Keycloak provider is refreshing the token...')
    refreshRHSSOToken({ keycloak: keycloakInstance, dispatch, state: reduxState })
  }

  const onRhssoTokenExpired = (keycloakInstance) => () => {
    console.log('An attempt will be made to refresh the token since it has expired...')
    refreshRHSSOToken({ keycloak: keycloakInstance, dispatch, state: reduxState })
  }

  useEffect(() => {
    const initKeycloak = async () => {
      const keycloakInstance = getKeycloak(realm, subdomain)
      try {
        const authenticated = await keycloakInstance.init({ onLoad: 'check-sso', checkLoginIframe: false })
        keycloakInstance.onAuthError = onRhssoError(keycloakInstance)
        keycloakInstance.onAuthRefreshError = onRhssoError(keycloakInstance)
        keycloakInstance.onTokenExpired = onRhssoTokenExpired(keycloakInstance)
        if (!authenticated) {
          removeItemFromLocalStorage('entryRoute')
          keycloakInstance.login()
        }

        console.log('Authenticating in rsso...', authenticated)
        keycloakInstance.isFirstLogin = storeUserFirstLogin(keycloakInstance)

        window.keycloakInstance = keycloakInstance
        setKeycloak(keycloakInstance)
      } catch (error) {
        console.log('Error creating keycloak instance...', error)
      }
    }

    if (hasRHSSO) {
      initKeycloak()
    }
  }, [hasRHSSO])

  useEffect(() => {
    useRhssoRefreshTokenListener(keycloak, handleRefreshToken(keycloak))
  }, [keycloak])

  return <KeycloakCTX.Provider value={keycloak}>{children}</KeycloakCTX.Provider>
}

export const useKeycloak = () => {
  const keycloak = useContext(KeycloakCTX)
  const hasRHSSO = useSelector((state) => state.config.migratedToRHSSO)

  return { keycloak, hasRHSSO }
}
