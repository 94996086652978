import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { getQuery } from 'utils/query'
import { showMessage } from 'redux/actions/modal'
import { logout } from 'redux/actions/auth'
import { KeycloakCTX } from '../components/Keycloak/KeycloakProvider'

/**
 * Reset the App's Redux Store to its initial state and logs out the User.
 *
 * Reset functionality is triggered when the User adds the following GET parameter in the URI: `?clear-state`.
 * If `ResetContainer` wraps the App's routing component (as it's now), then the GET parameter will be available
 * on any possible route / page.
 */

const ResetContainer = (props) => {
  const keycloak = useContext(KeycloakCTX)
  const { shouldReset, children, showMessage } = props

  useEffect(() => {
    if (shouldReset) {
      logout(keycloak)
      window.location.href = '/'
      showMessage({
        title: 'Application data is successfully cleared!',
        body: 'If the issue persists contact us at support@payslip.com!',
      })
    }
  })

  if (shouldReset) return <div />

  return children
}

const mapDispatchToProps = { logout, showMessage }

const mapStateToProps = (state) => {
  const query = getQuery(window.location.search)
  // We should reset the app, if `clear-state` parameter exists in the query GET parameters.
  // Its existence is enough for our check. We don't care about the parameter's value.
  const shouldReset = query['clear-state'] !== undefined

  return { shouldReset }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetContainer)
