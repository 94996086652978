import createSelector from 'utils/createSelector'
import { makeFilteredIdsByFilter } from 'redux/selectors/filters'
import { getKeyPeopleRole } from 'redux/models/employeeSystemUser'
import { getDocumentUsersByDocIdAndDocTenant } from './documentUsers'
import allCountries from 'redux/config/allCountries'
import { isCot, isPayrollAdmin, isPayrollAnalyst } from 'redux/selectors/auth'
import { getPayrollProcessTree } from 'routes/Companies/routes/Company/routes/DatesProcesses/routes/Payroll/selectors/payrollServiceORM'
import { getPayrollInstanceProcessTreeByPayrollInstance } from 'routes/Payruns/routes/ChangeDetails/selectors/payrollProcessORM'
import _ from 'lodash'

const getUserId = (state, props) => parseInt(props.userId)
const getAuthUserId = (state) => parseInt(state.auth.userId)
const getEmployeeId = (state, props) => parseInt(props.employeeId)
const isLoggedUserPayrollAdmin = (state) => isPayrollAdmin(state)
const isLoggedUserPayrollAnalyst = (state) => isPayrollAnalyst(state)

const getFilteredIds = makeFilteredIdsByFilter('employeeSystemUsers')

export const getFilteredEmployees = createSelector(getFilteredIds, ({ EmployeeSystemUser }, filteredIds) =>
  filteredIds.map((id) => EmployeeSystemUser.withId(id).ref)
)

export const getFilteredDecoratedEmployees = createSelector(getFilteredIds, ({ EmployeeSystemUser }, filteredIds) =>
  filteredIds.map((id) => {
    const employee = EmployeeSystemUser.withId(id)

    return {
      ...employee.ref,
      name: employee.getName(),
    }
  })
)

export const getUser = createSelector(getUserId, ({ EmployeeSystemUser }, userId) => {
  const user = EmployeeSystemUser.withId(userId)
  return {
    ...user.ref,
  }
})

export const getUserWithSchema = createSelector(getUserId, ({ EmployeeSystemUser }, userId) => {
  const user = EmployeeSystemUser.withId(userId)
  return {
    ...user.ref,
    schema: user.company.parentCompany.schema,
  }
})

export const getUserWithCompany = createSelector(getUserId, ({ EmployeeSystemUser }, userId) => {
  const user = EmployeeSystemUser.withId(userId)
  return {
    ...user.ref,
    ...(user.company ? { company: { ...user.company.ref } } : {}),
    ...(user.company ? { country: { ...user.company.country.ref } } : {}),
  }
})

export const getEmployees = createSelector(getFilteredIds, ({ EmployeeSystemUser }, filteredEmployees) => {
  return filteredEmployees.map((id) => buildEmployee(EmployeeSystemUser.withId(id))).filter((employee) => employee.isEmployee && employee.isAccessable)
})

export const buildEmployee = (employee) => ({
  ...employee.ref,
  company: employee.company.ref,
  businessUnit: employee.businessUnit ? employee.businessUnit.ref : null,
})

const getCompanyId = (state, props) => parseInt(props.companyId)
const getAccessAreasIds = (state, props) => props.accessAreasIds

export const getKeyPeopleByAccessArea = createSelector(getCompanyId, getDocumentUsersByDocIdAndDocTenant, ({ EmployeeSystemUser }, companyId, docUsers) => {
  return _getKeyPeopleByAccessAreaId(EmployeeSystemUser, companyId, docUsers)
})

export const getKeyPeopleByAccessAreaWithSharedDocument = createSelector(getKeyPeopleByAccessArea, (session, keyPeople) => {
  return keyPeople.filter((user) => user.share)
})

// eslint-disable-next-line max-len
export const getKeyPeopleByAccessAreaWithoutLoggedInUser = createSelector(
  getKeyPeopleByAccessArea,
  getUserId,
  isLoggedUserPayrollAdmin,
  isLoggedUserPayrollAnalyst,
  (session, keyPeople, userId, isPayrollAdmin, isPayrollAnalyst) => {
    if (!isPayrollAdmin && !isPayrollAnalyst) return keyPeople
    return keyPeople.filter((keyPeople) => keyPeople.id !== userId)
  }
)

const _getOwnersFromTree = (tree) => {
  let owners = []

  if (tree.owner) {
    owners.push(tree.owner.id)
  }

  tree.steps.map((step) => {
    step.tasks.map((task) => {
      if (task.owner && !_.includes(owners, task.owner.id)) owners.push(task.owner.id)
    })
  })
  return owners
}

export const getEmployeeWithSharedDocument = createSelector(
  getEmployeeId,
  getDocumentUsersByDocIdAndDocTenant,
  ({ EmployeeSystemUser }, employeeId, docUsers) => {
    const employee = EmployeeSystemUser.withId(employeeId)

    const schema = employee.company.parentCompany.schema
    const file = docUsers.find((docUser) => docUser.userId === employee.id && docUser.userTenant === schema)
    const name = employee.getName()
    let keyPeopleRole = getKeyPeopleRole(employee.roleType)
    const isKeyPerson = keyPeopleRole !== undefined
    const roleTypeName = isKeyPerson ? keyPeopleRole.name : 'Employee'

    return {
      ...employee.ref,
      share: file !== undefined,
      schema,
      name,
      roleTypeName,
      isKeyPerson,
    }
  }
)

// Get all key people and check if file is shared with them
export const getKeyPeopleByAccessAreaWithSharedDocumentsWithoutLoggedInUser = createSelector(
  getPayrollProcessTree,
  getPayrollInstanceProcessTreeByPayrollInstance,
  getKeyPeopleByAccessAreaWithoutLoggedInUser,
  getDocumentUsersByDocIdAndDocTenant,
  (session, payrollProcessTree, payrollInstanceProcessTree, users, docUsers) => {
    // Keep the process owner id and all the action owners ids
    const tree = payrollProcessTree || payrollInstanceProcessTree
    const owners = tree ? _getOwnersFromTree(tree) : []
    return (
      users
        // Filter users to exclude action owners from key people if needed
        .filter((user) => !_.includes(owners, user.id))
        .map((user) => {
          const file = docUsers.find((docUser) => docUser.userId === user.id && docUser.userTenant === user.schema)
          return {
            ...user,
            share: file !== undefined,
          }
        })
    )
  }
)

export const getLoggedInUserId = (state) => state.auth.userId
export const getKeyPeopleWithSharedDocumentsWithoutLoggedInUser = createSelector(
  (state, filterName) => {
    const entity = 'employeeSystemUsers'
    return state[entity].filters[filterName]?.ids
  },
  getLoggedInUserId,
  getDocumentUsersByDocIdAndDocTenant,
  ({ EmployeeSystemUser }, filteredIds = [], loggedUserId, docUsers) => {
    return (
      EmployeeSystemUser.all()
        .toModelArray()
        // get only filtered matches
        .filter((item) => filteredIds.includes(item.id))
        // add user schema to response
        .map((user) => {
          const schema = user.company.parentCompany.schema
          return {
            ...user,
            id: user.id,
            schema,
          }
        })
        // exclude logged in user
        .filter((user) => user.id !== loggedUserId)
        // add document share status to response, id, name and roleType user friendly name
        .map((user) => {
          const file = docUsers.find((docUser) => docUser.userId === user.id && docUser.userTenant === user.schema)
          return {
            ...user,
            name: user.fullname,
            roleTypeName: getKeyPeopleRole(user.roleType).name,
            share: file !== undefined,
          }
        })
        .sort((a, b) => filteredIds.indexOf(a.id) - filteredIds.indexOf(b.id))
    )
  }
)

export const getKeyPeopleByAccessAreas = createSelector(getAccessAreasIds, ({ EmployeeSystemUser }, accessAreasIds) => {
  let keyPeople = []

  accessAreasIds.map((id) => {
    keyPeople.push(_getKeyPeopleByAccessAreaId(EmployeeSystemUser, id))
  })

  return _.uniqBy(_.flatten(keyPeople), 'id')
})

export const _getKeyPeopleByAccessAreaId = (EmployeeSystemUser, id, docUsers) => {
  return EmployeeSystemUser.filter((employee) => getKeyPeopleRole(employee.roleType))
    .toModelArray()
    .filter((employee) => employee.accessableCompanies.find((companyId) => companyId === id))
    .map((employee) => {
      const schema = employee.company.parentCompany.schema
      const file = docUsers ? docUsers.find((docUser) => docUser.userId === employee.id && docUser.userTenant === schema) : null
      let employeeObj = {
        ...employee.ref,
        id: employee.id,
        name: employee.getName(),
        roleTypeName: getKeyPeopleRole(employee.roleType).name,
        schema,
      }
      if (docUsers) employeeObj['share'] = file !== undefined
      return employeeObj
    })
}

export const _getKeyPeopleByCompany = (EmployeeSystemUser, companyId) => {
  return EmployeeSystemUser.filter((employee) => employee.company === companyId && getKeyPeopleRole(employee.roleType))
    .toModelArray()
    .map((employee) => ({
      id: employee.id,
      name: employee.getName(),
    }))
}

export const getKeyPeople = createSelector(({ EmployeeSystemUser }) => {
  return EmployeeSystemUser.filter((employee) => getKeyPeopleRole(employee.roleType))
    .toModelArray()
    .map((employee) => ({
      ...employee.ref,
      name: employee.getName(),
    }))
})

export const getFilteredDecoratedEmployeesByCompany = createSelector(
  getFilteredDecoratedEmployees,
  getCompanyId,
  ({ EmployeeSystemUser }, employees, companyId) => {
    return employees.filter((employee) => employee.accessableCompanies.includes(companyId))
  }
)

/**
 * Get the key people for the edit container
 *
 * @param int getEmployeeId
 * @return EmployeeSystemUser
 */
export const getKeyPersonWithCountryWithBusinessUnit = createSelector(getUserId, ({ EmployeeSystemUser, Company, BusinessUnit }, id) => {
  // get employee
  let employee = EmployeeSystemUser.withId(id)

  return {
    ...getEmployeeProps(employee, Company, BusinessUnit),
    countriesIds: employee.countries.toRefArray().map((country) => country.id),
  }
})

export const getEmployeeProps = (employee, Company, BusinessUnit) => {
  // add country code to location for employee
  let code = employee.location ? _.find(allCountries, { name: employee.location }).code : null

  const businessUnits = [
    ...getEmployeeAccessCompanies(employee, Company).map((c) => ({ name: c.name, isCompany: true })),
    ...getEmployeeAccessBUs(employee, BusinessUnit).map((bu) => ({ name: bu.name })),
  ]

  return {
    ...employee.ref,
    roleTypeName: employee.roleType !== 'ROLE_EMPLOYEE' ? getKeyPeopleRole(employee.roleType).name : null,
    businessUnit: employee.businessUnit
      ? {
        ...employee.businessUnit.ref,
      }
      : {},
    code,
    accessAreas: employee.ref.accessableCompanies.map((companyId) => Company.withId(companyId).name),
    businessUnits,
    countries: employee.countries.toRefArray().map((country) => country.name),
  }
}

export const getKeyPersonAccessAreasInitials = createSelector(getUserId, ({ EmployeeSystemUser, BusinessUnit, Company }, id) => {
  const employee = EmployeeSystemUser.withId(id)

  const accessCompanies = getEmployeeAccessCompanies(employee, Company).map(({ id }) => id)
  const accessBUs = getEmployeeAccessBUs(employee, BusinessUnit).map(({ id, companyId }) => ({ id, companyId }))

  const accessCompaniesAsBUs = accessCompanies.map((id) =>
    JSON.stringify({
      companyId: id,
      isCompany: true,
    })
  )

  const accessBUsAsCompanies = _.uniq(accessBUs.map((bu) => bu.companyId))

  // Together with the already saved access area Companies,
  // add the Companies these derived from the Business Units.
  // For example: if the key person has access only to the BusinessUnit1 (from Company 1),
  // then the BE won't return Company 1 and here we should manually added it to initial values to the dropdown.
  const accessCompaniesOptions = [...accessCompanies, ...accessBUsAsCompanies]

  // Together with the already saved access area Business Units, add the access area Companies,
  // in order to initially select the `select all business units for this company` dropdown option.
  const accessBUsOptions = [...accessBUs.map((bu) => JSON.stringify(bu)), ...accessCompaniesAsBUs]

  return {
    companies: accessCompaniesOptions,
    businessUnits: accessBUsOptions,
  }
})

const getEmployeeAccessCompanies = (employee, Company) => {
  const accessAreas = employee.accessArea.all().toModelArray()

  return accessAreas
    .filter((aa) => aa.accessableType === 'Payslip\\CoreBundle\\Entity\\Company')
    .map((aa) => {
      const company = Company.withId(aa.accessableId)

      return company.ref
    })
}

const getEmployeeAccessBUs = (employee, BusinessUnit) => {
  const accessAreas = employee.accessArea.all().toModelArray()

  return accessAreas
    .filter((aa) => aa.accessableType === 'Payslip\\CoreBundle\\Entity\\BusinessUnit')
    .map((aa) => {
      const bu = BusinessUnit.withId(aa.accessableId)

      return {
        id: bu.id,
        companyId: bu.company.id,
        name: bu.name,
      }
    })
}

/**
 * Is mobile phone of currently edited employee is disabled for update?
 *
 * @example
 * Here's the case:
 * The mobile phone of the logged user can be updated ONLY from 'My settings'.
 * So when we have a listing of employees, for example the pages where we update employees or key people,
 * then we should forbid the logged in user to update his phone.
 * Because of this we check the logged user and the edited user ids and if they match the field is disabled.
 * That logic isn't valid for COT users, because of two reasons:
 * 1. COT user can update Employees from everywhere.
 * 2. COT users derived from `CotUser` model and it's possible his `id` to be the same as some of the employees ids.
 * If this happen, both ids would match `employeeId === authUserId` and the COT won't be able to update the employee.
 */
export const isMobilePhoneDisabled = createSelector(
  getEmployeeId,
  getAuthUserId,
  isCot,
  (session, employeeId, authUserId, isCot) => !isCot && employeeId === authUserId
)

/**
 * Selector function to retrieve the special rights of an authenticated user.
 *
 * This function utilizes `createSelector` to compute derived state based on
 * the authenticated user's ID. It fetches the user information from
 * the EmployeeSystemUser by the given user ID and extracts the special rights.
 *
 * @param {Function} getAuthUserId - Function to get the authenticated user's ID.
 * @param {Object} EmployeeSystemUser - Object containing methods and properties related to system users.
 * @returns {Object} An object containing the special rights of the authenticated user.
 */
export const getUserSpecialRights = createSelector(getAuthUserId, ({ EmployeeSystemUser }, userId) => {
  const user = EmployeeSystemUser.withId(userId)?.ref
  return user?.specialRight
})
