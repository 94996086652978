import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { detachEmployees } from 'redux/actions/payrolls'
import { showMessage } from 'redux/actions/modal'
import { hasEmployees } from 'redux/helpers/hasEmployees'
import { invalidatePayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'
import EmployeeAttachDetachHOC from 'containers/employee/EmployeeAttachDetachHOC'
import PayrollPaygroupEmployeesContainer from './PayrollPaygroupEmployeesContainer'
import Fetcher from 'containers/Fetcher'
import { getPayrollById } from 'redux/selectors/payroll'
import { getEmployeesWithCompanyByCompanyAttachedToPayroll } from '../selectors/employees'

// say what you're dispatching on init
const mapDispatchToProps = dispatch => ({ dispatch })

// map to state
const mapStateToProps = (state, props) => {
  const { payrolls, paygroups, payrollPaygroupPivot, paygroupEmployeePivot, payrollEmployeePivot } = state
  const entities = [payrolls, paygroups, payrollPaygroupPivot, paygroupEmployeePivot, payrollEmployeePivot]

  if (isFetching(entities)) return { isFetching: true }

  // get selected payroll
  let payroll = getPayrollById(state, { payrollId: props.match.params.payrollId })
  // get view's employees
  let employees = getEmployeesWithCompanyByCompanyAttachedToPayroll(
    state,
    {
      payrollId: payroll.id,
      companyId: payroll.company
    }
  )

  return {
    companyId: payroll.company.id,
    payrollName: payroll.name,
    content: `Are you sure you want to unassign this/these employee/s from ${payroll.name}?`,
    buttonName: 'Remove',
    hasEmployees: () => hasEmployees(state, 'payrollDetachEmployees', employees),
    payrollId: payroll.id,
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { companyId, payrollName } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    toggleAll: (value, employees) => ownProps.updateEmployees('payrollDetachEmployees', employees, value),
    onSubmit: data => dispatch(detachEmployees(data)).then(() => {
      dispatch(invalidatePayrollInstanceEmployeePivot())

      // On successful sync, go back to the company payroll page
      const path = `/companies/${companyId}/payroll`
      ownProps.history.push(path)
      // Display successful message
      dispatch(showMessage({
        body: `Employees successfully unassigned from Payroll ${payrollName}`,
      }))
    })
  }
}

const Container = EmployeeAttachDetachHOC(connect(mapStateToProps, mapDispatchToProps, mergeProps)(PayrollPaygroupEmployeesContainer))

export default Fetcher(Container, [
  'payrollEmployeePivot', 'paygroupEmployeePivot', 'payrollPaygroupPivot', 'payrolls', 'paygroups', 'companies'
])
