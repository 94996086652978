import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import CustomSelectField from 'components/form/select/CustomSelectField'
import FieldDatePicker from 'components/form/FieldDatePicker'
import CheckboxOption from 'components/form/select/CheckboxOption'
import SubmitButtons from './SubmitButtons'
import { isEmpty } from 'utils/fnkit/typeChecks'
import PayrollSelectContainer from '../containers/PayrollSelectContainer'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import { sortByLabel } from 'utils/strings'

class FiltersAuditTrailReport extends React.Component {
  render () {
    const {
      processes,
      selected,
      countries,
      companies,
      owners,
      isFieldDisabled,
      disabledReason,
      setProcessOwner,
      mostRecentRunReport = {},
      readonlyFields,
      hideNavigationButtons,
      navigateToParentOnClick,
      isSinglePayroll,
    } = this.props
    /*
     * Every next filter is shown if the last required field has value
     */
    const isMultiAllowed = !isSinglePayroll
    const shouldShowMostRecentRunReportItem = !this.props.reportItem
    const optionComponent = isMultiAllowed ? CheckboxOption : undefined
    return (
      <div className='o-block--inline'>
        {!isEmpty(selected.subcategory) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='process'
                label='Services *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled || readonlyFields.servicesLocked}
                disabledReason={disabledReason}
                options={processes.map((process) => ({
                  value: process.id,
                  label: process.name,
                }))}
                enableRemovedOptionFix
                data-testid='process-wrapper'
              />
            </div>
          </div>
        )}
        {selected.process && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='From Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='fromDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                data-testid='fromDate'
              />
            </div>
          </div>
        )}
        {selected.process && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='To Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='toDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                data-testid='toDate'
              />
            </div>
          </div>
        )}
        {selected.process && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='country'
                label='Country *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={optionComponent}
                multi={isMultiAllowed}
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={countries
                  .map((country) => ({
                    value: country.id,
                    label: country.name,
                    abbreviature: country.abbreviature,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='country-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.country) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='company'
                label='Company *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={optionComponent}
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi={isMultiAllowed}
                options={companies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='company-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <PayrollSelectContainer
                optionComponent={optionComponent}
                {...(!isFieldDisabled
                  ? {
                    onChanged: (value) => setProcessOwner(),
                  }
                  : {})}
                multi={isMultiAllowed}
                {...this.props}
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payroll) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='processOwner'
                label='Process Owner *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={CheckboxOption}
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={owners.map((owners) => ({
                  value: owners.id,
                  label: owners.name,
                }))}
                enableRemovedOptionFix
                data-testid='processOwner-wrapper'
              />
            </div>
          </div>
        )}
        {!hideNavigationButtons && <SubmitButtons pristineCheck={!isEmpty(selected.processOwner)} onBackClick={navigateToParentOnClick} {...this.props} />}
        {shouldShowMostRecentRunReportItem && (
          <div className='o-layout__item u-padding-left-small u-text--center u-1/1 u-margin-top-small u-padding-top-small'>
            <MostRecentRunReportItem
              mostRecentRunReport={mostRecentRunReport}
              onOpenReport={this.props.handleReportModalOpen}
              onDelete={this.props.deleteReport}
              {...this.props}
            />
          </div>
        )}
      </div>
    )
  }
}

FiltersAuditTrailReport.propTypes = {
  resetFormFields: PropTypes.func,
  processes: PropTypes.array,
  // Props in order to check if the previous filter has a value
  // Show next filter only if the previous is filled
  selected: PropTypes.object,
  // Dropdown options data
  countries: PropTypes.array,
  companies: PropTypes.array,
  payrolls: PropTypes.array,
  owners: PropTypes.array,
  // Disabled fields and submit btn check
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showRunReportBtn: PropTypes.bool,
  setProcessOwner: PropTypes.func,
  navigateToParentOnClick: PropTypes.func,
  mostRecentRunReport: PropTypes.object,
  deleteReport: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
}

export default FiltersAuditTrailReport
