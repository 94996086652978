import Fetcher from 'containers/Fetcher'
import { connect } from 'react-redux'
import { detachEmployees } from 'redux/actions/paygroups'
import { getPaygroupByIdWithEmployeeIds } from '../../../../../selectors/paygroup'
import { showMessage } from 'redux/actions/modal'
import { invalidatePayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'
import EmployeeAttachDetachHOC from 'containers/employee/EmployeeAttachDetachHOC'
import PayrollPaygroupEmployeesContainer from './PayrollPaygroupEmployeesContainer'
import { createFilter } from 'utils/redux/filter'

// say what you're dispatching on init
const mapDispatchToProps = (dispatch) => ({ dispatch })

// map to state
const mapStateToProps = (state, props) => {
  // get selected payroll
  let paygroup = getPaygroupByIdWithEmployeeIds(state, { paygroupId: props.match.params.paygroupId })

  return {
    companyId: paygroup.company,
    paygroupName: paygroup.name,
    employeesWithDifferTerms: [],
    content: `Are you sure you want to unassign this/these employee/s from ${paygroup.name}?`,
    buttonName: 'Remove',
    paygroup,
    initialValues: {
      paygroupId: paygroup.id,
    },
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { companyId, paygroupName } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    toggleAll: (value, employees) => ownProps.updateEmployees('paygroupDetachEmployees', employees, value),
    onSubmit: (data) =>
      dispatch(detachEmployees(data)).then(() => {
        dispatch(invalidatePayrollInstanceEmployeePivot())

        // On successful sync, go back to the company payroll page
        const path = `/companies/${companyId}/payroll`
        ownProps.history.push(path)
        // Display successful message
        dispatch(
          showMessage({
            body: `Employees successfully unassigned from Paygroup ${paygroupName}`,
          })
        )
      }),
  }
}

const Container = EmployeeAttachDetachHOC(connect(mapStateToProps, mapDispatchToProps, mergeProps)(PayrollPaygroupEmployeesContainer))

export default Fetcher(Container, [
  'paygroups',
  'paygroupEmployeePivot',
  'companies',
  {
    name: 'employeeSystemUsers',
    params: [
      {
        _computed: {
          filter: (
            state,
            {
              paygroup,
              match: {
                params: { paygroupId },
              },
            }
          ) => {
            return createFilter({
              paygroup: paygroupId,
              payloadFormat: 'reduced',
            })
          },
        },
        disableObsoleteFlow: true,
      },
    ],
  },
])
