import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Moment from 'components/Moment'
import { Authorization } from 'containers/Authorization'
import reportOptionsConfig, { WhiteListedReports } from 'configs/reports'
import { batch, useDispatch, useSelector } from 'react-redux'
import { isCot, isVendor } from 'redux/selectors/auth'
import { getCompletedReportCreateJobs } from 'redux/selectors/pollingJobs'
import { flagAsRead } from 'redux/actions/pollingJob'
import { toast } from 'react-toastify'
import { reportsWithNoPayrollFilter } from '../utils/reports'
import { RetryBaseIcon } from 'components/icons/RetryIcons'
import { DownloadReportButton } from 'routes/Reporting/components/DownloadReportButton'

const classNames = require('classnames')

const RecentReportItem = (props) => {
  const [showFiltersList, setShowFiltersList] = useState(false)
  const dispatch = useDispatch()

  const { report, onDelete, onSaveReport } = props
  const { cat, subCat } = report

  const inProgressPollingJobs = useSelector((state) => getCompletedReportCreateJobs(state))
  const isVendorUser = useSelector((state) => isVendor(state))
  const shouldUseDefaultApi = useSelector((state) => isCot(state))

  const job = inProgressPollingJobs ? inProgressPollingJobs[0] : null

  const getBaseApiUsage = () => {
    let baseApiUsage = { forceDefaultAPI: shouldUseDefaultApi }
    if (isVendorUser) {
      baseApiUsage = { forceDefaultAPI: false }
    }
    return baseApiUsage
  }

  const onDeletePress = (id) => {
    if (job) {
      batch(() => {
        dispatch(flagAsRead(job.id, job, getBaseApiUsage()))
        toast.dismiss(job.id)
      })
    }
    onDelete(id)
  }

  /*
   * Toggle filters on every recent report item
   */
  const toggleFilters = () => {
    setShowFiltersList(!showFiltersList)
  }

  /*
   * Arrow classes by toggling filters
   */
  const getArrowClasses = () => {
    return classNames({
      'icon--arrow u-float--left u-margin-left-tiny u-margin-top-tiny': true,
      active: showFiltersList,
    })
  }

  const handleRegenerateReport = () => {
    let pathToGo = `/reporting/customize-report`
    const isCustomReport = report?.feCategoryId === reportOptionsConfig.CUSTOM_BUILD_REPORT.VALUE

    if (isCustomReport) {
      pathToGo = `/reporting/report-designer`
    }

    const historyStateData = {
      feCategoryId: report?.feCategoryId,
      report,
    }

    props.history.push(pathToGo, historyStateData)
  }

  const showPayrollFilter = !reportsWithNoPayrollFilter.includes(report.type)
  const hasGraphs = WhiteListedReports.includes(report.name)

  return (
    <div key={report.id} className='o-layout__item u-1/1'>
      <div className='c-panel u-bg--white u-padding-small rounded border-1-valhalla hover--shadow-md u-margin-bottom'>
        <div className='o-layout o-grid o-grid--middle'>
          <div className='o-layout__item u-padding-left-none u-1/1 u-2/3@tablet u-4/6@desktop'>
            <div>
              {report.categoryName ? (
                <span className='u-text--xtra-huge u-text--minsk'>
                  <span className='u-weight--bold'>{report.categoryName}</span>
                  <span className='u-text--xtra-huge u-text--minsk'> / {report.name}</span>
                </span>
              ) : (
                <span className='u-text--xtra-huge u-text--minsk'>{report.name}</span>
              )}
            </div>
            <div className='o-block o-block--left u-test--small u-margin-top-small'>
              <span>
                <Moment date={report.createdAt.date} format='DD/MM/YYYY HH:mm' />
                {report.createdAt.timezone ? ` ${report.createdAt.timezone} ` : null}
              </span>
              <span>{cat === subCat ? cat : `${cat}, ${subCat}`}, </span>
              {report.filters.fromDate ? <Moment format='DD/MM/YYYY'>{report.filters.fromDate}</Moment> : 'Any date'}
              &nbsp;-&nbsp;
              {report.filters.toDate ? <Moment format='DD/MM/YYYY'>{report.filters.toDate}</Moment> : 'Any date'}
            </div>
            <div className='o-block o-block--left'>
              <div onClick={() => toggleFilters()} className='u-text--small u-cursor--pointer'>
                <span className='u-float--left'>{showFiltersList ? 'Hide filters' : 'Show filters'}</span>
                <span className={getArrowClasses()} />
              </div>
              <br />
              {/* Filters list */}
              {showFiltersList && (
                <div className='u-text--small'>
                  <span className='u-weight--bold'>Country: </span>
                  <span>{report.filters.countryNames.length ? report.filters.countryNames.join(', ') : 'Any'}</span>
                  <br />
                  <span className='u-weight--bold'>Company: </span>
                  <span>{report.filters.companyNames.length ? report.filters.companyNames.join(', ') : 'Any'}</span>
                  <br />
                  {showPayrollFilter && (
                    <span>
                      <span className='u-weight--bold'>Payroll: </span>
                      <span>{report.filters.payrollNames.length ? report.filters.payrollNames.join(', ') : 'Any'}</span>
                      <br />
                    </span>
                  )}
                  {report.type === 'EmployeeReport' && (
                    <span>
                      <span className='u-weight--bold'> Type of Employment/Engagement: </span>
                      <span>{report.filters.employmentStatus.length ? report.filters.employmentStatus.join(', ') : 'Any'}</span>
                      <br />
                      <span className='u-weight--bold'>Business Unit: </span>
                      <span>{report.filters.businessUnitNames.length ? report.filters.businessUnitNames.join(', ') : 'Any'}</span>
                      <br />
                      <span className='u-weight--bold'>Cost Center: </span>
                      <span>{report.filters.costCenterNames.length ? report.filters.costCenterNames.join(', ') : 'Any'}</span>
                      <br />
                    </span>
                  )}
                  {report.type === 'AuditTrailReport' && (
                    <span>
                      <span className='u-weight--bold'>Process owner: </span>
                      <span>{report.filters.processOwnerNames.length ? report.filters.processOwnerNames.join(', ') : 'Any'}</span>
                      <br />
                    </span>
                  )}
                  {['PayrollInstanceReport', 'EmployeeDataChangesReport', 'VarianceReport', 'GlobalPayrunManagementReport'].includes(report.type) && (
                    <span>
                      <span className='u-weight--bold'>Payrun: </span>
                      <span>{report.filters.payrollInstanceNames.length ? report.filters.payrollInstanceNames.join(', ') : 'Any'}</span>
                      <br />
                    </span>
                  )}
                  {(report.type === 'PayrollReport' || report.type === 'PayrollInstanceReport' || report.type === 'GlobalPayrunManagementReport') && (
                    <span>
                      <span className='u-weight--bold'>Show Only Totals: </span>
                      <span>{report.filters.showOnlyTotals ? 'Yes' : 'No'}</span>
                      <br />
                    </span>
                  )}
                  {['PayrollInstanceReport', 'EmployeeDataChangesReport', 'GlobalPayrunManagementReport'].includes(report.type) && (
                    <span>
                      <span className='u-weight--bold'>Type of Employment/Engagement: </span>
                      <span>{report.filters.employmentStatus.length ? report.filters.employmentStatus.join(', ') : 'Any'}</span>
                      <br />
                    </span>
                  )}
                  {['PayrollReport', 'GlobalPayAndTaxesReport'].includes(report.type) && report.filters.termCategory && (
                    <div>
                      <span>
                        <span className='u-weight--bold'>Term category: </span>
                        <span>{report.filters.termCategoryNames.length ? report.filters.termCategoryNames.join(', ') : 'Any'}</span>
                        <br />
                        <span className='u-weight--bold'>Elements Subcategory: </span>
                        <span>{report.filters.termSubCategoryNames.length ? report.filters.termSubCategoryNames.join(', ') : 'Any'}</span>
                        <br />
                        <span className='u-weight--bold'>Elements: </span>
                        <span>{report.filters.termNames.length ? report.filters.termNames.join(', ') : 'Any'}</span>
                      </span>
                    </div>
                  )}
                  {['PayrollInstanceReport', 'PayrollReport', 'EmployeeDataChangesReport', 'VarianceReport', 'GlobalPayrunManagementReport'].includes(
                    report.type
                  ) &&
                    report.reportingCurrency.length > 0 && (
                    <div>
                      <span className='u-weight--bold'>Reporting currency: </span>
                      <span>{report.reportingCurrency.join(', ')}</span>
                    </div>
                  )}
                  {['PayrollInstanceReport', 'PayrollReport', 'EmployeeDataChangesReport', 'VarianceReport'].includes(report.type) &&
                    report.reportingCurrency.length > 0 && (
                    <div>
                      <span className='u-weight--bold'>Reporting currency: </span>
                      <span>{report.reportingCurrency.join(', ')}</span>
                    </div>
                  )}
                  {report.type === 'ChangeLogReport' && (
                    <span>
                      <span className='u-weight--bold'>Payrun: </span>
                      <span>{report.filters.payrollInstanceNames.length ? report.filters.payrollInstanceNames.join(', ') : 'Any'}</span>
                      <br />
                      <span className='u-weight--bold'>Type of Employment/Engagement: </span>
                      <span>{report.filters.employmentStatus.length ? report.filters.employmentStatus.join(', ') : 'Any'}</span>
                      <br />
                      <span className='u-weight--bold'>Business Unit: </span>
                      <span>{report.filters.businessUnitNames.length ? report.filters.businessUnitNames.join(', ') : 'Any'}</span>
                      <br />
                      <span className='u-weight--bold'>Modified by: </span>
                      <span>
                        {report.filters.modifiedBy.length && report.filters.modifiedBy.filter((i) => i !== '').length
                          ? report.filters.modifiedBy.join(', ')
                          : 'Any'}
                      </span>
                      <br />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='o-layout__item u-text--right u-1/1 u-1/3@tablet u-2/6@desktop'>
            <button
              onClick={() => handleRegenerateReport()}
              className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
              data-testid='regenerateReport'
            >
              <RetryBaseIcon />
            </button>
            {hasGraphs && (
              <button
                onClick={() => {
                  props.history.push(`/reporting/analytics/${report.id}`, { from: props.location.pathname })
                }}
                className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
                data-testid='analytics'
              >
                <span className='icon icon--bar-chart' />
              </button>
            )}
            {props.showSave && (
              <button
                className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
                title='Save'
                onClick={(e) => {
                  e.preventDefault()
                  onSaveReport(report)
                }}
              >
                <span className='icon icon--save' />
              </button>
            )}
            <button
              className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
              title='View'
              onClick={(e) => {
                e.preventDefault()
                props.handleReportModalOpen()
              }}
            >
              <span className='icon icon--preview' />
            </button>
            <DownloadReportButton report={report} dropdownButtonClass='dropdown-icon-button--right-aligned' />
            <Authorization permissions={['REPORT_DELETE']}>
              <div
                className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
                title='Delete'
                onClick={() => onDeletePress(report.id)}
              >
                <span className='icon icon--trash' />
              </div>
            </Authorization>
          </div>
        </div>
      </div>
    </div>
  )
}

RecentReportItem.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.object,
    type: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    subcategory: PropTypes.string.isRequired,
    filters: PropTypes.shape({
      countryNames: PropTypes.array,
      companyNames: PropTypes.array,
      payrollNames: PropTypes.array,
      businessUnitNames: PropTypes.array,
      costCenterNames: PropTypes.array,
      processOwnerNames: PropTypes.array,
      termSubCategoryNames: PropTypes.array,
      termNames: PropTypes.array,
      employmentStatus: PropTypes.array,
      showOnlyTotals: PropTypes.bool,
    }),
  }),
  location: PropTypes.object,
  history: PropTypes.object,
  onDelete: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
  onSaveReport: PropTypes.func,
  showSave: PropTypes.bool,
}

export default RecentReportItem
