import React from 'react'

import { FloatingDialog, FloatingDialogContent, FloatingDialogDescription, FloatingDialogHeading } from 'components/popover/FloatingDialog'
import { FloatingDialogClose } from 'components/popover/FloatingDialog/trigger'
import { systemBannerTexts } from 'utils/locales/systemBanner.en'
import { buttonActions, contactLabels } from 'utils/locales/common.en'
import { AlertIcon } from 'components/icons/alertIcon'

export const SystemBannerHeader = () => (
  <>
    <AlertIcon />
    <span className='u-padding-left-small u-weight--bold'>{systemBannerTexts.header}</span>
    {systemBannerTexts.subHeader}
  </>
)

export const SystemBannerDescription = () => (
  <>
    <p className='fs--normal'>
      <span className='u-weight--bold'>{systemBannerTexts.subTitle}</span>
      {systemBannerTexts.changeInfo}
    </p>
    <section>
      <span className='u-weight--bold'>{systemBannerTexts.instructions.title}</span>
      <div className='d-flex d-flex--col'>
        {systemBannerTexts.instructions.options.map((option, index) => (
          <div key={option.title}>
            <span className='u-weight--bold'>
              {index + 1}. {option.title}
            </span>{' '}
            {option.value}
          </div>
        ))}
      </div>
      <div className='ps-mt-2'>
        <span>{systemBannerTexts.contact}</span>
        <a href={`mailto:${contactLabels.supportEmail}`} className='u-text--curious u-cursor--pointer u-underline'>
          {contactLabels.supportEmail}
        </a>
      </div>
    </section>
  </>
)

export const SystemBannerDialog = ({ isOpen, setIsOpen, dismissOptions, handleOkButton }) => (
  <FloatingDialog open={isOpen} onOpenChange={setIsOpen} dismissOptions={dismissOptions}>
    <FloatingDialogContent className='floatingDialog floatingDialog--as-a-banner floatingDialog--bc ps-pl-[232] ps-pr-[252] '>
      <FloatingDialogHeading className='floatingDialog--banner-header '>
        <SystemBannerHeader />
      </FloatingDialogHeading>
      <FloatingDialogDescription className='ps-pl-5 ps-mt-4'>
        <SystemBannerDescription />
      </FloatingDialogDescription>
      <FloatingDialogClose className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right ps-mt-3' handleClick={handleOkButton}>
        {buttonActions.ok}
      </FloatingDialogClose>
    </FloatingDialogContent>
  </FloatingDialog>
)

export const SystemBannerDialogMobile = ({ isOpen, setIsOpen, dismissOptions, handleOkButton }) => (
  <FloatingDialog open={isOpen} onOpenChange={setIsOpen} dismissOptions={dismissOptions}>
    <FloatingDialogContent className='floatingDialog floatingDialog--as-a-mobile-banner floatingDialog--bc ps-pl-[30] ps-pr-[30] '>
      <FloatingDialogHeading className='floatingDialog--banner-header '>
        <SystemBannerHeader />
      </FloatingDialogHeading>
      <FloatingDialogDescription className='ps-mt-2'>
        <SystemBannerDescription />
      </FloatingDialogDescription>
      <div className='ps-mt-2 w-full d-flex jc--center'>
        <FloatingDialogClose className='c-btn c-btn-w--large c-btn--curious' handleClick={handleOkButton}>
          {buttonActions.ok}
        </FloatingDialogClose>
      </div>
    </FloatingDialogContent>
  </FloatingDialog>
)
