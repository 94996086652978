import { isEmpty } from '../../utils/fnkit/typeChecks'
import { getStorageItem, setStorageItem } from 'utils/storage'

/**
 * Determines if an alert banner should be displayed based on MFA mode and banner acceptance status.
 *
 * @param {string} userId - The unique identifier of the user.
 * @param {string} bannerHasBeenAcceptedKey - The storage key for checking if the banner has been accepted.
 * @param {Object} keycloak - The Keycloak instance.
 * @param {Object} keycloak.idTokenParsed - The parsed token object from Keycloak.
 * @param {boolean} keycloak.authenticated - The authentication status of the user.
 * @param {boolean} keycloak.isFirstLogin - Indicates if this is the user's first login.
 * @returns {boolean} - Returns true if the alert banner should be displayed, otherwise false.
 */
export const shouldAlertBeDisplayed = (userId, bannerHasBeenAcceptedKey, keycloak) => {
  if (!userId || keycloak?.idTokenParsed?.login_mode !== 'local') {
    return false
  }
  const hasMFAMode = !isEmpty(keycloak?.idTokenParsed?.mfa_mode)
  const bannerHasBeenAccepted = getStorageItem(bannerHasBeenAcceptedKey) === 'true'
  const isFirstLogin = keycloak?.authenticated && keycloak?.isFirstLogin

  if ((hasMFAMode && isFirstLogin) || !bannerHasBeenAccepted) {
    if (bannerHasBeenAccepted) {
      setStorageItem(bannerHasBeenAcceptedKey, false)
    }
    return true
  }
  return false
}
