import React, { cloneElement, forwardRef, isValidElement } from 'react'

import { useMergeRefs } from '@floating-ui/react'
import { useFloatingDialogContext } from './useFloatingDialog'

export const FloatingDialogTrigger = forwardRef(({ children, asChild = false, ...props }, propRef) => {
  const context = useFloatingDialogContext()
  const childrenRef = children?.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allowsto pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    )
  }

  return (
    <button
      ref={ref}
      // Can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})

export const FloatingDialogClose = forwardRef((props, ref) => {
  const { handleClick } = props
  return <button type='button' {...props} ref={ref} onClick={handleClick} />
})
