import Fetcher from 'containers/Fetcher'
import PayslipsList from '../components/PayslipsList'
import { connect } from 'react-redux'
import { downloadPayslip, uploadPayslip } from 'redux/actions/payrollInstanceEmployeePivot'
import { getPayrollInstancesByEmployeeByStatuses } from '../../../selectors/payrollInstanceEmployee'
import { showMessage } from 'redux/actions/modal'
import { errorToString } from 'utils/apiErrors'
import { isCot, isPayrollAdmin, isPayrollAnalyst, isPayrollDataEntry, isControlling,
  isUser, isEmployeeOnly } from 'redux/selectors/auth'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'
import { getEmployeeById } from 'routes/Payruns/routes/Instance/selectors/employeesORM'

const mapDispatchToProps = dispatch => ({
  downloadFile: id => dispatch(downloadPayslip(id))
    .catch(() => dispatch(showMessage({
      body: "File isn't uploaded yet!",
    }))),
  uploadFile: (id, file) => dispatch(uploadPayslip(id, file))
    .then(() => dispatch(showMessage({
      body: 'File successfully uploaded!',
    })))
    .catch(error => dispatch(showMessage({
      body: errorToString(error)
    })))
})

const mapStateToProps = (state, props) => {
  let payrollInstancesByEmployee = []
  const employee = getEmployeeById(state, { employeeId: props.match.params.employeeId })
  const { employmentStatus } = employee

  if (isUser(state)) {
    payrollInstancesByEmployee = getPayrollInstancesByEmployeeByStatuses(state, {
      employeeId: props.match.params.employeeId,
      statuses: ['completed', 'completed-with-extra-tasks'] })
  }

  if (isPayrollAdmin(state) || isPayrollAnalyst(state) || isPayrollDataEntry(state) || isControlling(state)) {
    payrollInstancesByEmployee = getPayrollInstancesByEmployeeByStatuses(state, {
      employeeId: props.match.params.employeeId,
      // eslint-disable-next-line max-len
      statuses: ['sent-and-locked', 'gross-to-net-calculations-and-review-done', 'cot-final-payroll-reports-review-done', 'completed', 'completed-with-extra-tasks']
    })
  }

  if (isCot(state)) {
    payrollInstancesByEmployee = getPayrollInstancesByEmployeeByStatuses(state, {
      employeeId: props.match.params.employeeId,
      statuses: [
        'sent-and-locked', 'gross-to-net-calculations-and-review-done', 'cot-final-payroll-reports-review-done',
        'completed', 'completed-with-extra-tasks']
    })
  }

  return {
    payrollInstances: payrollInstancesByEmployee,
    isCot: isCot(state),
    isEmployeeOnly: isEmployeeOnly(state),
    employmentStatus
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(PayslipsList)

export default Fetcher(Container, [
  createEmployeeFilter({ name: 'payrollInstances' }),
  createEmployeeFilter({ name: 'payrollInstanceEmployeePivot' }),
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' })
])
