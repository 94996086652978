import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import InputField from 'components/form/InputField'
import ColorOption from 'components/form/select/ColorOption'
import SubmitButtons from './SubmitButtons'
import CheckboxOption from 'components/form/select/CheckboxOption'
import FieldDatePicker from 'components/form/FieldDatePicker'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { isEmpty, isNil, isArray } from 'lodash'
import PayrollInstancesSelectContainer from '../containers/PayrollInstancesSelectContainer'
import PayrollSelectContainer from '../containers/PayrollSelectContainer'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import { sortByLabel } from 'utils/strings'

class FiltersPayrunReport extends React.Component {
  render () {
    const {
      setCurrencyByPayrollInstance,
      selected,
      countries,
      companies,
      currencies,
      fxRates,
      setCurrency,
      resetFormFields,
      mostRecentRunReport = {},
      isFieldDisabled,
      disabledReason,
      handleModalOpen,
      customRatesFieldsNames,
      readonlyFields,
    } = this.props

    const shouldShowMostRecentRunReportItem = !this.props.reportItem

    // In the first case, it's a multi array, while in the second one it's a single Numeric value.
    // We can't catch empty payroll values with only one Lodash validation function,
    // because in the case of a single value and if we use `isEmpty` validation function only,
    // then any Numeric value (1,2 etc.) is threat as empty value.
    const hasPayrollValue = selected.showOnlyTotals ? !isEmpty(selected.payroll) : !isNil(selected.payroll)

    /*
     * Every next filter is shown if the last required field has value
     */
    return (
      <div className='o-block--inline'>
        {selected.subcategory && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='showOnlyTotals'
                label='Show Only Totals *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled || readonlyFields.showOnlyTotals}
                disabledReason={disabledReason}
                options={[
                  { value: true, label: 'Yes by subcategory' },
                  { value: false, label: 'No' },
                ]}
                enableRemovedOptionFix
                data-testid='show-only-totals-wrapper'
              />
            </div>
          </div>
        )}
        {!isNil(selected.showOnlyTotals) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='From Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='fromDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                data-testid='fromDate'
              />
            </div>
          </div>
        )}
        {!isNil(selected.showOnlyTotals) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='To Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='toDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                data-testid='toDate'
              />
            </div>
          </div>
        )}
        {!isNil(selected.showOnlyTotals) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='country'
                label='Country *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={countries
                  .map((country) => ({
                    value: country.id,
                    label: country.name,
                    abbreviature: country.abbreviature,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='country-wrapper'
              />
            </div>
          </div>
        )}
        {!isNil(selected.country) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='company'
                label='Company *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={companies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='company-wrapper'
              />
            </div>
          </div>
        )}
        {!isNil(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <PayrollSelectContainer
                optionComponent={selected.showOnlyTotals ? CheckboxOption : ColorOption}
                multi={selected.showOnlyTotals}
                {...this.props}
                {...(!isFieldDisabled
                  ? {
                    onChanged: () => {
                      setCurrency()
                      resetFormFields(null, ['reportingRate', 'rate', 'toCurrency', 'fxRate', ...customRatesFieldsNames])
                    },
                  }
                  : {})}
              />
            </div>
          </div>
        )}
        {hasPayrollValue && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <PayrollInstancesSelectContainer
                status={[
                  'active',
                  'sent-and-locked',
                  'gross-to-net-calculations-and-review-done',
                  'cot-final-payroll-reports-review-done',
                  'completed',
                  'completed-with-extra-tasks',
                  'reverted',
                ]}
                {...(!isFieldDisabled
                  ? {
                    onChanged: (value) => {
                      setCurrencyByPayrollInstance()
                      setCurrency()
                      resetFormFields(null, ['reportingRate', 'rate', 'toCurrency', 'fxRate', ...customRatesFieldsNames])
                    },
                  }
                  : {})}
                {...this.props}
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payrollInstance) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='employmentStatus'
                label='Type of Employment/Engagement'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={CheckboxOption}
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={EmploymentStatusHelper.allStatusOptionsForSelectBox}
                data-testid='type-of-employment'
                multi
                hasNoneOption
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payrollInstance) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='payrollCurrency'
                label='Payroll Currency *'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                multi
                disabled
                disabledReason={isFieldDisabled ? disabledReason : 'Payroll currency'}
                options={currencies.map((currency) => ({
                  value: currency.id,
                  label: currency.abbreviature,
                }))}
                enableRemovedOptionFix
                data-testid='payrollCurrency-wrapper'
                {...(!isFieldDisabled
                  ? {
                    onChanged: () => {
                      setCurrency()
                      resetFormFields(null, ['reportingRate', 'rate', 'toCurrency', 'fxRate', ...customRatesFieldsNames])
                    },
                  }
                  : {})}
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payrollInstance) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='reportingCurrency'
                label='Reporting Currency *'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                multi
                disabled
                disabledReason={
                  isFieldDisabled
                    ? disabledReason
                    : `By default reporting currency is the same as the payroll currency. If you want to change it please click
                the button "Change reporting currency" and choose an option`
                }
                options={currencies.map((currency) => ({
                  value: currency.id,
                  label: currency.abbreviature,
                }))}
                enableRemovedOptionFix
                data-testid='reportingCurrency-wrapper'
              />
            </div>
          </div>
        )}
        {selected.payrollCurrency && selected.reportingCurrency && JSON.stringify(selected.payrollCurrency) !== JSON.stringify(selected.reportingCurrency) ? (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              {isArray(selected.reportingRate) ? (
                <Field
                  name='reportingRate'
                  label='Rate'
                  component={CustomSelectField}
                  className='c-custom-select--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  multi
                  disabled
                  disabledReason={
                    isFieldDisabled ? disabledReason : 'If you want to change the rate please click the button "Change reporting currency" and choose an option'
                  }
                  options={fxRates.map((rate) => ({
                    value: rate.id,
                    label: rate.filterName,
                  }))}
                  data-testid='reportingRate'
                />
              ) : (
                <Field
                  name='reportingRate'
                  label='Rate'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  component={InputField}
                  type='text'
                  formGroupClassName='u-margin-none'
                  disabled
                  data-testid='reportingRate'
                />
              )}
            </div>
          </div>
        ) : null}
        {!isEmpty(selected.payrollInstance) && !isFieldDisabled && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-padding-top u-padding-bottom o-grid o-grid--medium'>
              <div onClick={() => handleModalOpen()} className='c-btn c-btn--small rounded shadow-md c-btn--curious u-padding-left u-padding-right'>
                Change Reporting Currency
              </div>
            </div>
          </div>
        )}

        {!this.props.hideNavigationButtons && (
          <SubmitButtons
            onBackClick={this.props.navigateToParentOnClick}
            pristineCheck={!isEmpty(selected.payrollInstance) && selected.payrollCurrency}
            {...this.props}
          />
        )}
        {shouldShowMostRecentRunReportItem && <div className='o-layout__item u-padding-left-small u-text--center u-1/1 u-margin-top-small u-padding-top-small'>
          <MostRecentRunReportItem
            mostRecentRunReport={mostRecentRunReport}
            onOpenReport={this.props.handleReportModalOpen}
            onDelete={this.props.deleteReport}
            {...this.props}
          />
        </div>}
      </div>
    )
  }
}

FiltersPayrunReport.propTypes = {
  resetFormFields: PropTypes.func,
  setCurrencyByPayrollInstance: PropTypes.func,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  // Props in order to check if the previous filter has a value
  // Show next filter only if the previous is filled
  selected: PropTypes.object,
  // Dropdown options data
  countries: PropTypes.array,
  companies: PropTypes.array,
  payrolls: PropTypes.array,
  payrollInstances: PropTypes.array,
  currencies: PropTypes.array,
  fxRates: PropTypes.array,
  // Disabled fields and submit btn check
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  handleModalOpen: PropTypes.func,
  navigateToParentOnClick: PropTypes.func,
  mostRecentRunReport: PropTypes.object,
  deleteReport: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
  setCurrency: PropTypes.func,
  customRatesFieldsNames: PropTypes.string,
  readonlyFields: PropTypes.object,
  hideNavigationButtons: PropTypes.bool,
}

export default FiltersPayrunReport
