/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import InputField from 'components/form/InputField'
import SubmitButtons from './SubmitButtons'
import CheckboxOption from 'components/form/select/CheckboxOption'
import FieldDatePicker from 'components/form/FieldDatePicker'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PayrollSelectContainer from '../containers/PayrollSelectContainer'
import PayrollInstancesSelectContainer from '../containers/PayrollInstancesSelectContainer'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import { sortByLabel } from 'utils/strings'
import { isArray, isEmpty, isNil } from 'utils/fnkit/typeChecks'
import { getDatesInRange, getMaxDate } from 'utils/fnkit/dates/ranges'
import { dateFormatEnums } from 'utils/enums/dateFormatEnums'
import { parseDate } from 'utils/fnkit/dates/transform'
import { getDateBasedOnMaxDate } from 'utils/fnkit/dates/checks'

const FiltersGlobalPayrunManagementReport = (props) => {
  const {
    setCurrencyByPayrollInstance,
    selected,
    countries,
    companies,
    currencies,
    fxRates,
    setCurrency,
    resetFormFields,
    mostRecentRunReport = {},
    isFieldDisabled,
    disabledReason,
    handleModalOpen,
    customRatesFieldsNames,
    readonlyFields,
    hideNavigationButtons,
    navigateToParentOnClick,
    handleReportModalOpen,
    deleteReport,
  } = props

  const startDate = selected?.fromDate
  const [maxDate, setMaxDate] = useState(getMaxDate(startDate))

  // In the first case, it's a multi array, while in the second one it's a single Numeric value.
  // We can't catch empty payroll values with only one Lodash validation function,
  // because in the case of a single value and if we use `isEmpty` validation function only,
  // then any Numeric value (1,2 etc.) is threat as empty value.
  const hasPayrollValue = selected.showOnlyTotals ? !isEmpty(selected.payroll) : !isNil(selected.payroll)
  const isShowOnlyTotalsSelected = !isNil(selected.showOnlyTotals)
  const isCountrySelected = !isEmpty(selected.country) || selected.country
  const isCompanySelected = !isEmpty(selected.company) || selected.company
  const isPayrollInstanceSelected = !isEmpty(selected.payrollInstance)
  const isLessThanMonth = getDatesInRange(startDate, selected.toDate)?.length <= 32
  const isLessThanYear = getDatesInRange(startDate, selected.toDate)?.length <= 366
  const showOnlyTotals = selected.showOnlyTotals
  const isLessThanYearWithShowOnlyTotalsYes = isLessThanYear && showOnlyTotals
  const shouldShowMostRecentRunReportItem = !props.reportItem
  useEffect(() => {
    setMaxDate(getMaxDate(parseDate(startDate, dateFormatEnums.MonthDayYear)))
  }, [startDate])

  /*
   * Every next filter is shown if the last required field has value
   */
  return (
    <div className='o-block--inline'>
      {selected.subcategory && (
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='showOnlyTotals'
              label='Show Only Totals *'
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--left-padding'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              disabled={isFieldDisabled || readonlyFields.showOnlyTotals}
              disabledReason={disabledReason}
              options={[
                { value: true, label: 'Yes by subcategory' },
                { value: false, label: 'No' },
              ]}
              enableRemovedOptionFix
              data-testid='show-only-totals-wrapper'
            />
          </div>
        </div>
      )}
      {isShowOnlyTotalsSelected && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              className='c-input c-input--transparent c-input__value-placeholder'
              label='From Date *'
              labelClassName='c-label u-text--small u-text--curious'
              name='fromDate'
              component={FieldDatePicker}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              formGroupClassName='u-margin-none'
              isClearable={!isFieldDisabled}
              data-testid='fromDate'
              onChange={() => {
                setMaxDate(getMaxDate(parseDate(startDate, dateFormatEnums.MonthDayYear)))
              }}
            />
          </div>
        </div>
      )}
      {isShowOnlyTotalsSelected && startDate && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              className='c-input c-input--transparent c-input__value-placeholder'
              label='To Date *'
              labelClassName='c-label u-text--small u-text--curious'
              name='toDate'
              component={FieldDatePicker}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              formGroupClassName='u-margin-none'
              isClearable={!isFieldDisabled}
              data-testid='toDate'
              minDate={startDate}
              maxDate={maxDate}
              selected={getDateBasedOnMaxDate(selected.toDate, maxDate)}
            />
          </div>
        </div>
      )}
      {isShowOnlyTotalsSelected && selected.toDate && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='country'
              label='Country *'
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={isLessThanMonth || isLessThanYearWithShowOnlyTotalsYes ? CheckboxOption : undefined}
              placeholder='Select'
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi={isLessThanMonth || isLessThanYearWithShowOnlyTotalsYes}
              options={countries.map((country) => ({
                value: country.id,
                label: country.name,
                abbreviature: country.abbreviature,
              }))}
              enableRemovedOptionFix
              data-testid='country-wrapper'
            />
          </div>
        </div>
      )}
      {isCountrySelected && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='company'
              label='Company *'
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={isLessThanMonth || isLessThanYearWithShowOnlyTotalsYes ? CheckboxOption : undefined}
              placeholder='Select'
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi={isLessThanMonth || isLessThanYearWithShowOnlyTotalsYes}
              options={companies
                .map((company) => ({
                  value: company.id,
                  label: company.name,
                }))
                .sort(sortByLabel)}
              enableRemovedOptionFix
              data-testid='company-wrapper'
            />
          </div>
        </div>
      )}
      {isCompanySelected && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <PayrollSelectContainer
              optionComponent={CheckboxOption}
              multi
              {...props}
              {...(!isFieldDisabled
                ? {
                  onChanged: () => {
                    setCurrency()
                    resetFormFields(null, ['reportingRate', 'rate', 'toCurrency', 'fxRate', ...customRatesFieldsNames])
                  },
                }
                : {})}
            />
          </div>
        </div>
      )}
      {hasPayrollValue && (
        <div
          className='o-layout__item u-padding-left-small
      u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'
        >
          <div className='c-modern-input'>
            <PayrollInstancesSelectContainer
              status={[
                'active',
                'sent-and-locked',
                'gross-to-net-calculations-and-review-done',
                'cot-final-payroll-reports-review-done',
                'completed',
                'completed-with-extra-tasks',
                'reverted',
              ]}
              {...(!isFieldDisabled
                ? {
                  onChanged: () => {
                    setCurrencyByPayrollInstance()
                    setCurrency()
                    resetFormFields(null, ['reportingRate', 'rate', 'toCurrency', 'fxRate', ...customRatesFieldsNames])
                  },
                }
                : {})}
              {...props}
            />
          </div>
        </div>
      )}
      {hasPayrollValue && (
        <div
          className='o-layout__item u-padding-left-small
      u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'
        >
          <div className='c-modern-input'>
            <Field
              name='employmentStatus'
              label='Type of Employment/Engagement'
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={CheckboxOption}
              placeholder='Any'
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              options={EmploymentStatusHelper.allStatusOptionsForSelectBox}
              data-testid='type-of-employment'
              multi
              hasNoneOption
            />
          </div>
        </div>
      )}
      {isPayrollInstanceSelected && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='payrollCurrency'
              label='Payroll Currency *'
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              multi
              disabled
              disabledReason={isFieldDisabled ? disabledReason : 'Payroll currency'}
              options={currencies.map((currency) => ({
                value: currency.id,
                label: currency.abbreviature,
              }))}
              enableRemovedOptionFix
              data-testid='payrollCurrency-wrapper'
              {...(!isFieldDisabled
                ? {
                  onChanged: () => {
                    setCurrency()
                    resetFormFields(null, ['reportingRate', 'rate', 'toCurrency', 'fxRate', ...customRatesFieldsNames])
                  },
                }
                : {})}
            />
          </div>
        </div>
      )}
      {isPayrollInstanceSelected && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='reportingCurrency'
              label='Reporting Currency *'
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              multi
              disabled
              disabledReason={
                isFieldDisabled
                  ? disabledReason
                  : `By default reporting currency is the same as the payroll currency.
              If you want to change it please click the button "Change reporting currency" and choose an option`
              }
              options={currencies.map((currency) => ({
                value: currency.id,
                label: currency.abbreviature,
              }))}
              enableRemovedOptionFix
              data-testid='reportingCurrency-wrapper'
            />
          </div>
        </div>
      )}
      {selected.payrollCurrency && selected.reportingCurrency && JSON.stringify(selected.payrollCurrency) !== JSON.stringify(selected.reportingCurrency) ? (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            {isArray(selected.reportingRate) ? (
              <Field
                name='reportingRate'
                label='Rate'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                multi
                disabled
                disabledReason={
                  isFieldDisabled
                    ? disabledReason
                    : `If you want to change the rate please click the button
                  "Change reporting currency" and choose an option`
                }
                options={fxRates.map((rate) => ({
                  value: rate.id,
                  label: rate.filterName,
                }))}
                data-testid='reportingRate'
              />
            ) : (
              <Field
                name='reportingRate'
                label='Rate'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                component={InputField}
                type='text'
                formGroupClassName='u-margin-none'
                disabled
                data-testid='reportingRate'
              />
            )}
          </div>
        </div>
      ) : null}
      {isPayrollInstanceSelected && !isFieldDisabled && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-padding-top u-padding-bottom o-grid o-grid--medium'>
            <div
              onClick={() => handleModalOpen()}
              className='c-btn c-btn--small rounded shadow-md c-btn--curious u-padding-left u-padding-right'
              data-testid='changeCurrency-button'
            >
              Change Reporting Currency
            </div>
          </div>
        </div>
      )}
      {!hideNavigationButtons && (
        <SubmitButtons onBackClick={navigateToParentOnClick} pristineCheck={isPayrollInstanceSelected && selected.payrollCurrency} {...props} />
      )}
      {shouldShowMostRecentRunReportItem && (
        <div className='o-layout__item u-padding-left-small u-text--center u-1/1 u-margin-top-small u-padding-top-small'>
          <MostRecentRunReportItem mostRecentRunReport={mostRecentRunReport} onOpenReport={handleReportModalOpen} onDelete={deleteReport} {...props} />
        </div>
      )}{' '}
    </div>
  )
}

FiltersGlobalPayrunManagementReport.propTypes = {
  resetFormFields: PropTypes.func,
  setCurrencyByPayrollInstance: PropTypes.func,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  // Props in order to check if the previous filter has a value
  // Show next filter only if the previous is filled
  selected: PropTypes.object,
  // Dropdown options data
  countries: PropTypes.array,
  companies: PropTypes.array,
  payrolls: PropTypes.array,
  payrollInstances: PropTypes.array,
  currencies: PropTypes.array,
  fxRates: PropTypes.array,
  // Disabled fields and submit btn check
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showRunReportBtn: PropTypes.bool,
  handleModalOpen: PropTypes.func,
  navigateToParentOnClick: PropTypes.func,
  mostRecentRunReport: PropTypes.object,
  deleteReport: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
  setCurrency: PropTypes.func,
  customRatesFieldsNames: PropTypes.func,
  readonlyFields: PropTypes.object,
  hideNavigationButtons: PropTypes.bool,
  optionComponent: PropTypes.func,
}

export default FiltersGlobalPayrunManagementReport
