import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import { reduxForm, destroy } from 'redux-form'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { types } from 'redux/config/documents'
import { showMessage } from 'redux/actions/modal'
import Form from 'components/form/Form'
import ShareActionOwners from 'components/documents/ShareActionOwners'
import ShareWithinCompany from 'components/documents/ShareWithinCompany'
import ShareOutsideCompany from 'components/documents/ShareOutsideCompany'
import { getInitialValues } from 'redux/selectors/documentUsers'
import { getKeyPeopleByAccessArea, getKeyPeopleWithSharedDocumentsWithoutLoggedInUser } from 'redux/selectors/employees'
import { getExternalVendorUsersByCompanyIdHavingCredentials } from 'redux/selectors/externalVendorUsers'
import { notifyUsers } from 'redux/actions/notifications'
import { createFilter } from 'utils/redux/filter'
import { buildShareBtnLabel } from 'redux/helpers/buildShareBtnLabel'
import { getSpecialStatusVendorId } from 'redux/selectors/vendorSwitch'
import { buildFilterName } from 'redux/filters/commonFilters'
import documentEmployeeFilter, { handleOnFilter } from 'containers/documents/utils/documentEmployeeFilter'
import { getInitialFiltersFromState, getInitialSortFromState } from 'redux/helpers/InitialFilters'
import { shareModalsFilterMapping, shareModalsSortMapping } from 'utils/enums/filterMappingEnums'
import { getStorageItem, setStorageItem } from 'utils/storage'

const filterKeys = {
  payroll: 'companyPayrollDocumentShare',
  company: 'companyDocumentShare',
  payrollInstance: 'payrollInstanceDocumentShare',
}

const ShareModalFormContainer = (props) => {
  const { typeClass, type, id } = props
  const storageKey = `${id}_note_tabIndex`
  const dispatch = useDispatch()
  // Retrieve tabIndex from localStorage or default to 0
  const savedTabIndex = parseInt(getStorageItem(storageKey), 10) || 0
  const [tabIndex, setTabIndex] = useState(savedTabIndex)

  const handleTabChange = (index) => {
    // Store the tabIndex in localStorage
    setStorageItem(storageKey, index)
    setTabIndex(index)
  }

  const tabs = typeClass ? types[typeClass].clientShareTabs.filter((tab) => !tab.hideOnNotes) : []
  const btnText = buildShareBtnLabel(type)

  // We don't want the redux form state to persist when opening a new note share
  // That is why we are resetting the form here where we can monitor the note ID change
  useEffect(() => {
    if (id) {
      dispatch(destroy('shareNonVendorFiles'))
    }
  }, [id, dispatch])

  return (
    <Form {...props} showSubmitBtn btnText={btnText} data-testid='share-form'>
      <Tabs selectedIndex={tabIndex} onSelect={handleTabChange}>
        <TabList className='c-tabs__list'>
          {tabs.map((tab) => (
            <Tab key={tab.name} className='c-tabs__item'>
              <span data-testid={tab.name}>{tab.label}</span>
            </Tab>
          ))}
        </TabList>
        {tabs.map((tab) => (
          <TabPanel key={tab.name}>
            {tab.name === 'actionOwners' && <ShareActionOwners {...props} />}
            {tab.name === 'withinCompany' && <ShareWithinCompany {...props} />}
            {tab.name === 'outsideCompany' && <ShareOutsideCompany {...props} />}
          </TabPanel>
        ))}
      </Tabs>
    </Form>
  )
}

ShareModalFormContainer.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  typeClass: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  const { companyId, payrollInstanceId } = props

  const filterKey = filterKeys['payrollInstance']
  const filterName = buildFilterName({ companyId, payrollInstanceId })[filterKey]

  const isFetchedAtLeastOnce = state.employeeSystemUsers.filters[filterName] && state.employeeSystemUsers.filters[filterName].ids
  let totalCount
  let offset
  let limit

  totalCount = isFetchedAtLeastOnce && state.employeeSystemUsers.filters[filterName].totalCount
  offset = isFetchedAtLeastOnce && state.employeeSystemUsers.filters[filterName].params.offset
  limit = isFetchedAtLeastOnce && state.employeeSystemUsers.filters[filterName].params.limit

  /*
   * ShareModalFormContainer combines containers for the three share non vendor tabs:
   * - ShareActionOwners
   * - ShareWithinCompany
   * - ShareOutsideCompany
   *
   * This is needed because we have to submit TOGETHER  all the three share tabs and sync the data.
   *
   * That's why we get here separately:
   * - actionOwnersUsers
   * - keyPeopleUsers
   * - externalVendorUsers
   * pass them to the tabs and set their initial values.
   */
  // TODO - `getKeyPeopleByAccessArea` documents logic can be moved out from the selector.
  const keyPeople = getKeyPeopleByAccessArea(state, { companyId })

  const keyPeopleWithinCompanyUsers = getKeyPeopleWithSharedDocumentsWithoutLoggedInUser(state, filterName)

  const externalVendorUsers = getExternalVendorUsersByCompanyIdHavingCredentials(state, { companyId })
  const onboardingVendor = getSpecialStatusVendorId(state, { companyId, vendorStatus: 'onboarding' })
  const offboardingVendor = getSpecialStatusVendorId(state, { companyId, vendorStatus: 'offboarding' })
  const hasOnboardingVendor = !!onboardingVendor.length
  const hasOffboardingVendor = !!offboardingVendor.length
  const specialStatusVendorId = hasOnboardingVendor ? parseInt(onboardingVendor, 10) : hasOffboardingVendor ? parseInt(offboardingVendor, 10) : null
  const externalVendorUsersHavingCredentialsFiltered = externalVendorUsers.filter((evu) => evu.vendor !== specialStatusVendorId)

  const keyPeopleInitialValues = getInitialValues(state, { users: keyPeople, userType: 'employeeUser' })
  const externalVendorUsersInitialValues = getInitialValues(state, {
    users: externalVendorUsersHavingCredentialsFiltered,
    userType: 'externalVendorUser',
  })

  const initialFilters = getInitialFiltersFromState({ state, entity: 'employeeSystemUsers', filterName, filterKeys: shareModalsFilterMapping })
  const initialSort = getInitialSortFromState({ state, entity: 'employeeSystemUsers', filterName, sortKeys: shareModalsSortMapping })

  return {
    payrollInstanceId,
    keyPeopleWithinCompanyUsers,
    externalVendorUsers: externalVendorUsersHavingCredentialsFiltered,
    initialValues: {
      ...keyPeopleInitialValues,
      ...externalVendorUsersInitialValues,
    },
    pagination: {
      totalPages: Math.ceil(totalCount / limit),
      currentPage: offset / limit,
      limit,
      totalCount: totalCount,
    },
    filterName,
    initialFilters,
    initialSort,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { id, type, onSubmit, onSubmitNote = null, body = 'Notification successfully sent.', title = null } = props

  return {
    onSubmit: (data) =>
      dispatch(notifyUsers(data, id, type)).then(({ data: { successful, failed } }) => {
        const pluralizeUsers = (count) => `${count} ${count === 1 ? 'user' : 'users'}`
        dispatch(
          showMessage({
            title: title,
            body: `${body} ${failed ? ` There was an issue sending it to ${pluralizeUsers(failed)}.` : ''}`,
          })
        )
        onSubmitNote ? onSubmitNote() : onSubmit()
      }),
    onFilter: ({ page = 0, limit, filters, sort }) =>
      dispatch((dispatch, getState) => handleOnFilter({ page, limit, filters, sort }, dispatch, getState, props)),
  }
}

const makePayrollInstanceFilter = (name, filterName = 'payrollInstance') => ({
  name,
  params: [
    {
      _computed: {
        filter: (state, { payrollInstanceId, params }) =>
          createFilter({
            [filterName]: params && params.payrollInstanceId ? params.payrollInstanceId : payrollInstanceId,
          }),
      },
    },
  ],
})

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'shareNonVendorFiles',
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
  })(ShareModalFormContainer)
)

Container.propTypes = {
  // Shareable object id. For example - `note.id`.
  id: PropTypes.number.isRequired,
  // Shareable object type. For example - `Note`.
  type: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  payrollInstanceId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  body: PropTypes.string,
}

export default Fetcher(
  Fetcher(Container, [
    documentEmployeeFilter(),
    'accessAreaPivot',
    'externalVendorUsers',
    'payrolls',
    'payrollService',
    'payrollProcess',
    'payrollStep',
    'payrollTask',
    'companyProcessPivot',
    'companyStepPivot',
    'companyTaskPivot',
    'countryProcessPivot',
    'countryStepPivot',
    'countryTaskPivot',
    'processes',
    'steps',
    'tasks',
    'parentCompanies',
    'companies',
    'companyServicePivot',
    'vendorSwitch',
    makePayrollInstanceFilter('payrollInstances', 'id'),
    makePayrollInstanceFilter('payrollInstanceService'),
    makePayrollInstanceFilter('payrollInstanceProcess'),
    makePayrollInstanceFilter('payrollInstanceStep'),
    makePayrollInstanceFilter('payrollInstanceTask'),
    makePayrollInstanceFilter('payrollInstanceTaskOwner'),
    makePayrollInstanceFilter('payrollInstanceTaskOwnerScope'),
  ]),
  ['parentCompanies']
)
