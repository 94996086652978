import Fetcher from 'containers/Fetcher'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { getEmployeesWithCompanyByCompanyAttachedToPayroll } from '../selectors/employees'
import { createFilter } from 'utils/redux/filter'
import { hasEmployees } from 'redux/helpers/hasEmployees'
import PayrollPaygroupEmployees from 'components/employees/PayrollPaygroupEmployees'
import { getPayrollByIdWithEmployeeIds } from 'routes/Payrolls/routes/Payroll/selectors/payroll'

const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = (state, props) => {
  const { payrollId, companyId } = props

  // get selected payroll
  let payroll = getPayrollByIdWithEmployeeIds(
    state,
    { payrollId: props.match.params.payrollId }
  )

  // get view's employees
  let employees = getEmployeesWithCompanyByCompanyAttachedToPayroll(
    state,
    {
      payrollId: payrollId,
      companyId: companyId
    }
  )

  return {
    employeeSystemUsers: employees,
    hasEmployees: () => hasEmployees(state, 'payrollDetachEmployees', employees),
    initialValues: {
      payrollId: payroll.id,
      paygroupEmployees: payroll.allPaygroupEmployees
    }
  }
}

const Container = (connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'payrollDetachEmployees'
})(PayrollPaygroupEmployees)))

export default Fetcher(Container, [
  {
    name: 'employeeSystemUsers',
    params: [{
      _computed: {
        filter: (state, { companyId }) => {
          return createFilter({
            company: companyId,
            payloadFormat: 'reduced'
          })
        }
      },
      disableObsoleteFlow: true
    }]
  },
])
