import createSelector from 'utils/createSelector'
import _ from 'lodash'

/**
 * Get employee id
 *
 * @return {Number}
 */
const getEmployeeId = (state, props) => parseInt(props.employeeId)

/**
 * Get direct employee payrolls
 *
 * @param {Number} getEmployeeId
 *
 * @return {Array}
 */
export const getNonOnceOffPayrollsByEmployee = createSelector(getEmployeeId,
  ({ EmployeeSystemUser }, id) => {
    let payrolls = []
    EmployeeSystemUser.withId(id).payrollEmployees.toModelArray().map(payrollEmployee => {
      if (payrollEmployee.payroll.frequency !== 'Once off') {
        payrolls.push({
          id: payrollEmployee.payroll.id,
          name: payrollEmployee.payroll.name,
          currency: payrollEmployee.payroll.currency.id
        })
      }
    })

    return payrolls
  }
)

/**
 * Get employee payrolls through the paygroup(s) it is assigned to
 *
 * @param {Number} getEmployeeId
 *
 * @return {Array}
 */
export const getPaygroupPayrollsByEmployee = createSelector(getEmployeeId,
  ({ EmployeeSystemUser, Paygroup }, id) => {
    let paygroups = EmployeeSystemUser.withId(id).paygroupEmployees.toModelArray().map(paygroupEmployee => ({
      id: paygroupEmployee.paygroup.id,
      name: paygroupEmployee.paygroup.name
    }))

    let payrolls = paygroups.map(paygroup => {
      let PaygroupObject = Paygroup.withId(paygroup.id)
      return PaygroupObject.payrollPaygroups.toModelArray().map(payrollPaygroup => ({
        id: payrollPaygroup.payroll.id,
        name: payrollPaygroup.payroll.name
      }))
    })

    return _.flatten(payrolls)
  }
)

/**
 * Combine employee payrolls and payrolls through the paygroup(s) it is assigned to
 *
 * @param {Array} getNonOnceOffPayrollsByEmployee
 * @param {Array} getPaygroupPayrollsByEmployee
 *
 * @return {Array}
 */
export const getAllPayrollsByEmployee = createSelector(getNonOnceOffPayrollsByEmployee, getPaygroupPayrollsByEmployee,
  (session, directEmployeePayrolls, employeePayrollsThroughPaygroups) => {
    let payrolls = _.concat(directEmployeePayrolls, employeePayrollsThroughPaygroups)
    payrolls = _.uniqBy(payrolls, 'id')
    return payrolls
  }
)
