import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { isArray } from 'lodash'
import PropTypes from 'prop-types'
import { isFetching } from 'utils/redux/fetching'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth, getCompanyRef } from 'redux/selectors/company'
import { getBusinessUnitsWithCompanyWithCountry, getBusinessUnitsByCompanies } from 'redux/selectors/businessUnits'
import FiltersChangeLogReport from 'routes/Reporting/components/FiltersChangeLogReport'
import Fetcher from 'containers/Fetcher'
import { sortByName } from 'utils/strings'

const mapStateToProps = (state, props) => {
  const { countries, companies, businessUnits, accessAreaPivot } = state

  if (isFetching([countries, companies, businessUnits, accessAreaPivot])) return { isFetching: true }

  const selector = formValueSelector(props.form)
  const values = selector(
    state,
    'subcategory',
    'fromDate',
    'toDate',
    'country',
    'company',
    'payroll',
    'payrollInstance',
    'businessUnit',
    'employmentStatus',
    'modifiedBy'
  )

  const companyId = values?.company
  const isHQ = companyId ? getCompanyRef(state, { companyId }).isHQ : null
  return {
    // All selected values
    selected: values,
    // All countries in the system
    countries: getCountriesByAuth(state).sort(sortByName),
    // Companies by selected countries
    companies: values.country
      ? getCompaniesByCountriesByAuth(state, { countriesIds: isArray(values.country) ? values.country : [values.country] }).sort(sortByName)
      : getCompaniesByAuth(state, props).sort(sortByName),
    // Business Units by selected countries
    businessUnits: isHQ
      ? getBusinessUnitsWithCompanyWithCountry(state).sort(sortByName)
      : getBusinessUnitsByCompanies(state, {
        companiesIds: isArray(values.company) ? values.company : [values.company],
      }).sort(sortByName),
  }
}

Fetcher.propTypes = {
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
}

const Container = connect(mapStateToProps)(FiltersChangeLogReport)

export default Fetcher(Container, ['countries', 'companies', 'accessAreaPivot', 'businessUnits'], { wrapperDivClassName: 'o-block--inline' })
