import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AccordionContainer from 'containers/AccordionContainer'
import { Authorization } from 'containers/Authorization'
import SectionHeading from 'components/SectionHeading'
import AmountFormat from 'components/AmountFormat'
import Dropzone from 'react-dropzone'
import { trimString } from 'utils/strings'
import MobileDetailsView from 'components/MobileDetailsView'
import PayslipTable from 'components/table/PayslipTable'
const noPayslipsMessage = 'No files to display.'

class PayslipsList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      instanceId: null
    }
  }

  onUpload (instanceId) {
    this.setState({
      instanceId
    })

    this.dropzoneRef.open()
  }

  renderRows () {
    const { payrollInstances, downloadFile, isCot } = this.props

    return payrollInstances.map(instance => ({
      payrollName: instance.payrollName,
      fromDate: moment(instance.fromDate.date).format('DD/MM/YYYY'),
      toDate: moment(instance.toDate.date).format('DD/MM/YYYY'),
      grossPay: <AmountFormat amount={instance.grossPay} />,
      netPay: <AmountFormat amount={instance.netPay} />,
      payDate: moment(instance.payDate.date).format('DD/MM/YYYY'),
      payrollpayslipsName: <div>
        <button
          className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny'
          title='Download'
          onClick={() => downloadFile(instance.id)}
          data-testid={`payslip-${instance.id}-download`}
        >
          <span className='icon icon--download' />
        </button>
        <Authorization permissions={['PAYSLIP_IMPORT']}>
          {
            isCot && <button
              type='button'
              onClick={() => this.onUpload(instance.id)}
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
              title='Upload'
            >
              <span className='icon icon--upload' />
            </button>
          }
        </Authorization>
      </div>
    }))
  }

  renderPayslipDetails (instance) {
    const { fromDate, toDate, grossPay, netPay } = instance

    return [
      { 'From Date': moment(fromDate.date).format('DD/MM/YYYY') },
      { 'To Date': moment(toDate.date).format('DD/MM/YYYY') },
      { 'Gross Pay': <AmountFormat amount={grossPay} key={instance.id} /> },
      { 'Net Pay': <AmountFormat amount={netPay} key={instance.id} /> }
    ]
  }

  render () {
    const { isFetching, uploadFile, employmentStatus, payrollInstances, downloadFile, isEmployeeOnly } = this.props

    let contractorChangeTitleStatuses = ['contractorcompany', 'contractoragency', 'contractorindividual']
    const isContractorChangeTitleStatus = employmentStatus &&
    contractorChangeTitleStatuses.includes(trimString(employmentStatus))

    const sectionHeading = isContractorChangeTitleStatus ? 'Invoices' : 'Payslips'
    const headingPayslips = isContractorChangeTitleStatus ? 'Invoice' : 'Payroll'
    const headingDocuments = isContractorChangeTitleStatus ? 'My Invoices' : 'My Payslips'

    if (isFetching) return <div>Loading...</div>

    return <div>
      <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
        <SectionHeading text={`${sectionHeading}`} />

        <Dropzone
          onDrop={files => uploadFile(this.state.instanceId, files[0])}
          ref={(node) => { this.dropzoneRef = node }}
          className='u-relative'
        />

        <PayslipTable
          proportionsClassName='u-1/1'
          tableClassName='o-table--tiny o-table--striped'
          headings={[
            {
              Header: `${headingPayslips}`,
              accessor: 'payrollName',
              disableSortBy: true,
              disableFilters: true
            },
            {
              Header: 'From Date',
              accessor: 'fromDate',
              setWidth: '220px',
              disableSortBy: true,
              disableFilters: true
            },
            {
              Header: 'To Date',
              accessor: 'toDate',
              setWidth: '220px',
              disableSortBy: true,
              disableFilters: true
            },
            {
              Header: 'Pay Date',
              accessor: 'payDate',
              setWidth: '220px',
              disableSortBy: true,
              disableFilters: true
            },
            {
              Header: 'Gross Pay',
              accessor: 'grossPay',
              setWidth: '150px',
              classNames: 'text-center',
              columnClassName: 'text-center',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: 'Net Pay',
              accessor: 'netPay',
              setWidth: '150px',
              classNames: 'text-center',
              columnClassName: 'text-center',
              disableSortBy: true,
              disableFilters: true
            },
            {
              Header: `${headingDocuments}`,
              accessor: 'payrollpayslipsName',
              classNames: 'text-right',
              columnClassName: 'text-right',
              setWidth: '200px',
              disableSortBy: true,
              disableFilters: true
            }
          ]}
          data={this.renderRows()}
        />
      </div>
      { isEmployeeOnly && <div className='u-hide-on-desktop'>
        <h1 className='u-text--huge u-weight--bold'>{headingDocuments}</h1>
        {payrollInstances && payrollInstances.length ? payrollInstances.map(instance => <AccordionContainer
          key={instance.id}
          rounded
          title={instance.payrollInstance.payrollName}
          titleColor='minsk'
          priority={3}
          subtitleKey={'Pay Date:'}
          subtitle={moment(instance.payDate.date).format('DD/MM/YYYY')}
          mobileView
          fullWidth
          actionButtons={<button
            className='c-btn c-btn--small c-btn--curious u-margin-right-tiny rounded'
            title='Download'
            onClick={() => downloadFile(instance.id)}
            data-testid={`payslip-mobile-${instance.id}-download`}
          >
            <span className='icon icon--download' />
          </button>}
        >
          <MobileDetailsView rows={this.renderPayslipDetails(instance)} />
        </AccordionContainer>)
          : <div className='mobile-no-data-message--centered'>
            <span>{noPayslipsMessage}</span>
          </div>
        }
      </div>
      }
    </div>
  }
}

PayslipsList.propTypes = {
  isFetching: PropTypes.bool,
  payrollInstances: PropTypes.array,
  downloadFile: PropTypes.func,
  uploadFile: PropTypes.func,
  isCot: PropTypes.bool,
  employmentStatus: PropTypes.string,
  isEmployeeOnly: PropTypes.bool
}

export default PayslipsList
