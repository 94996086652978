import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { attachEmployees } from 'redux/actions/payrolls'
import { getPayrollByIdWithEmployeeIds } from '../../../../../selectors/payroll'
import { invalidatePayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'
import EmployeeAttachDetachHOC from 'containers/employee/EmployeeAttachDetachHOC'
import Fetcher from 'containers/Fetcher'
import PayrollPaygroupEmployeesContainer from './PayrollPaygroupEmployeesContainer'

const mapDispatchToProps = (dispatch) => ({ dispatch })

// map to state
const mapStateToProps = (state, props) => {
  const { payrollPaygroupPivot, paygroupEmployeePivot, payrollEmployeePivot, payrolls, paygroups } = state
  const entities = [payrollPaygroupPivot, paygroupEmployeePivot, payrollEmployeePivot, payrolls, paygroups]

  if (isFetching(entities)) return { isFetching: true }

  // get selected payroll
  let payroll = getPayrollByIdWithEmployeeIds(state, { payrollId: props.match.params.payrollId })

  const isOnceOffPayroll = payroll.frequency === 'Once off'
  const messageContent = isOnceOffPayroll
    ? `This/These employees will be assigned to ${payroll.name}.
     Values for which groups of elements should be present in this Once Off Payrun File`
    : `Are you sure you want to assign this/these employee/s to ${payroll.name}?`

  return {
    content: messageContent,
    buttonName: 'Assign',
    payrollInstancePayPeriod: payroll.frequency,
    companyId: payroll.company,
    payrollName: payroll.name,
    payrollId: payroll.id,
    initialValues: {
      payrollId: payroll.id,
    },
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { companyId } = stateProps

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    toggleAll: (value, employees) => ownProps.updateEmployees('payrollAttachEmployees', employees, value),
    onSubmit: (data) =>
      dispatch(attachEmployees(data)).then(() => {
        dispatch(invalidatePayrollInstanceEmployeePivot())

        // On successful sync, go back to the company payroll page
        const path = `/companies/${companyId}/payroll`
        ownProps.history.push(path)
      }),
  }
}

const Container = EmployeeAttachDetachHOC(connect(mapStateToProps, mapDispatchToProps, mergeProps)(PayrollPaygroupEmployeesContainer))

export default Fetcher(Container, ['payrollEmployeePivot', 'paygroupEmployeePivot', 'payrollPaygroupPivot', 'payrolls', 'paygroups', 'companies'])
