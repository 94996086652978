import { reportFeCategoryIdLabels } from 'utils/locales/report.en'

// Legacy Report Types
export const types = [
  {
    type: 'EmployeeReport',
    name: 'Employee Headcount Report',
    categories: [
      {
        value: 'All',
        label: 'All employees',
      },
      {
        value: 'Joiners',
        label: 'Joiners',
      },
      {
        value: 'Leavers',
        label: 'Leavers',
      },
    ],
    subcategories: [
      {
        value: 'All',
        label: 'All employees',
        category: 'All',
      },
      {
        value: 'Joiners',
        label: 'Joiners',
        category: 'Joiners',
      },
      {
        value: 'Leavers',
        label: 'Leavers',
        category: 'Leavers',
      },
    ],
  },
  {
    type: 'AuditTrailReport',
    name: 'Audit Workflow Report',
    categories: [
      {
        value: 'Operational',
        label: 'Operational',
      },
    ],
    subcategories: [
      {
        value: 'PayrollCalculation',
        label: 'Payroll calculation',
        category: 'Operational',
      },
    ],
  },
  {
    type: 'PayrollInstanceReport',
    name: 'Payrun Report',
    categories: [
      {
        value: 'PayrollInstance',
        label: 'Payrun',
      },
    ],
    subcategories: [
      {
        value: 'PayrollInstance',
        label: 'Payrun',
        category: 'PayrollInstance',
      },
    ],
  },
  {
    type: 'PayrollReport',
    name: 'Global Payroll Report',
    categories: [
      {
        value: 'Costs',
        label: 'Costs',
      },
    ],
    subcategories: [
      {
        value: 'TotalEmployerCosts',
        label: 'Total employer costs',
        category: 'Costs',
      },
      {
        value: 'Terms',
        label: 'Terms',
        category: 'Costs',
      },
    ],
  },
  {
    type: 'EmployeeDataChangesReport',
    name: 'Employee Payroll Changes Report',
    categories: [
      {
        value: 'EmployeePayrollChanges',
        label: 'Employee payroll changes',
      },
    ],
    subcategories: [
      {
        value: 'EmployeePayrollChanges',
        label: 'Employee payroll changes',
        category: 'EmployeePayrollChanges',
      },
    ],
  },
  {
    type: 'VarianceReport',
    name: 'Variance Report',
    categories: [
      {
        value: 'VarianceReport',
        label: 'Variance Report',
      },
    ],
    subcategories: [
      {
        value: 'VarianceReport',
        label: 'Variance Report',
        category: 'VarianceReport',
      },
    ],
  },
  {
    type: 'ChangeLogReport',
    name: 'Change Log Report',
    categories: [
      {
        value: 'ChangeLogReport',
        label: 'Change Log Report',
      },
    ],
    subcategories: [
      {
        value: 'All',
        label: 'All',
        category: 'ChangeLogReport',
      },
      {
        value: 'PayAndTermsChanges',
        label: 'Payroll Data',
        category: 'ChangeLogReport',
      },
      {
        value: 'GeneralUpdates',
        label: 'General Updates',
        category: 'ChangeLogReport',
      },
      {
        value: 'RolesAndRights',
        label: 'Roles & Rights',
        category: 'ChangeLogReport',
      },
    ],
  },
  {
    type: 'GlobalPayrunManagementReport',
    name: 'Global Payrun Management Report',
    categories: [
      {
        value: 'GlobalPayrunManagement',
        label: 'Global Payrun Management',
      },
    ],
    subcategories: [
      {
        value: 'GlobalPayrunManagement',
        label: 'Global Payrun Management',
        category: 'GlobalPayrunManagement',
      },
    ],
  },
  {
    type: 'GlobalPayAndTaxesReport',
    name: 'Global Pay and Taxes Report',
    categories: [
      {
        value: 'GlobalPayAndTaxes',
        label: 'Global Pay and Taxes Set Up Report',
      },
    ],
    subcategories: [
      {
        value: 'Terms',
        label: 'Terms',
        category: 'GlobalPayAndTaxes',
      },
    ],
  },
  {
    type: 'AccessRightsReport',
    name: 'Access Management Report',
    categories: [
      {
        value: 'Operational',
        label: 'Operational',
      },
    ],
    subcategories: [
      {
        value: 'PayrollCalculation',
        label: 'Payroll calculation',
        category: 'Operational',
      },
    ],
  },
  {
    type: 'CustomBuildReport',
    name: reportFeCategoryIdLabels.customBuildReports.label,
    categories: [
      {
        value: 'Workforce',
        label: 'Workforce',
      },
    ],
    subcategories: [
      {
        value: 'PayrunDetail',
        label: 'PayrunDetail',
        category: 'Workforce',
      },
    ],
  },
]
