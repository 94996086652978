import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import TimeAttendanceUpdatesRoute from '../TimeAttendanceUpdates'
import InstanceRouteNew from '../InstanceNew'
import InstanceEmptyRoute from '../InstanceEmpty'
import InstanceChanges from '../InstanceChanges'
import NotesRoute from '../Notes'
import { ALL_ROUTES } from 'configs/routes'

export default (store) => ({
  path: ALL_ROUTES.PAYRUNS.CLOSED,
  indexRoute: { component: RouteView },
  tabbedRoute: true,
  authProtected: true,
  renderChildrenOutSideTab: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ InstanceRouteNew(store), InstanceEmptyRoute(store), InstanceChanges(store),
    TimeAttendanceUpdatesRoute(store), NotesRoute(store)
  ]
})
