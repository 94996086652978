import { placeholders } from 'utils/locales/common.en'
import { sortByName } from 'utils/strings'

/**
 * Generates a schema with oneOf for enum items.
 *
 * @param {Array} items - The array of items to map.
 * @param {string} nameKey - The key to use for the title in the schema.
 * @param {string} valueKey - The key to use for the const value in the schema.
 * @param options
 * @returns {Object} The schema with the mapped oneOf array with none option depending on the options argument or an empty object if items are empty.
 */
export const getMappedOneOfEnumSchema = (items = [], nameKey, valueKey, options = {}) => {
  const sortFunc = options.sortFunc || sortByName
  const mappedItems = items.sort(sortFunc).map((item) => ({ const: item[valueKey], title: item[nameKey] }))

  if (options.withNone) {
    mappedItems.push({
      const: 0,
      title: placeholders.none,
    })
  }

  return { oneOf: mappedItems }
}
