import allCountries from './allCountries'
import nationalities from 'redux/config/nationalities'
import pageUIStatesEnum from 'utils/enums/pageUIStatesEnums'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { inlineEndDateValidation, inlineStartDateValidation } from 'utils/validations'
import addressFields from './employeeAddressFieldsConfig'
import { getSelectionOptions } from 'utils/selectOptions'
import { yesNoBoolValuesOptions } from './DropDownOptions/confirmationOptions'
import { mode2faEmailOption } from './DropDownOptions/mode2faOptions'
import { optionLabels } from 'utils/locales/common.en'

export const taxClassFields = ['I', 'II', 'III', 'IV', 'V', 'VI', 'Pauschal Steuer 2%', 'Pauschal 25%']
export const employeeFieldsConfigAlt = ({
  isCreate = false,
  isEdit = false,
  isUserSSOEnabled = false,
  showUserSSOField = false,
  showMode2faFieldOnCreate = false,
  showMode2faFieldOnEdit = false,
  showInvoicePaymentCurrencyArea = false,
  isCotUser,
  personalExtraFields = [],
}) => {
  return [
    {
      category: 'personal',
      categoryName: 'Personal',
      fields: [
        ...personalExtraFields,
        // Ordered field in EE Personal tab including country specific fields
        {
          subCategory: 'personalDetails',
          field: 'title',
          name: 'Title',
          component: 'dropdown',
          placeholder: 'Select',
          options: getSelectionOptions('title'),
        },
        {
          subCategory: 'personalDetails',
          field: 'prefixTitle',
          name: 'Prefix Title',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany', 'Netherlands'],
        },
        {
          subCategory: 'personalDetails',
          field: 'suffix',
          name: 'Suffix',
          component: 'dropdown',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Netherlands'],
          options: [
            { value: 'I', label: 'I' },
            { value: 'II', label: 'II' },
            { value: 'III', label: 'III' },
            { value: 'JD', label: 'JD' },
            { value: 'JR', label: 'JR' },
            { value: 'MD', label: 'MD' },
            { value: 'PHD', label: 'PHD' },
            { value: 'SR', label: 'SR' },
          ],
        },
        {
          subCategory: 'personalDetails',
          field: 'firstname',
          name: `First Name${isEdit || isCreate ? ' *' : ''}`,
          component: 'input',
        },
        {
          subCategory: 'personalDetails',
          field: 'surname',
          name: `Surname${isEdit || isCreate ? ' *' : ''}`,
          component: 'input',
        },
        {
          subCategory: 'personalDetails',
          field: 'otherSurname',
          name: 'Other Surname',
          component: 'input',
        },
        {
          subCategory: 'personalDetails',
          field: 'nativeFirstNames',
          name: 'Local First Name',
          component: 'input',
          isCountrySpecific: true,
          countries: ['China', 'Japan'],
        },
        {
          subCategory: 'personalDetails',
          field: 'nativeFamilyNames',
          name: 'Local Surname',
          component: 'input',
          isCountrySpecific: true,
          countries: ['China', 'Japan'],
        },
        {
          subCategory: 'personalDetails',
          field: 'otherLocalFirstName',
          name: 'Other Local First Name',
          component: 'input',
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'personalDetails',
          field: 'otherLocalSurname',
          name: 'Other Local Surname',
          component: 'input',
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'personalDetails',
          field: 'initials',
          name: 'Initials',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Netherlands'],
        },
        // Biographical Details
        {
          subCategory: 'biographicalDetails',
          field: 'gender',
          name: 'Gender',
          component: 'dropdown',
          placeholder: 'Select',
          options: [
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
            { value: 'not stated', label: 'Not stated' },
            { value: 'other', label: 'Other' },
            { value: 'unknown', label: 'Unknown' },
          ],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'dateOfBirth',
          name: 'Date of Birth',
          component: 'date',
        },
        {
          subCategory: 'biographicalDetails',
          field: 'age',
          name: 'Age',
          component: 'input',
          type: 'number',
          min: '0',
          isCountrySpecific: true,
          countries: ['United Arab Emirates'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'nationality',
          name: 'Nationality',
          component: 'dropdown',
          options: nationalities,
        },
        {
          subCategory: 'biographicalDetails',
          field: 'citizenship',
          name: 'Citizenship',
          component: 'dropdown',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['China', 'Singapore', 'Israel', 'Korea, Republic of', 'Italy'],
          options: [
            { value: 'Citizen', label: 'Citizen' },
            { value: 'Non-citizen', label: 'Non-citizen' },
          ],
          allOptions: [
            { value: 'Singapore Permanent Resident', label: 'Singapore Permanent Resident' },
            { value: 'Foreigner', label: 'Foreigner' },
            { value: 'Permanent Resident', label: 'Permanent Resident' },
            { value: 'Citizen', label: 'Citizen' },
            { value: 'Non-citizen', label: 'Non-citizen' },
          ],
          allOptionsCountries: ['Singapore'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'languagePreferences',
          name: 'Language Preferences',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'cityOfBirth',
          name: 'City of Birth',
          component: 'input',
          showInPayrollInstance: false,
        },
        {
          subCategory: 'biographicalDetails',
          field: 'countryOfBirth',
          name: 'Country of Birth',
          component: 'dropdown',
          showInPayrollInstance: false,
          options: allCountries,
        },
        {
          subCategory: 'biographicalDetails',
          field: 'birthName',
          name: 'Birth Name',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'race',
          name: 'Race',
          component: 'dropdown',
          isCountrySpecific: true,
          countries: ['Singapore', 'Malaysia'],
          options: [
            { value: 'Chinese', label: 'Chinese' },
            { value: 'Indian', label: 'Indian' },
            { value: 'Malay', label: 'Malay' },
            { value: 'Eurasian', label: 'Eurasian' },
            { value: 'Caucasian', label: 'Caucasian' },
            { value: 'Other', label: 'Other' },
          ],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'maritalStatus',
          name: 'Marital Status',
          component: 'dropdown',
          placeholder: 'Select',
          options: [
            { value: 'Single', label: 'Single' },
            { value: 'Married', label: 'Married' },
            { value: 'Free Union', label: 'Free Union' },
            { value: 'Domestic Partnership', label: 'Domestic Partnership' },
            { value: 'Civil Partnership', label: 'Civil Partnership' },
            { value: 'Cohabitation', label: 'Cohabitation' },
            { value: 'Civil Partnership Dissolved', label: 'Civil Partnership Dissolved' },
            { value: 'Civil Partner Deceased', label: 'Civil Partner Deceased' },
            { value: 'Separated', label: 'Separated' },
            { value: 'Judicially Separated', label: 'Judicially Separated' },
            { value: 'Divorced', label: 'Divorced' },
            { value: 'Widowed', label: 'Widowed' },
            { value: 'Pacsed', label: 'Pacsed' },
            { value: 'Not Reported', label: 'Not Reported' },
          ],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'numberOfChildren',
          name: 'Number of Children',
          component: 'input',
          showInPayrollInstance: false,
          type: 'number',
          min: '0',
          isCountrySpecific: true,
          countries: ['Costa Rica', 'Singapore'],
        },
        {
          subCategory: 'biographicalDetails',
          field: 'religion',
          name: 'Religion',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany', 'Indonesia', 'Singapore'],
        },
        // Tax & ID Details
        {
          subCategory: 'taxDetails',
          field: 'taxNumber',
          name: 'Tax Number',
          component: 'input',
        },
        {
          subCategory: 'taxDetails',
          field: 'federalTaxpayerRegistryCode',
          name: 'RFC',
          component: 'input',
          hideOnCreate: true,
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Mexico'],
        },
        {
          subCategory: 'taxDetails',
          field: 'generalPopulationRegistry',
          name: 'CURP',
          component: 'input',
          hideOnEditView: true,
          isCountrySpecific: true,
          countries: ['Mexico'],
        },
        {
          subCategory: 'taxDetails',
          field: 'hecs',
          name: 'HECS',
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          isCountrySpecific: true,
          showInPayrollInstance: false,
          countries: ['Australia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'taxCode',
          name: 'Tax Code',
          component: 'input',
          showInPayrollInstance: true,
          isCountrySpecific: true,
          countries: ['United Kingdom'],
        },
        {
          subCategory: 'taxDetails',
          field: 'niCategoryLetter',
          name: 'National Insurance Letter',
          showInPayrollInstance: true,
          component: 'dropdown',
          options: ['A', 'A1', 'B', 'C', 'E', 'J', 'H', 'M', 'X', 'Z'],
          isCountrySpecific: true,
          countries: ['United Kingdom', 'Ireland'],
        },
        {
          subCategory: 'taxDetails',
          field: 'churchTax',
          name: 'Church Tax',
          component: 'dropdown',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          countries: ['Germany'],
        },
        {
          subCategory: 'taxDetails',
          field: 'taxClass',
          name: 'Tax Class',
          component: 'dropdown',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          options: taxClassFields,
          countries: ['Germany'],
        },
        {
          subCategory: 'taxDetails',
          field: 'identityType',
          name: 'Identity Type',
          component: 'dropdown',
          options: [
            { value: 'NRIC (Blue)', label: 'NRIC (Blue)' },
            { value: 'NRIC (Pink)', label: 'NRIC (Pink)' },
            { value: 'Employment Pass', label: 'Employment Pass' },
            { value: 'S Pass', label: 'S Pass' },
            { value: 'Work Permit', label: 'Work Permit' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'fundType',
          name: 'Fund Type',
          component: 'dropdown',
          options: [
            { value: 'CDAC', label: 'CDAC' },
            { value: 'MBMF', label: 'MBMF' },
            { value: 'SINDA', label: 'SINDA' },
            { value: 'ECF', label: 'ECF' },
            { value: 'Not Applicable', label: 'Not Applicable' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'cpfType',
          name: 'CPF Type',
          component: 'dropdown',
          options: [
            { value: 'Local', label: 'Local' },
            { value: 'PR', label: 'PR' },
            { value: 'Singaporean', label: 'Singaporean' },
            { value: 'Dependant', label: 'Dependant' },
            { value: 'Employment Pass', label: 'Employment Pass' },
            { value: 'Workpermit/SPASS', label: 'Workpermit/SPASS' },
            { value: 'Permanent Resident', label: 'Permanent Resident' },
            { value: 'Permanent Resident(Graduated/Full Rates)', label: 'Permanent Resident(Graduated/Full Rates)' },
            { value: 'Vacational Trainee', label: 'Vacational Trainee' },
            { value: 'Not Applicable', label: 'Not Applicable' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'dateIssue',
          name: 'Date Issue',
          component: 'date',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'expiryDate',
          name: 'Expiry Date',
          component: 'date',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'socialSecurityTAJNumber',
          name: 'Social Security (TAJ) Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Hungary'],
        },
        {
          subCategory: 'taxDetails',
          field: 'socialInsuranceNumber',
          name: 'Social Insurance Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany', 'Uganda', 'Senegal', 'Ghana', 'Ivory Coast', 'United Arab Emirates', 'Austria', 'Switzerland', 'State of Qatar'],
        },
        {
          subCategory: 'taxDetails',
          field: 'irasType',
          name: 'IRAS Type',
          component: 'dropdown',
          options: [
            { value: 'NRIC', label: 'NRIC' },
            { value: 'FIN', label: 'FIN' },
            { value: 'Non Application for SG TAX', label: 'Non Application for SG TAX' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'taxDetails',
          field: 'bpjsKetenagakerjaan',
          name: 'BPJS Ketenagakerjaan',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Indonesia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'bpjsKesehatan',
          name: 'BPJS Kesehatan',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Indonesia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'alienIdentificationNumber',
          name: 'Alien Identification Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Singapore', 'Spain', 'United Arab Emirates', 'Korea, Republic of'],
        },
        {
          subCategory: 'taxDetails',
          field: 'ctpsNumber',
          name: 'CTPS Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'pisNumber',
          name: 'PIS Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'socialSecurityId',
          name: 'Social Security Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: [
            'Costa Rica',
            'Germany',
            'Thailand',
            'Spain',
            'France',
            'Portugal',
            'Malaysia',
            'Denmark',
            'Norway',
            'United Kingdom',
            'Switzerland',
            'Ireland',
            'Belgium',
            'Sweden',
            'Netherlands',
            'Italy',
            'Austria',
            'Czechia',
            'Finland',
            'Turkey',
            'Brazil',
          ],
        },
        {
          subCategory: 'taxDetails',
          field: 'epfNumber',
          name: 'EPF Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['India'],
        },
        {
          subCategory: 'taxDetails',
          field: 'permanentAccountNumber',
          name: 'Permanent Account Number (PAN)',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['India'],
        },
        {
          subCategory: 'taxDetails',
          field: 'universalAccountNumber',
          name: 'Universal Account Number',
          tab: 'Job & Org',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['India'],
        },
        {
          subCategory: 'taxDetails',
          field: 'employmentInsuranceNumber',
          name: 'Employment Insurance Number',
          tab: 'Job & Org',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'taxDetails',
          field: 'residentCardNumber',
          name: 'Resident Card Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'taxDetails',
          field: 'welfarePensionNumber',
          name: 'Welfare Pension Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Japan'],
        },
        {
          subCategory: 'taxDetails',
          field: 'idCardNumber',
          name: 'ID Card Number',
          component: 'input',
          isCountrySpecific: true,
          countries: ['Peru', 'Costa Rica', 'Israel', 'China', 'Indonesia', 'Hungary', 'Thailand', 'Belgium', 'United Arab Emirates', 'Poland', 'Turkey'],
        },
        {
          subCategory: 'taxDetails',
          field: 'rgNumber',
          name: 'General Registration (RG) Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'nci',
          name: 'National Civil Identification (NCI)',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'crnm',
          name: 'National Migration Registration Card (CRNM)',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'voterRegistrationNumber',
          name: 'Voter Registration Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Brazil'],
        },
        {
          subCategory: 'taxDetails',
          field: 'lifeInsurance',
          name: 'Life Insurance',
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'taxDetails',
          field: 'socialSecurityNumber',
          name: 'IMSS',
          component: 'input',
          showInPayrollInstance: false,
          hideOnEditView: true,
          isCountrySpecific: true,
          countries: ['Mexico'],
        },
        {
          subCategory: 'taxDetails',
          field: 'pensionBank',
          name: 'Pension Bank',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Australia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'insuranceNumber',
          name: 'Insurance Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany', 'United Kingdom', 'Russian Federation', 'Belgium'],
        },
        {
          subCategory: 'taxDetails',
          field: 'dependentLifeInsurance',
          name: 'Dependent Life Insurance',
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'taxDetails',
          field: 'extendedHealthCare',
          name: 'Extended Health Care',
          component: 'dropdown',
          options: [
            { value: 'Single', label: 'Single' },
            { value: 'Family', label: 'Family' },
            { value: 'No', label: 'No' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'taxDetails',
          field: 'accidentalDeathAndDismembermentInsurance',
          name: 'Accidental Death & Dismemberment Ins.',
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Canada'],
        },
        {
          subCategory: 'taxDetails',
          field: 'asbMembershipNumber',
          name: 'ASB Membership Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'nationalIdentityNumberNew',
          name: 'National Identity Number: New',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'nationalIdentityNumberOld',
          name: 'National Identity Number: Old',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'pnbNumber',
          name: 'PNB Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'providentFundNumber',
          name: 'Provident Fund Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'tabungHajiNumber',
          name: 'Tabung Haji Number',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Malaysia'],
        },
        {
          subCategory: 'taxDetails',
          field: 'zemisNumber',
          name: 'ZEMIS Number',
          component: 'input',
          showInPayrollInstance: true,
          isCountrySpecific: true,
          countries: ['Switzerland'],
        },
        {
          subCategory: 'taxDetails',
          field: 'foreignerCode',
          name: 'Foreigner code',
          component: 'dropdown',
          showInPayrollInstance: true,
          isCountrySpecific: true,
          countries: ['Switzerland'],
          options: [
            { value: 'Temporary resident (B)', label: 'Temporary resident (B)' },
            { value: 'Permanent resident (C)', label: 'Permanent resident (C)' },
            {
              value: 'Gainfully employed spouse and diplomat (Ci)',
              label: 'Gainfully employed spouse and diplomat (Ci)',
            },
            { value: 'Provisionally admitted (F)', label: 'Provisionally admitted (F)' },
            { value: 'Cross-border commuter (G)', label: 'Cross-border commuter (G)' },
            { value: 'Short-term resident (L)', label: 'Short-term resident (L)' },
          ],
        },
        // Contact Details Section fields
        {
          subCategory: 'contactDetails',
          field: 'personalEmail',
          name: 'Personal Email',
          component: 'email',
        },
        {
          subCategory: 'contactDetails',
          field: 'workEmail',
          name: `Work Email${isEdit || isCreate ? ' *' : ''}`,
          component: 'email',
        },
        {
          subCategory: 'contactDetails',
          field: 'addresses',
          name: '',
          isVisibleOn: [pageUIStatesEnum.CREATE],
          component: 'object',
          fields: addressFields,
        },
        {
          subCategory: 'contactDetails',
          field: 'officePhone',
          name: 'Office Phone',
          component: 'phone',
        },
        {
          subCategory: 'contactDetails',
          field: 'mobilePhone',
          name: 'Mobile Phone',
          component: 'phone',
        },
        {
          subCategory: 'contactDetails',
          field: 'skype',
          name: 'Skype',
          component: 'input',
        },
        // Bank Details
        {
          subCategory: 'bankDetails',
          field: 'payrollCountry',
          name: `Payroll Country${isEdit || isCreate ? ' *' : ''}`,
          component: 'dropdown',
          options: [],
          disabled: true,
        },
        {
          subCategory: 'bankDetails',
          field: 'company',
          name: `Company${isEdit || isCreate ? ' *' : ''}`,
          component: 'dropdown',
          options: [],
          disabled: true,
        },
        {
          subCategory: 'bankDetails',
          field: 'bankCountry',
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
          name: `Bank Country${isEdit || isCreate ? ' *' : ''}`,
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'currency',
          name: `Currency${isEdit || isCreate ? ' *' : ''}`,
          component: 'dropdown',
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
          options: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'bankName',
          name: 'Bank Name',
          component: 'input',
        },
        {
          subCategory: 'bankDetails',
          field: 'bankAddress',
          name: 'Bank Address',
          component: 'input',
        },
        {
          subCategory: 'bankDetails',
          field: 'accountHolder',
          name: 'Account Holder Name',
          component: 'input',
        },
        {
          subCategory: 'bankDetails',
          field: 'typeOfAccount',
          name: 'Type of Account',
          component: 'dropdown',
          options: [
            { value: 'Saving', label: 'Saving' },
            { value: 'Checking', label: 'Checking' },
            { value: 'Current', label: 'Current' },
            { value: 'Other', label: 'Other' },
          ],
          isCountrySpecific: false,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'primaryBankAccount',
          name: 'Primary Bank Account',
          component: 'dropdown',
          options: yesNoBoolValuesOptions,
        },
        {
          subCategory: 'bankDetails',
          field: 'paymentType',
          name: 'Payment Type',
          component: 'dropdown',
          options: [
            { value: 'Default', label: 'Default' },
            { value: 'Manual', label: 'Manual' },
          ],
        },
        {
          subCategory: 'bankDetails',
          field: 'swiftBICCode',
          name: 'Swift / BIC code',
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'iban',
          name: 'IBAN',
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'accountNumber',
          name: 'Account Number',
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'sortCode',
          name: 'Sort code',
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'bsbNumber',
          name: 'BSB Number',
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'financialInstitutionNumber',
          name: 'Financial institution Number',
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'bankBranchNumber',
          name: 'Bank branch Number',
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'abaRoutingNumber',
          name: 'ABA / Rounting',
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'clabe',
          name: 'CLABE',
          component: 'input',
          isCountrySpecific: true,
          countries: [],
        },
        {
          subCategory: 'bankDetails',
          field: 'description',
          name: 'Description',
          component: 'input',
        },
        // Emergency Person Details Section fields
        {
          subCategory: 'emergencyPersonDetails',
          field: 'emergencyFirstname',
          name: 'First Name',
          component: 'input',
        },
        {
          subCategory: 'emergencyPersonDetails',
          field: 'emergencySurname',
          name: 'Surname',
          component: 'input',
        },
        {
          subCategory: 'emergencyPersonDetails',
          field: 'emergencyMobilePhone',
          name: 'Mobile Phone',
          component: 'phone',
        },
        {
          subCategory: 'emergencyPersonDetails',
          field: 'emergencyRelationship',
          name: 'Relationship',
          component: 'input',
        },
        // Contractors Details Section fields
        {
          subCategory: 'contractorDetails',
          field: 'contractorCompanyName',
          name: 'Contractors Company Name',
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'agencyCompanyName',
          name: 'Agency Company Name',
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorAddressLineOne',
          name: 'Address Line One',
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorAddressLineTwo',
          name: 'Address Line Two',
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorCity',
          name: 'City',
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorPostCode',
          name: 'Post Code',
          component: 'input',
        },
        {
          subCategory: 'contractorDetails',
          field: 'contractorAddressCountry',
          name: 'Address Country',
          component: 'dropdown',
          options: allCountries,
        },
        {
          subCategory: 'contractorDetails',
          field: 'vatRegistrationNumber',
          name: 'VAT Registration Number',
          component: 'input',
        },
      ],
    },
    {
      category: 'job-and-org',
      categoryName: 'Job & Org',
      fields: [
        // Job Profile fields
        {
          subCategory: 'jobProfile',
          field: 'employeeId',
          name: 'Employee ID',
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'otherEmployeeId',
          name: 'Other Employee ID',
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'uniqueContractorId',
          name: 'Unique Contractor ID',
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'position',
          name: 'Position',
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'workLevel',
          name: 'Work Level',
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'weeklyHours',
          name: 'Weekly Hours',
          component: 'input',
        },
        {
          subCategory: 'jobProfile',
          field: 'monthlyHours',
          name: 'Monthly Hours',
          component: 'input',
        },

        {
          subCategory: 'jobProfile',
          field: 'workWeekGroup',
          name: 'Work Week Group',
          component: 'dropdown',
          showInPayrollInstance: false,
          options: [
            { value: '5 Days', label: '5 Days' },
            { value: '6 Days', label: '6 Days' },
          ],
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'jobProfile',
          field: 'payScaleLevel',
          name: 'Pay Scale Level',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany'],
        },
        {
          subCategory: 'jobProfile',
          field: 'payScaleGroup',
          name: 'Pay Scale Group',
          component: 'input',
          showInPayrollInstance: false,
          isCountrySpecific: true,
          countries: ['Germany'],
        },
        {
          subCategory: 'jobProfile',
          field: 'paymentType',
          name: 'Payment Type',
          component: 'dropdown',
          options: [
            { value: 'Salary', label: 'Salary' },
            { value: 'Hourly', label: 'Hourly' },
            { value: 'Invoice', label: 'Invoice' },
          ],
        },
        {
          subCategory: 'jobProfile',
          field: 'hourlyRate',
          name: 'Hourly Rate',
          component: 'input',
        },
        // Organization Details fields
        {
          subCategory: 'organizationDetails',
          field: 'countries',
          name: `Country${isEdit || isCreate ? ' *' : ''}`,
          component: 'dropdown',
          options: allCountries,
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
          hideOnEdit: true,
        },
        {
          subCategory: 'organizationDetails',
          field: 'company',
          name: `Company ${isCreate ? '*' : ''}`,
          component: 'dropdown',
          options: [],
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
          hideOnEdit: true,
        },
        {
          subCategory: 'organizationDetails',
          field: 'paymentCurrency',
          name: `Payment Currency ${isCreate ? '*' : ''}`,
          component: 'dropdown',
          options: [],
          onChangeUpdateFieldOptions: [
            { name: 'payroll', subCategory: 'organizationDetails' },
            { name: 'paygroup', subCategory: 'organizationDetails' },
          ],
          // eslint-disable-next-line max-len
          disabledReason:
            'The employee is already part of a Payroll or Paygroup. If you want to change this, please remove them first from the payroll or paygroup.',
        },
        {
          subCategory: 'organizationDetails',
          field: 'invoicePaymentCurrency',
          name: `Invoice Payment Currency`,
          component: 'dropdown',
          options: [],
          isCountrySpecific: true,
          countries: ['United Kingdom', 'Netherlands', 'Sweden', 'France', 'United Arab Emirates'],
        },
        {
          subCategory: 'organizationDetails',
          field: 'payroll',
          name: 'Payroll',
          component: 'dropdown',
          options: [],
          optionsDependency: true,
          // eslint-disable-next-line max-len
          disabledReason:
            'The employee is already part of a Payroll or Paygroup. If you want to change this, please remove them first from the payroll or paygroup.',
        },
        {
          subCategory: 'organizationDetails',
          field: 'paygroup',
          name: 'Paygroup',
          component: 'dropdown',
          options: [],
          optionsDependency: true,
          // eslint-disable-next-line max-len
          disabledReason:
            'The employee is already part of a Payroll or Paygroup. If you want to change this, please remove them first from the payroll or paygroup.',
        },
        {
          subCategory: 'organizationDetails',
          field: 'department',
          name: 'Department',
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'businessUnit',
          name: 'Business Unit',
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'costCenter',
          name: 'Cost Center',
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'officeLocation',
          name: 'Office Location',
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'subsidiary',
          name: 'Subsidiary',
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'project',
          name: 'Project',
          component: 'dropdown',
          options: [],
        },
        {
          subCategory: 'organizationDetails',
          field: 'managementPandL',
          name: 'Management P&L',
          component: 'input',
        },
        {
          subCategory: 'organizationDetails',
          field: 'managerId',
          name: 'Manager ID',
          component: 'input',
        },
        {
          subCategory: 'organizationDetails',
          field: 'resetUserPassword',
          name: 'Reset User Password',
          component: 'button',
          hideOnCreate: true,
        },
        {
          subCategory: 'organizationDetails',
          field: 'makeKeyUser',
          name: 'Make Key User',
          component: 'authorizedButton',
          hideOnCreate: true,
        },
        // Employee Self service
        {
          subCategory: 'employeeSelfService',
          field: 'hasCredentials',
          name: 'Employee Self-Service',
          component: 'dropdown',
          options: [
            { value: 'true', label: 'Active' },
            { value: 'false', label: 'Inactive' },
          ],
        },
        {
          subCategory: 'employeeSelfService',
          field: 'isSSOEnabled',
          name: 'SSO',
          isVisible: showUserSSOField,
          hideOnCreate: true,
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: false,
          },
        },
        {
          subCategory: 'employeeSelfService',
          field: 'username',
          name: 'Username',
          component: 'input',
        },
        {
          subCategory: 'employeeSelfService',
          field: 'mode2fa',
          name: '2FA Mode',
          isVisible: isCreate ? showMode2faFieldOnCreate : showMode2faFieldOnEdit,
          component: 'dropdown',
          options: getSelectionOptions('mode2fa'),
          allOptions: [...getSelectionOptions('mode2fa'), mode2faEmailOption],
        },
        // Employment Details fields
        {
          subCategory: 'employmentDetails',
          field: 'employmentStatus',
          name: 'Type of Employment / Engagement',
          component: 'dropdown',
          options: [...EmploymentStatusHelper.allStatusOptionsForSelectBox],
        },
        {
          subCategory: 'employmentDetails',
          field: 'employmentCondition',
          name: 'Employment Status',
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Active', label: 'Active' },
            { value: 'Discarded', label: 'Discarded' },
            { value: 'Dormant', label: 'Dormant' },
            { value: 'On Leave', label: 'On Leave' },
            { value: 'Retired', label: 'Retired' },
            { value: 'Terminated', label: 'Terminated' },
            { value: 'Unpaid Leave', label: 'Unpaid Leave' },
          ],
        },
        {
          subCategory: 'employmentDetails',
          field: 'workType',
          name: 'Full Time / Part Time',
          component: 'dropdown',
          options: [
            { value: 'Full Time', label: 'Full Time' },
            { value: 'Part Time', label: 'Part Time' },
            { value: 'Variable', label: 'Variable' },
          ],
        },
        {
          subCategory: 'employmentDetails',
          field: 'typeOfContract',
          name: 'Type of Contract',
          component: 'dropdown',
          options: ['Germany', 'Denmark', 'Netherlands', 'Sweden', 'Norway', 'Finland'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'confirmationDate',
          name: 'Confirmation Date',
          showInPayrollInstance: false,
          component: 'date',
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'visaWorkPermit',
          name: 'Visa / Work Permit',
          component: 'dropdown',
          options: [
            { value: 'Applicable', label: 'Applicable' },
            { value: 'Not applicable', label: 'Not applicable' },
          ],
        },
        {
          subCategory: 'employmentDetails',
          field: 'workAuthorizationType',
          name: 'Work Authorization Type',
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Employment Pass', label: 'Employment Pass' },
            { value: 'Permanent Resident', label: 'Permanent Resident' },
            { value: 'Work Permit', label: 'Work Permit' },
          ],
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'fwlSpassCode',
          name: 'FWL / SPASS Code',
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Basic Tier Skilled', label: 'Basic Tier Skilled' },
            { value: 'SPASS Basic Tier', label: 'SPASS Basic Tier' },
          ],
          isCountrySpecific: true,
          countries: ['Singapore'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'contractorTempAgency',
          name: 'Contractor / Temp Agency',
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'occupationalStatusCode',
          name: 'Occupational Status Code',
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'tradeUnion',
          name: 'Trade Union / Work Council',
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'companyLink',
          accessLayers: {
            edit: ['EMPLOYEEUSER_NORMAL_COMPANYLINK_EDIT'],
            create: null,
            view: null,
            delete: null,
          },
          canModifyFields: {
            [pageUIStatesEnum.EDIT]: { permissionLayers: ['EMPLOYEEUSER_NORMAL_COMPANYLINK_EDIT'] },
          },
          name: 'Company Link',
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'collectiveBargainingItaly',
          name: 'Collective Bargaining Italy',
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Dirigente', label: 'Dirigente' },
            { value: 'Employee', label: 'Employee' },
            { value: 'Quadro', label: 'Quadro' },
            { value: 'Other', label: 'Other' },
          ],
          isCountrySpecific: true,
          countries: ['Italy'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'collectiveBargainingFrance',
          name: 'Collective Bargaining France',
          showInPayrollInstance: false,
          component: 'dropdown',
          options: [
            { value: 'Cadre 10', label: 'Cadre 10' },
            { value: 'Cadre 100', label: 'Cadre 100' },
            { value: 'Cadre 105', label: 'Cadre 105' },
            { value: 'Cadre 115', label: 'Cadre 115' },
            { value: 'Cadre 130', label: 'Cadre 130' },
            { value: 'Cadre 150', label: 'Cadre 150' },
            { value: 'Cadre 170', label: 'Cadre 170' },
            { value: 'Cadre 210', label: 'Cadre 210' },
            { value: 'Cadre 270', label: 'Cadre 270' },
            { value: 'Cadre 95', label: 'Cadre 95' },
            { value: 'ETAM 200', label: 'ETAM 200' },
            { value: 'ETAM 210', label: 'ETAM 210' },
            { value: 'ETAM 220', label: 'ETAM 220' },
            { value: 'ETAM 230', label: 'ETAM 230' },
            { value: 'ETAM 240', label: 'ETAM 240' },
            { value: 'ETAM 250', label: 'ETAM 250' },
            { value: 'ETAM 275', label: 'ETAM 275' },
            { value: 'ETAM 310', label: 'ETAM 310' },
            { value: 'ETAM 355', label: 'ETAM 355' },
            { value: 'ETAM 400', label: 'ETAM 400' },
            { value: 'ETAM 450', label: 'ETAM 450' },
            { value: 'ETAM 500', label: 'ETAM 500' },
            { value: 'Other', label: 'Other' },
          ],
          isCountrySpecific: true,
          countries: ['France'],
        },
        {
          subCategory: 'employmentDetails',
          field: 'hireReason',
          name: 'Hire Reason',
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'rehire',
          name: 'Rehire',
          component: 'dropdown',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
        },
        {
          subCategory: 'employmentDetails',
          field: 'originalHireDate',
          name: 'Original Hire Date',
          component: 'date',
        },
        {
          subCategory: 'employmentDetails',
          field: 'startDate',
          name: `Start Date ${isCreate ? '*' : ''}`,
          component: 'date',
          validation: {
            errorFn: inlineStartDateValidation,
            compare: 'endDate',
          },
        },
        {
          subCategory: 'employmentDetails',
          field: 'endDate',
          name: 'End Date',
          validation: {
            errorFn: inlineEndDateValidation,
            compare: 'startDate',
          },
          component: 'date',
        },
        {
          subCategory: 'employmentDetails',
          field: 'terminationReason',
          name: 'Termination Reason',
          component: 'input',
        },
        {
          subCategory: 'employmentDetails',
          field: 'hasMoved',
          name: 'Has Moved',
          isVisible: !isCreate && isCotUser,
          component: 'dropdown',
          options: [
            { value: optionLabels.yes, label: optionLabels.yes },
            { value: optionLabels.no, label: optionLabels.no },
          ],
        },
      ],
    },
    {
      category: 'pay-and-taxes',
      categoryName: 'Pay & Taxes',
      fields: [
        {
          field: 'employmentStatus',
          name: 'Type of Employment/Engagement',
        },
        {
          field: 'paymentCurrency',
          name: 'Payment Currency',
        },
        {
          field: 'annualSalary',
          name: 'Annual Salary',
        },
        {
          field: 'employeePensionContributionsPercentage',
          name: 'Employee Pension Contributions %',
        },
        {
          field: 'employerPensionContributionsPercentage',
          name: 'Employer Pension Contributions %',
        },
        {
          field: 'Pay elements',
          name: 'Pay Elements',
        },
        {
          field: 'Employer Contributions',
          name: 'Employer Contributions',
        },
        {
          field: 'Employee Deductions',
          name: 'Employee Deductions',
        },
        {
          field: 'Time and Attendance',
          name: 'Time and Attendance',
        },
        {
          field: 'Overtime',
          name: 'Overtime',
        },
        {
          field: 'Employee Net Deductions',
          name: 'Employee Net Deductions',
        },
      ],
    },
  ]
}
