import createSelector from 'utils/createSelector'
import { getPaygroupByIdWithEmployeeIds } from '../../../../../selectors/paygroup'
import { getFixedPaygroupTermByPaygroup } from 'redux/selectors/paygroupTerm'
import _ from 'lodash'

// get the company id
const getCompanyId = (state, props) => parseInt(props.companyId, 10)

// get the passed employee ids
const getEmployeeIds = (state, props) => props.employeeIds

const getFiltered = state => state.employeeSystemUsers.filters.search
  ? state.employeeSystemUsers.filters.search.ids
  : state.employeeSystemUsers.filterIds

// return employees by company
// that aren't participating in current paygroup
export const getEmployeesWithCompanyByCompanyAvailableForPaygroup = createSelector(getCompanyId, getPaygroupByIdWithEmployeeIds, getFiltered,
  ({ EmployeeSystemUser }, companyId, paygroup, filtered) => {
    return EmployeeSystemUser
      .filter(
        employee =>
          employee.isEmployee && employee.isAccessable &&
          (employee.company === companyId) &&
          // Only employees with the same currency as the payroll currency should be returned.
          // If the employee doesn't have a currency, then it's fine
          ((!employee.paymentCurrency) || (employee.paymentCurrency === paygroup.currency)) &&
          (filtered ? filtered.includes(employee.id) : true)
      )
      .toModelArray()
      .filter(
        employee => !employee.paygroupEmployees.toModelArray().length
      )
      .map(employee => ({
        ...employee.ref,
        company: {
          ...employee.company.ref
        },
        businessUnit: employee.businessUnit ? employee.businessUnit.ref : null
      }))
  })

// Get employees, which company country terms differ with the selected paygroup
export const getEmployeesWhichFixedTermsDifferWithPaygroup = createSelector(
  getEmployeeIds, getFixedPaygroupTermByPaygroup,
  ({ EmployeeSystemUser }, employeeIds, paygroupTerms) => {
    return EmployeeSystemUser
      .filter(
        employee =>
          employee.isEmployee && employee.isAccessable &&
          _.includes(employeeIds, employee.id)
      )
      .toModelArray()
      .filter(
        employee => {
          // get employee terms
          // I haven't filtered out the variable terms
          // because it's one extra loop, instead
          // i have one extra check to see if the term
          // is variable to skip it at the final loop
          let employeeTerms = employee
            .employeeCompanyCountryTerms
            .toModelArray()

          let shouldBeAdded = employeeTerms.some(empTerm => {
            if (empTerm.term.isVariable()) return false

            let paygroupTerm = _.find(paygroupTerms, { id:empTerm.term.id })
            return (paygroupTerm && paygroupTerm.amount === empTerm.amount)
          })

          return !shouldBeAdded
        }
      )
  })
