import reportOptionsConfig from 'configs/reports'

const mandatoryReadOnly = {
  type: true,
  name: true,
  category: true,
  subcategory: true,
}
/**
 * All reports types
 */
let newTypes = {}
newTypes[reportOptionsConfig.GLOBAL_WORK_HEADCOUNT.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'All',
  subcategory: 'All',
  employeeStatus: 'all',
  showOnlyTotals: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  hasPayrollNoneOption: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}
newTypes[reportOptionsConfig.GLOBAL_JOINERS_HEADCOUNT.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'Joiners',
  subcategory: 'Joiners',
  employeeStatus: 'all',
  showOnlyTotals: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  hasPayrollNoneOption: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}
newTypes[reportOptionsConfig.GLOBAL_LEAVERS_HEADCOUNT.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'Leavers',
  subcategory: 'Leavers',
  employeeStatus: 'all',
  showOnlyTotals: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  hasPayrollNoneOption: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.WORKFORCE_HEADCOUNT.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'All',
  subcategory: 'All',
  employeeStatus: 'all',
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.JOINERS_HEADCOUNT.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'Joiners',
  subcategory: 'Joiners',
  employeeStatus: 'all',
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.LEAVERS_HEADCOUNT.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'Leavers',
  subcategory: 'Leavers',
  employeeStatus: 'all',
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.WORKFORCE_DETAIL.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'All',
  subcategory: 'All',
  employeeStatus: 'all',
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.JOINERS_DETAIL.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'Joiners',
  subcategory: 'Joiners',
  employeeStatus: 'all',
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.LEAVERS_DETAIL.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'Leavers',
  subcategory: 'Leavers',
  employeeStatus: 'all',
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'All',
  subcategory: 'All',
  employeeStatus: 'all',
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.GLOBAL_WORKFLOW_AUDIT.VALUE] = {
  type: 'AuditTrailReport',
  name: 'Audit Workflow Report',
  category: 'Operational',
  subcategory: 'PayrollCalculation',
  employeeStatus: null,
  servicesSelection: ['Payroll Calculation'],
  showOnlyTotals: null,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allProcessOwnersSelected: true,
  allPayrollsSelected: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    servicesLocked: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.WORKFLOW_AUDIT.VALUE] = {
  type: 'AuditTrailReport',
  name: 'Audit Workflow Report',
  category: 'Operational',
  subcategory: 'PayrollCalculation',
  employeeStatus: null,
  servicesSelection: ['Payroll Calculation'],
  showOnlyTotals: null,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    servicesLocked: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.PAYROLL_CHANGES_REPORT.VALUE] = {
  type: 'EmployeeDataChangesReport',
  name: 'Employee Payroll Changes Report',
  category: 'EmployeePayrollChanges',
  subcategory: 'EmployeePayrollChanges',
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY.VALUE] = {
  type: 'GlobalPayrunManagementReport',
  name: 'Employee Payroll Changes Report',
  category: 'GlobalPayrunManagement',
  subcategory: 'GlobalPayrunManagement',
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.PAYRUN_MANAGEMENT_REPORT.VALUE] = {
  type: 'GlobalPayrunManagementReport',
  name: 'Employee Payroll Changes Report',
  category: 'GlobalPayrunManagement',
  subcategory: 'GlobalPayrunManagement',
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.PAYRUN_REPORT.VALUE] = {
  type: 'PayrollInstanceReport',
  name: 'Payrun Report',
  category: 'PayrollInstance',
  subcategory: 'PayrollInstance',
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.VARIANCE_REPORT.VALUE] = {
  type: 'VarianceReport',
  name: 'Variance Report',
  category: 'VarianceReport',
  subcategory: 'VarianceReport',
  employeeStatus: null,
  showOnlyTotals: null,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
  },
}

newTypes[reportOptionsConfig.EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'All',
  subcategory: 'All',
  employeeStatus: 'inactive',
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.EMPLOYEE_SELF_SERVE_ACCESS_REPORT.VALUE] = {
  type: 'EmployeeReport',
  name: 'Employee Headcount Report',
  category: 'All',
  subcategory: 'All',
  employeeStatus: 'active',
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
  },
}

newTypes[reportOptionsConfig.GLOBAL_TOTAL_EMPLOYER_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  subcategory: 'TotalEmployerCosts',
  combinedSubcategorySelection: ['Total employer costs'],
  showOnlyTotals: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.TOTAL_EMPLOYER_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  subcategory: 'TotalEmployerCosts',
  combinedSubcategorySelection: ['Total employer costs'],
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.EMPLOYER_COSTS_DETAIL.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  subcategory: 'TotalEmployerCosts',
  combinedSubcategorySelection: ['Total employer costs'],
  employeeStatus: null,
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.GLOBAL_PAY_ELEMENT_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Pay elements'],
  shouldShowElements: true,
  isPayAndTaxesReport: true,
  employeeStatus: null,
  showOnlyTotals: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.TOTAL_PAY_ELEMENT_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Pay elements'],
  populateElementsSubCategoriesSelected: true,
  employeeStatus: null,
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.PAY_ELEMENTS_COSTS_DETAIL.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Pay elements'],
  populateElementsSubCategoriesSelected: true,
  employeeStatus: null,
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.GLOBAL_EMPLOYER_CONTRIBUTION_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Employer Contributions'],
  shouldShowElements: true,
  isPayAndTaxesReport: true,
  employeeStatus: null,
  showOnlyTotals: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.TOTAL_EMPLOYER_CONTRIBUTION_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Employer Contributions'],
  populateElementsSubCategoriesSelected: true,
  employeeStatus: null,
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Employer Contributions'],
  populateElementsSubCategoriesSelected: true,
  employeeStatus: null,
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.GLOBAL_EMPLOYEE_DEDUCTION_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Employee Deductions'],
  shouldShowElements: true,
  isPayAndTaxesReport: true,
  employeeStatus: null,
  showOnlyTotals: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.TOTAL_EMPLOYEE_DEDUCTION_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Employee Deductions'],
  populateElementsSubCategoriesSelected: true,
  employeeStatus: null,
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.EMPLOYEE_DEDUCTION_COSTS_DETAIL.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Employee Deductions'],
  populateElementsSubCategoriesSelected: true,
  employeeStatus: null,
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Employee Net Deductions'],
  shouldShowElements: true,
  isPayAndTaxesReport: true,
  employeeStatus: null,
  showOnlyTotals: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Employee Net Deductions'],
  populateElementsSubCategoriesSelected: true,
  employeeStatus: null,
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Employee Net Deductions'],
  populateElementsSubCategoriesSelected: true,
  employeeStatus: null,
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    showOnlyTotals: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.CHANGE_LOG.VALUE] = {
  type: 'ChangeLogReport',
  name: 'Change Log Report',
  category: 'ChangeLogReport',
  subcategory: 'All',
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
  },
}

newTypes[reportOptionsConfig.CHANGE_LOG_PAYROLL_DATA.VALUE] = {
  type: 'ChangeLogReport',
  name: 'Change Log Report',
  category: 'ChangeLogReport',
  subcategory: 'PayAndTermsChanges',
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
  },
}

newTypes[reportOptionsConfig.CHANGE_LOG_GENERAL_UPDATES.VALUE] = {
  type: 'ChangeLogReport',
  name: 'Change Log Report',
  category: 'ChangeLogReport',
  subcategory: 'GeneralUpdates',
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
  },
}

newTypes[reportOptionsConfig.GLOBAL_PAY_AND_TAXES_REPORT.VALUE] = {
  type: 'GlobalPayAndTaxesReport',
  name: 'Global Pay and Taxes Report',
  category: 'GlobalPayAndTaxes',
  subcategory: 'Terms',
  allCombinedSubcategorySelection: true,
  allElementsSelected: true,
  isPayAndTaxesReport: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.PAY_AND_TAXES_REPORT.VALUE] = {
  type: 'GlobalPayAndTaxesReport',
  name: 'Global Pay and Taxes Report',
  category: 'GlobalPayAndTaxes',
  subcategory: 'Terms',
  allCombinedSubcategorySelection: true,
  isPayAndTaxesReport: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    combinedSubcategory: false,
  },
}

newTypes[reportOptionsConfig.PAY_ELEMENTS_LIST.VALUE] = {
  type: 'GlobalPayAndTaxesReport',
  name: 'Global Pay and Taxes Report',
  category: 'GlobalPayAndTaxes',
  combinedSubcategorySelection: ['Pay elements'],
  isPayAndTaxesReport: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.EMPLOYER_CONTRIBUTIONS_LISTING.VALUE] = {
  type: 'GlobalPayAndTaxesReport',
  name: 'Global Pay and Taxes Report',
  category: 'GlobalPayAndTaxes',
  combinedSubcategorySelection: ['Employer Contributions'],
  isPayAndTaxesReport: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.EMPLOYEE_DEDUCTIONS_LISTING.VALUE] = {
  type: 'GlobalPayAndTaxesReport',
  name: 'Global Pay and Taxes Report',
  category: 'GlobalPayAndTaxes',
  combinedSubcategorySelection: ['Employee Deductions'],
  isPayAndTaxesReport: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.EMPLOYEE_NET_DEDUCTIONS_LISTING.VALUE] = {
  type: 'GlobalPayAndTaxesReport',
  name: 'Global Pay and Taxes Report',
  category: 'GlobalPayAndTaxes',
  combinedSubcategorySelection: ['Employee Net Deductions'],
  isPayAndTaxesReport: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    employeeStatus: true,
    combinedSubcategory: true,
  },
}

newTypes[reportOptionsConfig.GLOBAL_PAYROLL_ELEMENT_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Pay elements', 'Employer Contributions', 'Employee Deductions', 'Employee Net Deductions'],
  shouldShowElements: true,
  showOnlyTotals: true,
  allCountriesSelected: true,
  allCompaniesSelected: true,
  allPayrollsSelected: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    showOnlyTotals: true,
    fieldsNotToReset: ['showOnlyTotals'],
    combinedSubcategory: false,
  },
}

newTypes[reportOptionsConfig.PAYROLL_ELEMENT_COSTS.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Pay elements', 'Employer Contributions', 'Employee Deductions', 'Employee Net Deductions'],
  shouldShowElements: true,
  showOnlyTotals: true,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    showOnlyTotals: true,
    fieldsNotToReset: ['showOnlyTotals'],
    combinedSubcategory: false,
  },
}

newTypes[reportOptionsConfig.PAYROLL_ELEMENT_COSTS_DETAIL.VALUE] = {
  type: 'PayrollReport',
  hasPayollCurrencySelection: 'setCurrency',
  name: 'Global Payroll Report',
  category: 'Costs',
  combinedSubcategorySelection: ['Pay elements', 'Employer Contributions', 'Employee Deductions', 'Employee Net Deductions'],
  shouldShowElements: true,
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    showOnlyTotals: true,
    fieldsNotToReset: ['showOnlyTotals'],
    combinedSubcategory: false,
  },
}

newTypes[reportOptionsConfig.ROLES_AND_RIGHTS_CHANGE_LOG.VALUE] = {
  type: 'ChangeLogReport',
  name: 'Change Log Report',
  category: 'ChangeLogReport',
  subcategory: 'RolesAndRights',
  showOnlyTotals: false,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
  },
}

newTypes[reportOptionsConfig.GLOBAL_ACCESS_MANAGEMENT.VALUE] = {
  type: 'AccessRightsReport',
  name: 'Access Management Report',
  category: 'Operational',
  subcategory: 'PayrollCalculation',
  servicesSelection: ['Payroll Calculation'],
  allCountriesSelected: true,
  allCompaniesSelected: true,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    servicesLocked: true,
  },
}

newTypes[reportOptionsConfig.ACCESS_MANAGEMENT.VALUE] = {
  type: 'AccessRightsReport',
  name: 'Access Management Report',
  category: 'Operational',
  subcategory: 'PayrollCalculation',
  servicesSelection: ['Payroll Calculation'],
  allCountriesSelected: false,
  allCompaniesSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    servicesLocked: true,
  },
}

newTypes[reportOptionsConfig.PAYROLL_WORKFLOW_AUDIT.VALUE] = {
  type: 'AuditTrailReport',
  name: 'Audit Workflow Report',
  category: 'Operational',
  subcategory: 'PayrollCalculation',
  servicesSelection: ['Payroll Calculation'],
  isSinglePayroll: true,
  showOnlyTotals: null,
  allCountriesSelected: false,
  allCompaniesSelected: false,
  allPayrollsSelected: false,
  readonlyFieldsObj: {
    ...mandatoryReadOnly,
    servicesLocked: true,
  },
}

newTypes[reportOptionsConfig.CUSTOM_BUILD_REPORT.VALUE] = {
  type: 'CustomBuildReport',
  name: 'Workforce and Payrun Detail',
  category: 'Workforce',
  subcategory: 'PayrunDetail',
}

export default newTypes
