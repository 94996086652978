import createSelector from 'utils/createSelector'

// get the payroll instance id
const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId)

/**
 * Get payroll instance by id with Payroll
 */
export const getPayrollInstanceWithPayroll = createSelector(getPayrollInstanceId,
  ({ PayrollInstance }, payrollInstanceId) => {
    const payrollInstance = PayrollInstance.withId(payrollInstanceId)

    return {
      ...payrollInstance.ref,
      payroll: { ...payrollInstance.payroll.ref }
    }
  }
)
