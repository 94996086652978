/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { isArray } from 'lodash'
import PropTypes from 'prop-types'
import { isFetching } from 'utils/redux/fetching'
import { getFxRates } from 'redux/selectors/fxRates'
import { getCurrencies } from 'redux/selectors/currencies'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth, getCompanyRef } from 'redux/selectors/company'
import { getBusinessUnitsByCompany, getBusinessUnitsWithCompanyWithCountry } from 'redux/selectors/businessUnits'
import { getCostCentersByCompany, getCostCentersWithCompanyWithCountry } from 'redux/selectors/costCenters'
import { getDepartmentsByCompany, getDepartmentsWithCompanyWithCountry } from 'redux/selectors/departments'
import { formValueSelector } from 'redux-form'
import { fetchFxRatesIfNeeded } from 'redux/actions/fxRates'
import { fetchCurrenciesIfNeeded } from 'redux/actions/currencies'
import { fetchCostCentersIfNeeded } from 'redux/actions/costCenters'
import { fetchDepartmentsIfNeeded } from 'redux/actions/departments'
import { fetchBusinessUnitsIfNeeded } from 'redux/actions/businessUnits'
import { fetchAccessAreaPivotIfNeeded } from 'redux/actions/accessAreaPivot'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import FiltersEmployeeDataChangesReport from '../../../components/FiltersEmployeeDataChangesReport'
import { sortByName } from 'utils/strings'

/**
 * We are reusing this container for both 'EmployeeDataChangesReport' and 'VarianceReport',
 * because they have the same filters and fields.
 */
class FiltersEmployeeDataChangesReportContainer extends React.Component {
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props

    dispatch(fetchCountriesIfNeeded())
    dispatch(fetchCompaniesIfNeeded())
    dispatch(fetchCurrenciesIfNeeded())
    dispatch(fetchFxRatesIfNeeded())
    dispatch(fetchAccessAreaPivotIfNeeded())
    dispatch(fetchBusinessUnitsIfNeeded())
    dispatch(fetchCostCentersIfNeeded())
    dispatch(fetchDepartmentsIfNeeded())
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <FiltersEmployeeDataChangesReport {...this.props} />
  }
}

const mapStateToProps = (state, props) => {
  const { countries, companies, currencies, fxRates, accessAreaPivot, businessUnits, costCenters, departments } = state

  if (isFetching([countries, companies, currencies, fxRates, accessAreaPivot, businessUnits, costCenters, departments])) return { isFetching: true }

  const selector = formValueSelector(props.form)
  const values = selector(
    state,
    'subcategory',
    'fromDate',
    'toDate',
    'country',
    'company',
    'payroll',
    'payrollInstance',
    'payrollCurrency',
    'reportingCurrency',
    'reportingRate',
    'businessUnit',
    'costCenter',
    'department'
  )

  const allCurrencies = getCurrencies(state)
  const companyId = values.company ? values.company : null
  const isHQ = values.company && values.company.length ? getCompanyRef(state, { companyId }).isHQ : null

  return {
    // All selected values
    selected: values,
    initialValues: {
      payrollCurrency: null,
    },
    // All countries in the system
    countries: getCountriesByAuth(state).sort(sortByName),
    // Companies by selected country
    companies: values.country
      ? getCompaniesByCountriesByAuth(state, { countriesIds: isArray(values.country) ? values.country : [values.country] }).sort(sortByName)
      : getCompaniesByAuth(state, props).sort(sortByName),
    // All currencies
    currencies: allCurrencies,
    fxRates: getFxRates(state),
    // Business Units by selected countries
    businessUnits: isHQ ? getBusinessUnitsWithCompanyWithCountry(state).sort(sortByName) : getBusinessUnitsByCompany(state, { companyId }).sort(sortByName),
    // Cost Centers by selected countries
    costCenters: isHQ ? getCostCentersWithCompanyWithCountry(state).sort(sortByName) : getCostCentersByCompany(state, { companyId }).sort(sortByName),
    // Departments by selected countries
    departments: isHQ ? getDepartmentsWithCompanyWithCountry(state).sort(sortByName) : getDepartmentsByCompany(state, { companyId }).sort(sortByName),
  }
}

FiltersEmployeeDataChangesReportContainer.propTypes = {
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
}

export default connect(mapStateToProps)(FiltersEmployeeDataChangesReportContainer)
