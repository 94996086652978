import { reportGroupingsDescriptionsEnum, reportGroupingsEnum } from '../enums/reportGroupings'
import reports from '../../configs/reports'
import ReportItemDetailBuilder from './ReportItemDetailBuilder'

class ReportItemBuilder {
  constructor (type = null) {
    this.type = type
    this.reports = []
    this.mappedReports = {}
    this.buildReportItems()
    this.buildMappedReports()
  }

  buildReportItems = () => {
    switch (this.type) {
    case reportGroupingsEnum.HR:
      this.reports = [
        reports.GLOBAL_WORK_HEADCOUNT,
        reports.GLOBAL_JOINERS_HEADCOUNT,
        reports.GLOBAL_LEAVERS_HEADCOUNT,
        reports.WORKFORCE_HEADCOUNT,
        reports.JOINERS_HEADCOUNT,
        reports.LEAVERS_HEADCOUNT,
        reports.WORKFORCE_DETAIL,
        reports.JOINERS_DETAIL,
        reports.LEAVERS_DETAIL,
        reports.EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT,
        reports.EMPLOYEE_SELF_SERVE_ACCESS_REPORT,
        reports.EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT,
      ]
      break
    case reportGroupingsEnum.FINANCE:
      this.reports = [
        reports.GLOBAL_TOTAL_EMPLOYER_COSTS,
        reports.GLOBAL_PAYROLL_ELEMENT_COSTS,
        reports.GLOBAL_PAY_ELEMENT_COSTS,
        reports.GLOBAL_EMPLOYEE_DEDUCTION_COSTS,
        reports.GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS,
        reports.GLOBAL_EMPLOYER_CONTRIBUTION_COSTS,
        reports.TOTAL_EMPLOYER_COSTS,
        reports.PAYROLL_ELEMENT_COSTS,
        reports.TOTAL_PAY_ELEMENT_COSTS,
        reports.TOTAL_EMPLOYEE_DEDUCTION_COSTS,
        reports.TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS,
        reports.TOTAL_EMPLOYER_CONTRIBUTION_COSTS,
        reports.EMPLOYER_COSTS_DETAIL,
        reports.PAYROLL_ELEMENT_COSTS_DETAIL,
        reports.PAY_ELEMENTS_COSTS_DETAIL,
        reports.EMPLOYEE_DEDUCTION_COSTS_DETAIL,
        reports.EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL,
        reports.EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL,
        reports.VARIANCE_REPORT,
      ]
      break
    case reportGroupingsEnum.PAYROLL:
      this.reports = [
        reports.PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY,
        reports.PAYRUN_MANAGEMENT_REPORT,
        reports.PAYRUN_REPORT,
        reports.PAYROLL_CHANGES_REPORT,
      ]
      break
    case reportGroupingsEnum.CONTROL_AND_GOVERNANCE:
      this.reports = [
        reports.GLOBAL_WORKFLOW_AUDIT,
        reports.GLOBAL_PAY_AND_TAXES_REPORT,
        reports.GLOBAL_ACCESS_MANAGEMENT,
        reports.WORKFLOW_AUDIT,
        reports.PAYROLL_WORKFLOW_AUDIT,
        reports.ACCESS_MANAGEMENT,
        reports.CHANGE_LOG,
        reports.CHANGE_LOG_PAYROLL_DATA,
        reports.CHANGE_LOG_GENERAL_UPDATES,
        reports.ROLES_AND_RIGHTS_CHANGE_LOG,
        reports.PAY_AND_TAXES_REPORT,
        reports.PAY_ELEMENTS_LIST,
        reports.EMPLOYEE_DEDUCTIONS_LISTING,
        reports.EMPLOYEE_NET_DEDUCTIONS_LISTING,
        reports.EMPLOYER_CONTRIBUTIONS_LISTING,
      ]
      break
    default:
      break
    }
  }

  get reportTypes () {
    return this.reports
  }

  get title () {
    let title = ''
    switch (this.type) {
    case reportGroupingsEnum.HR:
      title = reportGroupingsEnum.HR
      break
    case reportGroupingsEnum.FINANCE:
      title = reportGroupingsEnum.FINANCE
      break
    case reportGroupingsEnum.PAYROLL:
      title = reportGroupingsEnum.PAYROLL
      break
    case reportGroupingsEnum.CONTROL_AND_GOVERNANCE:
      title = reportGroupingsEnum.CONTROL_AND_GOVERNANCE
      break
    default:
      break
    }
    return title
  }

  get description () {
    let description = ''
    switch (this.type) {
    case reportGroupingsEnum.HR:
      description = reportGroupingsDescriptionsEnum.HR
      break
    case reportGroupingsEnum.FINANCE:
      description = reportGroupingsDescriptionsEnum.FINANCE
      break
    case reportGroupingsEnum.PAYROLL:
      description = reportGroupingsDescriptionsEnum.PAYROLL
      break
    case reportGroupingsEnum.CONTROL_AND_GOVERNANCE:
      description = reportGroupingsDescriptionsEnum.CONTROL_AND_GOVERNANCE
      break
    default:
      break
    }
    return description
  }

  /**
   * Method to build up the reverse list when needing to find a reports category
   */
  buildMappedReports = () => {
    this.mappedReports[reportGroupingsEnum.REPORT_DESIGNER] = [reports.CUSTOM_BUILD_REPORT.VALUE]
    this.mappedReports[reportGroupingsEnum.HR] = [
      reports.GLOBAL_WORK_HEADCOUNT.VALUE,
      reports.GLOBAL_JOINERS_HEADCOUNT.VALUE,
      reports.GLOBAL_LEAVERS_HEADCOUNT.VALUE,
      reports.WORKFORCE_HEADCOUNT.VALUE,
      reports.JOINERS_HEADCOUNT.VALUE,
      reports.LEAVERS_HEADCOUNT.VALUE,
      reports.WORKFORCE_DETAIL.VALUE,
      reports.JOINERS_DETAIL.VALUE,
      reports.LEAVERS_DETAIL.VALUE,
      reports.EMPLOYEE_SELF_SERVE_ACCESS_STATUS_REPORT.VALUE,
      reports.EMPLOYEE_SELF_SERVE_NO_ACCESS_REPORT.VALUE,
      reports.EMPLOYEE_SELF_SERVE_ACCESS_REPORT.VALUE,
    ]
    this.mappedReports[reportGroupingsEnum.FINANCE] = [
      reports.VARIANCE_REPORT.VALUE,
      reports.GLOBAL_TOTAL_EMPLOYER_COSTS.VALUE,
      reports.TOTAL_EMPLOYER_COSTS.VALUE,
      reports.EMPLOYER_COSTS_DETAIL.VALUE,
      reports.GLOBAL_PAY_ELEMENT_COSTS.VALUE,
      reports.TOTAL_PAY_ELEMENT_COSTS.VALUE,
      reports.PAY_ELEMENTS_COSTS_DETAIL.VALUE,
      reports.GLOBAL_EMPLOYER_CONTRIBUTION_COSTS.VALUE,
      reports.TOTAL_EMPLOYER_CONTRIBUTION_COSTS.VALUE,
      reports.EMPLOYER_CONTRIBUTIONS_COSTS_DETAIL.VALUE,
      reports.GLOBAL_EMPLOYEE_DEDUCTION_COSTS.VALUE,
      reports.TOTAL_EMPLOYEE_DEDUCTION_COSTS.VALUE,
      reports.EMPLOYEE_DEDUCTION_COSTS_DETAIL.VALUE,
      reports.GLOBAL_EMPLOYEE_NET_DEDUCTION_COSTS.VALUE,
      reports.TOTAL_EMPLOYEE_NET_DEDUCTION_COSTS.VALUE,
      reports.EMPLOYEE_NET_DEDUCTION_COSTS_DETAIL.VALUE,
      reports.GLOBAL_PAYROLL_ELEMENT_COSTS.VALUE,
      reports.PAYROLL_ELEMENT_COSTS.VALUE,
      reports.PAYROLL_ELEMENT_COSTS_DETAIL.VALUE,
    ]
    this.mappedReports[reportGroupingsEnum.PAYROLL] = [
      reports.PAYROLL_CHANGES_REPORT.VALUE,
      reports.PAYRUN_MANAGEMENT_REPORT_BY_SUBCATEGORY.VALUE,
      reports.PAYRUN_MANAGEMENT_REPORT.VALUE,
      reports.PAYRUN_REPORT.VALUE,
    ]
    this.mappedReports[reportGroupingsEnum.CONTROL_AND_GOVERNANCE] = [
      reports.GLOBAL_WORKFLOW_AUDIT.VALUE,
      reports.WORKFLOW_AUDIT.VALUE,
      reports.PAYROLL_WORKFLOW_AUDIT.VALUE,
      reports.CHANGE_LOG.VALUE,
      reports.CHANGE_LOG_PAYROLL_DATA.VALUE,
      reports.CHANGE_LOG_GENERAL_UPDATES.VALUE,
      reports.ROLES_AND_RIGHTS_CHANGE_LOG.VALUE,
      reports.GLOBAL_PAY_AND_TAXES_REPORT.VALUE,
      reports.PAY_AND_TAXES_REPORT.VALUE,
      reports.PAY_ELEMENTS_LIST.VALUE,
      reports.EMPLOYER_CONTRIBUTIONS_LISTING.VALUE,
      reports.EMPLOYEE_DEDUCTIONS_LISTING.VALUE,
      reports.EMPLOYEE_NET_DEDUCTIONS_LISTING.VALUE,
      reports.GLOBAL_ACCESS_MANAGEMENT.VALUE,
      reports.ACCESS_MANAGEMENT.VALUE,
    ]
  }

  getLockedReportTypes = (reportType) => {
    if (!reportType) return []

    const reportLockedItemBuilder = new ReportItemDetailBuilder(reportType)
    return reportLockedItemBuilder.lockedInItems
  }

  /**
   * Method to retrieve which category the report has been sorted into
   * @param {reportType} reportType int
   * @returns The mapped report category name based on the report type
   */
  getReportTypeCategory = (reportType) => {
    for (const [key, value] of Object.entries(this.mappedReports)) {
      if (value.includes(reportType)) {
        return key
      }
    }
  }

  /**
   * Method to retrieve the display name for any given report
   * @param {reportType} reportType int
   * @returns The FE label name for the report
   */
  getReportNameCategory = (reportType) => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(reports)) {
      if (value.VALUE === reportType) {
        return value.LABEL
      }
    }
  }

  /**
   * Retrieves the metadata for a specific report type.
   *
   * @param {string} reportType - The type of the report to retrieve metadata for.
   * @returns {Object} An object containing the key, label, description, and value of the report if found, otherwise an empty object.
   */
  getReportMetadata = (reportType) =>
    Object.entries(reports).reduce((acc, [key, data]) => {
      if (data.VALUE === reportType) {
        acc = {
          key,
          label: data.LABEL,
          description: data.DESCRIPTION,
          value: data.VALUE,
        }
      }
      return acc
    }, {})

  getReportGraphsByCategoryId = (reportType) => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(reports)) {
      if (value.VALUE === reportType) {
        return value.REPORT_CHARTS
      }
    }
  }

  getReportGraphsLogicByCategoryId = (reportType) => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(reports)) {
      if (value.VALUE === reportType) {
        return value.CHART_LOGIC
      }
    }
  }
}

export default ReportItemBuilder
