import Fetcher from 'containers/Fetcher'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { getEmployeesWithCompanyByCompanyAvailableForPayroll } from '../selectors/employees'
import { createFilter } from 'utils/redux/filter'
import { hasEmployees } from 'redux/helpers/hasEmployees'
import PayrollPaygroupEmployees from 'components/employees/PayrollPaygroupEmployees'

const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = (state, props) => {
  const { payrollId, companyId } = props

  // get view's employees
  let employees = getEmployeesWithCompanyByCompanyAvailableForPayroll(
    state,
    {
      payrollId: payrollId,
      companyId: companyId
    }
  )

  return {
    employeeSystemUsers: employees,
    hasEmployees: () => hasEmployees(state, 'payrollAttachEmployees', employees),
  }
}

const Container = (connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'payrollAttachEmployees'
})(PayrollPaygroupEmployees)))

export default Fetcher(Container, [
  {
    name: 'employeeSystemUsers',
    params: [{
      _computed: {
        filter: (state, { companyId }) => {
          return createFilter({
            company: companyId,
            payloadFormat: 'reduced'
          })
        }
      },
      disableObsoleteFlow: true
    }]
  },
])
