import createSelector from 'utils/createSelector'
import { getPayrollByIdWithEmployeeIds } from '../../../../../selectors/payroll'
import _ from 'lodash'
import moment from 'moment'

// get the company id
const getCompanyId = (state, props) => parseInt(props.companyId, 10)

const getFiltered = state => state.employeeSystemUsers.filters.search
  ? state.employeeSystemUsers.filters.search.ids
  : state.employeeSystemUsers.filterIds

// return employees by company
// that aren't participating in current payroll
export const getEmployeesWithCompanyByCompanyAvailableForPayroll = createSelector(getCompanyId, getPayrollByIdWithEmployeeIds, getFiltered,
  ({ EmployeeSystemUser }, companyId, payroll, filtered) => {
    return EmployeeSystemUser
      .filter(employee => filterEmployees(employee, companyId, payroll, filtered))
      .toModelArray()
      .filter(employee => {
        // Only employees with the same currency as the payroll currency should be returned.
        // Therefore if the employee has a currency and the currency differs to the payroll's currency,
        // then don't return the employee.
        // If employee has no currency, it should not be returned.
        if ((!employee.ref.paymentCurrency) || (employee.ref.paymentCurrency && (employee.ref.paymentCurrency !== payroll.currency))) {
          return false
        }

        // return the employee if payroll is recurring
        if (!payroll.isRecurring) {
          return true
        }

        let shouldBeAdded = true

        // exclude employees that participate in another
        // recurring payroll
        shouldBeAdded = !(employee
          .payrollEmployees
          .toModelArray()
          .some(pivot => pivot.payroll.isRecurring))

        if (shouldBeAdded === false) { return shouldBeAdded }

        // exclude employee that participate in paygroup
        // that is recurring
        shouldBeAdded = !(employee
          .paygroupEmployees
          .toModelArray()
          .some(pivot => pivot.paygroup.isRecurring))

        return shouldBeAdded
      })
      .map(employee => ({
        ...employee.ref,
        company: {
          ...employee.company.ref
        },
        businessUnit: employee.businessUnit ? employee.businessUnit.ref : null
      }))
  })

const filterEmployees = (employee, companyId, payroll, filtered) => {
  const isEmployee = employee.isEmployee
  const isAccessable = employee.isAccessable
  const isTheSameCompany = (employee.company === companyId)
  const isNotInPayroll = !_.includes(payroll.employeeIds, employee.id)
  const hasFilter = (filtered ? filtered.includes(employee.id) : true)
  const isOnceOffPayroll = payroll.frequency === 'Once off'

  const isEmpEndDateAfterPayrollStartDate = (
    employee.endDate
      ? moment(employee.endDate.date).toDate() > moment(payroll.startDate.date).toDate()
      : true)
  const isEmpStartDateBeforePayrollEndDate =
    (employee.startDate && payroll.endDate) && isOnceOffPayroll
      ? moment(employee.startDate.date).toDate() <= moment(payroll.endDate.date).toDate()
      : employee.startDate && payroll.endDate
        ? moment(employee.startDate.date).toDate() < moment(payroll.endDate.date).toDate()
        : true
  return (isEmployee && isAccessable && isTheSameCompany && isNotInPayroll &&
    hasFilter && isEmpEndDateAfterPayrollStartDate && isEmpStartDateBeforePayrollEndDate)
}
