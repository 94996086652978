import createSelector from 'utils/createSelector'
import { getPayrollByIdWithEmployeeIds } from '../../../../../selectors/payroll'
import _ from 'lodash'

// get the company id
const getCompanyId = (state, props) => parseInt(props.companyId, 10)

const getFiltered = state => state.employeeSystemUsers.filters.search
  ? state.employeeSystemUsers.filters.search.ids
  : state.employeeSystemUsers.filterIds

// return employees by company
// that aren't participating in current payroll
export const getEmployeesWithCompanyByCompanyAttachedToPayroll = createSelector(getCompanyId, getPayrollByIdWithEmployeeIds, getFiltered,
  ({ EmployeeSystemUser }, companyId, payroll, filtered) => {
    return EmployeeSystemUser
      .filter(
        employee =>
          _.includes(payroll.employeeIds, employee.id) &&
          (filtered ? filtered.includes(employee.id) : true)
      )
      .toModelArray()
      .map(employee => ({
        ...employee.ref,
        company: {
          ...employee.company.ref
        },
        businessUnit: employee.businessUnit ? employee.businessUnit.ref : null
      }))
  })
