import useQuery from 'hooks/useQuery'
import React from 'react'
import PayrollInstanceView from 'routes/Payruns/components/PayrollInstanceView'
import { PayrollTabModalOptionButtons } from 'utils/buttonOptions'

const { actionOwnersButtonItem } = PayrollTabModalOptionButtons

export default props => {
  return <PayrollInstanceView
    payrunState='previousWithExtraTasks'
    query={useQuery()}
    heading='Post Payroll Steps'
    buttonOptions={actionOwnersButtonItem}
    {...props}
  />
}
