import { addDays, format, isValid, isWeekend, parseISO } from 'date-fns'

/**
 * Computes a date that is a specified number of days after the given date.
 * If the input date is a string, it will be parsed as an ISO 8601 string.
 * If the input date is already a Date object, it will be used directly.
 * If the date is invalid or not provided, the function will return null.
 *
 * @param {string | Date} fromDate - The date to start from. Can be an ISO 8601 string or a Date object.
 * @param {number} [range=365] - The number of days to add to the start date. Defaults to 365 days.
 * @returns {Date | null} - The computed date or null if the input date is invalid.
 */
export const getMaxDate = (fromDate, range = 365) => {
  const date = typeof fromDate === 'string' ? parseISO(fromDate) : fromDate
  return date && isValid(date) ? addDays(date, range) : null
}

/**
 * Get dates in a specific range
 *
 * @param {Date | string} startDate - The start date of the range.
 * @param {Date | string} endDate - The end date of the range.
 * @param {Boolean} [includeWeekends=true] - Whether to include weekends in the range.
 * @param {String} [dateFormat='yyyy-MM-dd'] - The format for the output dates.
 * @returns {Array} - An array of formatted date strings.
 */

/**
 * Get dates in a specific range
 *
 * @param {Date | string} startDate - The start date of the range.
 * @param {Date | string} endDate - The end date of the range.
 * @param {Boolean} [includeWeekends=true] - Whether to include weekends in the range.
 * @param {String} [dateFormat='yyyy-MM-dd'] - The format for the output dates.
 * @returns {Array} - An array of formatted date strings.
 */
export const getDatesInRange = (startDate, endDate, includeWeekends = true, dateFormat = 'yyyy-MM-dd') => {
  const start = typeof startDate === 'string' ? parseISO(startDate) : startDate
  const end = typeof endDate === 'string' ? parseISO(endDate) : endDate

  const daysBetween = Math.ceil((end - start) / (1000 * 60 * 60 * 24))

  const dates = Array.from({ length: daysBetween + 1 }, (_, index) => {
    const currentDate = addDays(start, index)
    return !isWeekend(currentDate) || includeWeekends ? format(currentDate, dateFormat) : null
  }).filter((date) => date !== null)

  return dates
}

/**
 * Checks if the duration between two dates is more than a month and a year.
 *
 * @param {Object} params - The parameters object.
 * @param {Date} params.fromDate - The start date.
 * @param {Date} params.toDate - The end date.
 * @returns {boolean} True if the duration is more than a month and a year, false otherwise.
 */
export const isDurationMoreThanAMonthAndYear = ({ fromDate, toDate } = {}) => {
  if (!fromDate || !toDate) {
    return false
  }
  const dateRangeLength = getDatesInRange(fromDate, toDate)?.length || 0
  const isLessThanMonth = dateRangeLength <= 32
  const isLessThanYear = dateRangeLength <= 366
  return !isLessThanMonth && !isLessThanYear
}

/**
 * A function that determines whether the range between two dates is less than or equal
 * to a month (32 days). It takes an object containing 'fromDate' and 'toDate' properties
 * as input and returns a boolean result.
 *
 * @param {Object} params - The input object containing date parameters.
 * @param {Date|string} params.fromDate - The start date of the range.
 * @param {Date|string} params.toDate - The end date of the range.
 * @returns {boolean} - Returns true if the range is less than or equal to 32 days, otherwise false.
 */
export const isLessThanAMonth = ({ fromDate, toDate } = {}) => {
  if (!fromDate || !toDate) {
    return false
  }
  const dateRangeLength = getDatesInRange(fromDate, toDate)?.length || 0
  return dateRangeLength <= 32
}
