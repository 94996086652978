import { apiUrl, isMultitenancyEnabled } from 'utils/config'

export const SERVER_ERROR = 'Something wrong happened on the server side. Please try again! ' + 'If the issue persists contact us at support@payslip.com!'

export const TOKEN_ERROR = 'Session expired. \n' + 'To continue working, please log in to your account again. \n' + 'Thank you'

/**
 * Get the URL, that will be used to make an API call
 *
 * We have different APIs endpoint, because of the multi tenancy support and different system modes(COS and Tenant):
 *
 * 1. `apiUrl` - Default API, where we make the general calls (get current tenant api address call, for example).
 * 2. `state.config.apiUrl` - API address, when you're logged in Tenant mode or in COS mode (but without selecting a Client / Vendor / Tenant).
 * 3. `state.tenants.url` - API address, whey you're logged in COS mode and you select a Client / Vendor / Tenant to operate with.
 * 4. `state.vendorTenant.url` - API address, when you're logged in COS or Tenant mode, already chosen a Vendor (`state.tenants.url`),
 * and wants to see some information for the selected Vendor's Clients.
 *
 * We want to use vendorTenant API url (`state.vendorTenant.url`) only in some edge cases:
 *
 * Case 1:
 * --------------
 * Log in as a COT, go to Vendor, and then select a vendor client.
 * Then we have the following API urls set in our store:
 * COS (apiUrl) -> Vendor (tenants) -> Tenant (vendorTenant)
 *
 * The case here is that by default most of the API calls should be addressing the vendor API (tenants.url),
 * only in a few edge cases we need to address the client API (vendorTenant.url)
 *
 * Case 2:
 * --------------
 * Log in as a Vendor and select a vendor client.
 * Then we have the following API urls set in our store:
 * Vendor (apiUrl) -> Tenant (vendorTenant)
 *
 * The case here is almost the same as Case 1 - we need to make most of our API calls to the vendor API, which in this case is stored in `apiUrl`,
 * and only a few of the requests need to be addressing the client API (vendorTenant.url)
 *
 * Case 3:
 * --------------
 * Log in as COT and go to tenant
 * Then we have the following API urls set in our store:
 * COS (apiUrl) -> Tenant (tenants)
 *
 * In this case all request are addressing `tenants.url` - no need to switch between API urls while in tenant
 *
 * Case 4:
 * --------------
 * Log in as Tenant - Payroll Admin, Payroll Analyst, Payroll Data entry
 * Then we have the following API url set in our store:
 * Tenant (apiUrl)
 *
 * In that case all request are addressing `apiUrl` - no need to switch between API urls while in tenant
 *
 * --------------------------------------------
 * Summary:
 *
 * Since there are two cases where we need to switch between `tenant.url`/`apiUrl` and `vendorTenant.url`
 * we implemented a flag `forceVendorTenantAPI` and we should set it to `true` ONLY in these edge cases
 * where we NEED TO address the vendorTenant API.
 *
 * This way we DON'T NEED TO take into account if we are currently logged as a COT or a Vendor - in both
 * cases we need to address the vendorTenant API.
 *
 * @param {String} uri
 * @param {Object} state
 * @param {Object} params
 * @returns {string}
 */
const getUrl = (uri, state, { forceDefaultAPI, forceUserAPI, forceVendorTenantAPI, isURL }) => {
  // Sometimes (for example when we download a file via Amazon) we request external URL
  // In that cases, we don't use our API base address
  if (isURL) return uri
  let url

  if (forceDefaultAPI || !isMultitenancyEnabled) {
    // Use default API if it's set explicitly or if multi tenancy is disabled
    url = apiUrl
  } else if (forceUserAPI) {
    // Use the API, that's specific to the current logged in user, according to the tenant
    url = state.config.apiUrl
  } else if (forceVendorTenantAPI) {
    // Use the API, that's specific to the currently selected vendorTenant
    url = state.vendorTenant.url
  } else {
    // Otherwise prioritize all the possible APIs.
    url = state.tenants.url || state.config.apiUrl || apiUrl
  }
  return `${url}${uri}`
}

/**
 * Construct the API params.
 * Here the State is passed in, so we can add some defaults as `accessToken`
 *
 * @param {Object} state - Redux state
 * @param {String} uri - API uri, where the request will point to
 * @param {Object} params - Params, that fine-tune the API request.
 * Please refer to `getUrl` function  and `api` utility, to check the supported props.
 * @param {Object} payload - API data, that the request will sent
 */
export const getParams = (state, uri, params = {}) => [getUrl(uri, state, params), { ...params, accessToken: state.auth.accessToken }]

const api = {
  /**
   * Here we validate if the response is OK.
   *
   * If it's fine - we return it to the Promise chain.
   * Otherwise - we reject the promise with appropriate Error.
   *
   * @param {Object} response
   * @param {String} contentType
   * @private
   */
  _handleResponse: (response) => {
    const status = response.status
    const contentType = response.headers.get('Content-Type')
    // Something wrong happened on BE (server error),
    // and we don't have an error message, because the response is not json.
    if (status >= 500 && contentType !== 'application/json') {
      let error = new Error(SERVER_ERROR)
      return Promise.reject(error)
    }

    // The tokens (access and refresh) are expired or blacklisted on BE
    if (status === 401) {
      let error = new Error(TOKEN_ERROR)

      return Promise.reject(error)
    }

    // If response is json, just return the json
    if (contentType === 'application/json') return response.json()

    // Otherwise we assume that we return a file
    return {
      file: response.blob(),
      contentType: response.headers.get('Content-Type'),
    }
  },

  /**
   * Fetch
   *
   * @param url
   * @param {Object} params:
   *  - { boolean } removeContentType - If we remove the content type, a default one will be added by `fetch` library.
   *  It's really useful when uploading a multipart/form file.
   *  - {String} accessToken
   */
  fetch: (url, params = {}) => {
    const { removeContentType = false, accessToken, ...requestParams } = params
    // Default headers
    let headers = {
      Authorization: 'Bearer ' + accessToken,
    }

    // Set the default content type, unless it is explicitly removed
    if (!removeContentType) {
      headers['Content-Type'] = 'application/json'
    }

    const fetchParams = {
      ...requestParams,
      headers: {
        ...headers,
        ...requestParams.headers,
      },
    }

    return fetch(url, fetchParams).then(api._handleResponse)
  },
  post: (url, params = {}) => {
    const { payload, ...rest } = params

    const fetchParams = {
      ...rest,
      body: JSON.stringify(payload),
      method: 'POST',
    }

    return api.fetch(url, fetchParams)
  },
  put: (url, params = {}) => {
    const { payload, ...rest } = params

    const fetchParams = {
      ...rest,
      body: JSON.stringify(payload),
      method: 'PUT',
    }

    return api.fetch(url, fetchParams)
  },
  patch: (url, params = {}) => {
    const { payload, ...rest } = params

    const fetchParams = {
      ...rest,
      body: JSON.stringify(payload),
      method: 'PATCH',
    }

    return api.fetch(url, fetchParams)
  },
  delete: (url, params = {}) => {
    const { payload, ...rest } = params

    const fetchParams = {
      ...rest,
      ...(payload ? { body: JSON.stringify(payload) } : {}),
      method: 'DELETE',
    }

    return api.fetch(url, fetchParams)
  },
  upload: (url, params = {}) => {
    const { payload, ...rest } = params
    const fetchParams = {
      ...rest,
      body: payload,
      method: 'POST',
      removeContentType: true,
    }

    return api.fetch(url, fetchParams)
  },
}

export default api
