import Fetcher from 'containers/Fetcher'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { getEmployeesWithCompanyByCompanyAttachedToPaygroup } from '../selectors/employees'
import { createFilter } from 'utils/redux/filter'
import { hasEmployees } from 'redux/helpers/hasEmployees'
import PayrollPaygroupEmployees from 'components/employees/PayrollPaygroupEmployees'

const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = (state, props) => {
  const { paygroup } = props

  let employees = getEmployeesWithCompanyByCompanyAttachedToPaygroup(
    state,
    {
      paygroupId: paygroup.id,
      companyId: paygroup.company
    }
  )

  return {
    employeeSystemUsers: employees,
    hasEmployees: () => hasEmployees(state, 'paygroupDetachEmployees', employees),
  }
}

const Container = (connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'paygroupDetachEmployees'
})(PayrollPaygroupEmployees)))

export default Fetcher(Container, [
  {
    name: 'employeeSystemUsers',
    params: [{
      _computed: {
        filter: (state, { paygroup }) => {
          return createFilter({
            paygroup: paygroup.id,
            payloadFormat: 'reduced'
          })
        }
      },
      disableObsoleteFlow: true
    }]
  },
])
