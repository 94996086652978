import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import { formValueSelector, reduxForm } from 'redux-form'
import { types } from 'redux/config/documents'
import { getInitialValues } from 'redux/selectors/documentUsers'
import { getKeyPeopleByAccessArea, getKeyPeopleWithSharedDocumentsWithoutLoggedInUser } from 'redux/selectors/employees'
import { getExternalVendorUsersByCompanyIdHavingCredentials, getExternalVendorUsersByVendorIdWithSharedDocuments } from 'redux/selectors/externalVendorUsers'
import { getCompanyIdByTypeClass } from 'redux/helpers/companyIdByTypeClass'
import { invalidateDocuments } from 'redux/actions/document'
import { resetDocumentUsers, updateDocumentUsers } from 'redux/actions/documentUsers'
import { invalidateDocumentLogs } from 'redux/actions/documentLogs'
import { createFilter } from 'utils/redux/filter'
import { getFilteredDocuments } from 'redux/selectors/document'
import { isCot } from 'redux/selectors/auth'
import createDocumentFilter from 'redux/filters/createDocumentFilter'
import DocumentTabs from 'components/documents/DocumentTabs'
import { buildShareBtnLabel } from 'redux/helpers/buildShareBtnLabel'
import { getSpecialStatusVendorId } from 'redux/selectors/vendorSwitch'
import { docTypeIDsRestrictedForVendors } from 'utils/enums/documentTypes'
import documentEmployeeFilter, { handleOnFilter } from './utils/documentEmployeeFilter'
import { buildFilterName } from 'redux/filters/commonFilters'
import { invalidateEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import { invalidatePayrollInstanceTaskOwner } from 'redux/actions/payrollInstanceTaskOwner'
import { getInitialFiltersFromState, getInitialSortFromState } from 'redux/helpers/InitialFilters'
import { shareModalsFilterMapping, shareModalsSortMapping } from 'utils/enums/filterMappingEnums'

const filterKeys = {
  payroll: 'companyPayrollDocumentShare',
  company: 'companyDocumentShare',
  payrollInstance: 'payrollInstanceDocumentShare',
}
/**
 * Important Gotcha: About building Users and their Form initial values.
 * As you will see below, when we are building the needed Users to be listed in the tabs,
 * but only these Users having credentials.
 * BUT, here is the Gotcha: for the User initial values we are building all the Users having a shared document,
 * no matter having or not the needed credentials.
 * BUT why? Because we want to keep the shared file for the inactive (without credentials) users too.
 * Here's the case:
 * 1. Let's say we want to share a file to John.
 * 2. John has credentials (his account is active) and we share the file successfully.
 * 3. A month later, we decide to make John account inactive.
 * 4. And now in the Share modal, we won't list John as sharing option, because his account is inactive,
 * but we still want to keep the file shared and that's the reason we keep adding it in the Form initial values.
 */

const DocumentsShareModalFormView = ({ tab, ...rest }) => {
  const [tabIndex, setTabIndex] = useState(tab)

  return <DocumentTabs tabIndex={tabIndex} onChangeTab={(idx) => setTabIndex(idx)} {...rest} />
}

const mapStateToProps = (state, props) => {
  const { typeClass, type, payrollInstanceId, documentId, documentTenant, filters } = props

  const companyId = getCompanyIdByTypeClass(typeClass, state, props)

  const filterKey = filterKeys[typeClass]
  const filterName = buildFilterName({ companyId, payrollInstanceId, documentId })[filterKey]

  const isFetchedAtLeastOnce = state.employeeSystemUsers.filters[filterName]?.ids

  if (!isFetchedAtLeastOnce) return { isInitialFetching: true }

  const {
    totalCount,
    params: { offset, limit },
  } = state.employeeSystemUsers.filters[filterName]

  /*
   * DocumentsShareModalFormContainer combines containers for the three share non vendor tabs:
   * - ShareActionOwners
   * - ShareWithinCompany
   * - ShareOutsideCompany
   *
   * This is needed because we have to submit TOGETHER  all the three share tabs and sync the data.
   *
   * That's why we get here separately:
   * - actionOwnersUsers
   * - keyPeopleUsers
   * - externalVendorUsers
   * pass them to the tabs and set their initial values.
   */
  const keyPeople = getKeyPeopleByAccessArea(state, { companyId, documentId, documentTenant })

  // In the case we share a Document to a Payroll, we should exclude Accounting users, because they don't have access
  // to this feature (Company->Payroll->Files), therefore they can't access the Document, even it's shared with them.
  // However, there is an edge-case, if an Accounting user is ActionOwner in the tree.
  // Then, it will be shown, because the limitation is not applied there.
  // It's communicated with the PM and we won't handle it for now.

  const keyPeopleWithinCompanyUsers = getKeyPeopleWithSharedDocumentsWithoutLoggedInUser(state, filterName)
  const keyPeopleInitialValues = getInitialValues(state, {
    users: keyPeople,
    userType: 'employeeUser',
  })

  const filteredDocuments = getFilteredDocuments(state, { filter: filters.document.name })
  const document = filteredDocuments[0]

  const allowDocumentSftpShare = document.extraData?.canBeSharedWithSftp

  // Don't build the External Vendors tab data, if the current type of Document doesn't have such tab,
  // according to the config value.
  // If we always build the data and pass it as initial form values, then this will reflect to the data that will be
  // sent to the BE API, via `updateDocumentUsers`
  // Some document types shouldn't be shared with vendors, so we're comparing the restricted list with the types of the shared document
  const shouldBuildExternalUsersData =
    types[typeClass].clientShareTabs.find((tab) => tab.name === 'outsideCompany') &&
    !docTypeIDsRestrictedForVendors.some((docType) => document.documentTypes?.includes(docType))

  const externalVendorUsersHavingCredentials = shouldBuildExternalUsersData
    ? getExternalVendorUsersByCompanyIdHavingCredentials(state, { companyId, documentId, documentTenant })
    : []

  const onboardingVendor = getSpecialStatusVendorId(state, { companyId, vendorStatus: 'onboarding' })
  const onboardingVendorUsers = onboardingVendor
    ? getExternalVendorUsersByVendorIdWithSharedDocuments(state, { vendorId: onboardingVendor, documentId, documentTenant })
    : null
  const onboardingVendorUsersInitialValues = getInitialValues(state, { users: onboardingVendorUsers, userType: 'onboardingVendorUser' })

  const offboardingVendor = getSpecialStatusVendorId(state, { companyId, vendorStatus: 'offboarding' })
  const offboardingVendorUsers = offboardingVendor
    ? getExternalVendorUsersByVendorIdWithSharedDocuments(state, { vendorId: offboardingVendor, documentId, documentTenant })
    : null
  const offboardingVendorUsersInitialValues = getInitialValues(state, { users: offboardingVendorUsers, userType: 'offboardingVendorUser' })

  const hasOnboardingVendor = !!onboardingVendor.length
  const hasOffboardingVendor = !!offboardingVendor.length

  const specialStatusVendorId = hasOnboardingVendor ? parseInt(onboardingVendor) : hasOffboardingVendor ? parseInt(offboardingVendor) : null

  // The BE will return all users, including the special vendor ones
  // We filter them out here to prevent special vendor users appearing on the "Outside company" user tab
  const externalVendorUsersHavingCredentialsFiltered = externalVendorUsersHavingCredentials.filter((evu) => evu.vendor !== specialStatusVendorId)

  const externalVendorUsersInitialValues = shouldBuildExternalUsersData
    ? getInitialValues(state, { users: externalVendorUsersHavingCredentialsFiltered, userType: 'externalVendorUser' })
    : {}

  const selector = formValueSelector('shareNonVendorFiles')
  const isSftpSelected = selector(state, 'sftp')

  const initialValues =
    typeClass === 'company'
      ? {
        ...keyPeopleInitialValues,
        ...externalVendorUsersInitialValues,
        ...onboardingVendorUsersInitialValues,
        ...offboardingVendorUsersInitialValues,
        sftp: !allowDocumentSftpShare,
      }
      : {
        ...keyPeopleInitialValues,
        ...externalVendorUsersInitialValues,
        sftp: !allowDocumentSftpShare,
      }

  const initialFilters = getInitialFiltersFromState({ state, entity: 'employeeSystemUsers', filterName, filterKeys: shareModalsFilterMapping })
  const initialSort = getInitialSortFromState({ state, entity: 'employeeSystemUsers', filterName, sortKeys: shareModalsSortMapping })

  const currentPage = offset / limit

  return {
    btnText: buildShareBtnLabel(type),
    showSubmitBtn: true,
    keyPeopleWithinCompanyUsers,
    externalVendorUsers: externalVendorUsersHavingCredentialsFiltered,
    initialValues,
    isSftpSelected,
    isCot: isCot(state),
    isOwner: document && document.extraData && document.extraData.isOwner,
    onboardingVendorUsers,
    offboardingVendorUsers,
    hasOnboardingVendor,
    hasOffboardingVendor,
    pagination: {
      totalPages: Math.ceil(totalCount / limit),
      currentPage,
      limit,
      totalCount: totalCount,
    },
    initialFilters,
    initialSort,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { documentId, documentTenant, onShared } = props
  return {
    onSubmit: (data, excludeSftp = false) => {
      return dispatch(updateDocumentUsers(data, documentId, documentTenant, excludeSftp)).then(() => {
        onShared()
        dispatch(invalidateDocuments())
        dispatch(resetDocumentUsers())
        dispatch(invalidateDocumentLogs())
        dispatch(invalidateEmployeeSystemUsers())
        dispatch(invalidatePayrollInstanceTaskOwner())
      })
    },
    onFilter: ({ page = 0, limit, filters, sort }) =>
      dispatch((dispatch, getState) => handleOnFilter({ page, limit, filters, sort }, dispatch, getState, props)),
  }
}

const makePayrollInstanceFilter = (name, filterName = 'payrollInstance') => ({
  name,
  params: [
    {
      _computed: {
        filter: (state, { payrollInstanceId }) =>
          createFilter({
            [filterName]: payrollInstanceId,
          }),
      },
    },
  ],
})

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'shareNonVendorFiles',
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
  })(DocumentsShareModalFormView)
)

const CompanyFetcher = Fetcher(Container, [
  documentEmployeeFilter(),
  'accessAreaPivot',
  'externalVendorUsers',
  'vendorSwitch',
  'companyServicePivot',
  {
    name: 'documentUsers',
    params: [
      {
        _computed: {
          filter: (state, { documentId, documentTenant }) => createFilter({ documentId, documentTenant }),
        },
        disableObsoleteFlow: true,
      },
    ],
  },
  createDocumentFilter({ filterNames: ['id', 'showSftpInfo'] }),
  'payrollService',
  'payrollProcess',
  'payrollStep',
  'payrollTask',
  'companies',
  'parentCompanies',
  'companyProcessPivot',
  'companyStepPivot',
  'companyTaskPivot',
  'countryProcessPivot',
  'countryStepPivot',
  'countryTaskPivot',
  'processes',
  'steps',
  'tasks',
  'payrolls',
])

const PayrollInstanceFetcher = Fetcher(Container, [
  documentEmployeeFilter(),
  'accessAreaPivot',
  'externalVendorUsers',
  'vendorSwitch',
  'companyServicePivot',
  {
    name: 'documentUsers',
    params: [
      {
        _computed: {
          filter: (state, { documentId, documentTenant }) => createFilter({ documentId, documentTenant }),
        },
        disableObsoleteFlow: true,
      },
    ],
  },
  createDocumentFilter({ filterNames: ['id', 'showSftpInfo'] }),
  'payrollService',
  'payrollProcess',
  'payrollStep',
  'payrollTask',
  'companies',
  'parentCompanies',
  'companyProcessPivot',
  'companyStepPivot',
  'companyTaskPivot',
  'countryProcessPivot',
  'countryStepPivot',
  'countryTaskPivot',
  'processes',
  'steps',
  'tasks',
  'payrolls',
  makePayrollInstanceFilter('payrollInstances', 'id'),
  makePayrollInstanceFilter('payrollInstanceService'),
  makePayrollInstanceFilter('payrollInstanceProcess'),
  makePayrollInstanceFilter('payrollInstanceStep'),
  makePayrollInstanceFilter('payrollInstanceTask'),
  makePayrollInstanceFilter('payrollInstanceTaskOwner'),
])

/**
 * This Component is reused for Company, Payroll and PayrollInstance Documents sharing.
 *
 * When we reuse it for PayrollInstance, we have to fetch different data.
 * Because of this we have this default stateless component, in order to determine what data is needed to be fetched.
 *
 * @param props
 * @returns {*}
 */
const DataProvider = (props) => {
  const [showFilters, setShowFilters] = useState(false)

  if (props.typeClass === 'payrollInstance') {
    return <PayrollInstanceFetcher showFilters={showFilters} setShowFilters={setShowFilters} {...props} />
  }

  return <CompanyFetcher showFilters={showFilters} setShowFilters={setShowFilters} {...props} />
}

DataProvider.propTypes = {
  typeClass: PropTypes.string.isRequired,
}

export default DataProvider
