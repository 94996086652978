import React, { useEffect, useState } from 'react'

import { isMobile } from 'mobile-device-detect'

import { useKeycloak } from 'components/Keycloak/KeycloakProvider'
import { setStorageItem } from 'utils/storage'
import { shouldAlertBeDisplayed } from './helper'
import { SystemBannerDialog, SystemBannerDialogMobile } from './content'

/*
 * TODO: After the migration, we only need to delete
 * this component and the whole folder -> src/components/SystemBanner
 * wit the tests. We need to keep the rest because they are useful, reusable tools.
 */

export const SystemBanner = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { keycloak } = useKeycloak()
  const userId = keycloak?.tokenParsed?.sub
  const bannerHasBeenAcceptedKey = `hasbanner_${userId}`

  const dismissOptions = {
    escapeKey: false,
    outsidePress: false,
  }

  useEffect(() => {
    const needsOpen = shouldAlertBeDisplayed(userId, bannerHasBeenAcceptedKey, keycloak)
    if (needsOpen) {
      setIsOpen(true)
    }
  }, [keycloak])

  const handleOkButton = () => {
    if (userId) {
      setStorageItem(bannerHasBeenAcceptedKey, true)
      setIsOpen(false)
    }
  }

  const systemDialogProps = { isOpen, setIsOpen, dismissOptions, handleOkButton }

  return isMobile ? <SystemBannerDialogMobile {...systemDialogProps} /> : <SystemBannerDialog {...systemDialogProps} />
}
