/* eslint-disable max-len */

export const systemBannerTexts = {
  header: 'Important Update: ',
  subHeader: '2FA Verification Method Change',
  subTitle: 'Effective October 2024, we are changing the default Two-Factor Authentication (2FA) method from SMS to Time-based One-Time Password (TOTP). ',
  changeInfo: 'This change enhances security and provides a more reliable verification process.',
  instructions: {
    title: 'Setup Instructions:',
    options: [
      {
        title: 'First Login After the Change:',
        value:
          'When you log in for the first time after the update, the system will prompt you to register your TOTP device. Please download an authentication app, such as Google Authenticator or Microsoft Authenticator, to complete the registration process.',
      },
      {
        title: 'Open Your TOTP App:',
        value: 'Select the option to add a new account.',
      },
      {
        title: 'Scan the QR Code:',
        value: 'Scan the QR code displayed on the login screen to link the new account. Your TOTP app will generate a verification code.',
      },
      {
        title: 'Future Logins:',
        value: 'For future logins, you will retrieve your 2FA code from your TOTP app instead of receiving it via SMS.',
      },
    ],
  },
  contact: 'If you have any questions, please contact: ',
}
