/* eslint-disable camelcase */
import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { hasAccess, isCot } from 'redux/selectors/auth'
import { isFetching } from 'utils/redux/fetching'
import { reduxForm, formValueSelector, change } from 'redux-form'
import ReportingFilters from '../../../components/ReportingFilters'
import { types } from 'redux/config/reports'
import { deleteReport, downloadReport, fetchReportsIfNeeded, invalidateReports, resetReports, updateReport } from 'redux/actions/reports'
import { getCurrencyByPayroll, getCurrencyByPayrollInstance } from '../../../selectors/currencies'
import { getMostRecentRunReportByTypeID, getMostRecentJobById, getCombinedSubcategories, buildUpInitialValuesForReports } from '../../../selectors/reports'
import { fetchCurrenciesIfNeeded } from 'redux/actions/currencies'
import { fetchCompanyCountryTermPivotIfNeeded } from 'redux/actions/companyCountryTermPivot'
import { fetchCountryTermPivotIfNeeded } from 'redux/actions/countryTermPivot'
import { fetchTermsIfNeeded } from 'routes/Terms/modules/actions'
import { fetchTermSubCategoriesIfNeeded } from 'redux/actions/termSubCategories'
import { fetchTermCategoriesIfNeeded } from 'redux/actions/termCategories'
import { getCurrencies, getCurrencyById } from 'redux/selectors/currencies'
import { getFxRatesByIds } from 'redux/selectors/fxRates'
import { getCompanyCountryTermsPivotByCategory } from '../../../selectors/companyCountryTermPivot'
import termCategories from 'redux/config/termCategories'
import { makeScaleValidation } from 'utils/validations'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import Loader from 'components/Loader'
import { showMessage } from 'redux/actions/modal'
import { errorToString } from 'utils/apiErrors'
import { getUser } from 'redux/selectors/employees'
import PropTypes from 'prop-types'
import { createNewReportJob, setLastCreatedJobId } from 'redux/actions/pollingJob'
import { getInitialReportData, getRegenerateSavedReport } from 'routes/Reporting/utils/regenerateReport'

import { uniq } from 'utils/fnkit/array'
import { replace } from 'utils/fnkit/string'
import { isArray, isEmpty } from 'utils/fnkit/typeChecks'

const ReportingFiltersContainer = (props) => {
  const { dispatch, isFetching, hasAccess } = props

  useEffect(() => {
    dispatch(fetchCurrenciesIfNeeded())
    dispatch(fetchCompanyCountryTermPivotIfNeeded())
    dispatch(fetchCountryTermPivotIfNeeded())
    dispatch(fetchTermsIfNeeded())
    dispatch(fetchTermSubCategoriesIfNeeded())
    dispatch(fetchTermCategoriesIfNeeded())
    dispatch(fetchCountriesIfNeeded())
    dispatch(fetchCompaniesIfNeeded())
    dispatch(setLastCreatedJobId(null))
    dispatch(invalidateReports())
    dispatch(resetReports())
  }, [dispatch])

  if (isFetching) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  return hasAccess && <ReportingFilters {...props} />
}

const mapDispatchToProps = (dispatch, props) => {
  /*
   * Reset form fields
   */
  const resetFormFields = (value, fields) => {
    fields.forEach((field) => dispatch(change(props.form, field, null)))
  }

  return {
    resetFormFields,
    /*
     * Reset all form fields on changing the report type.
     * Otherwise, the fields values are still kept in the Store and later submitted to the back-end,
     * no matter they are unregistered.
     * The Select fields having enabled `enableRemovedOptionFix` prop don't have this issue,
     * but just in case we are resetting all the fields to `null`.
     */
    resetForm: (data) => {
      return dispatch((dispatch, getState) => {
        const {
          form: {
            reportingFilters: { registeredFields },
          },
        } = getState()
        const formFields = Object.keys(registeredFields)

        formFields.forEach((field) => {
          // Reset all fields, except report type
          if (field === 'type') return

          dispatch(change(props.form, field, null))
        })
      })
    },
    onSaveReport: (report, selectedReport) =>
      dispatch(updateReport(report, true, true))
        .then(() => {
          dispatch(
            showMessage({
              body: 'Your report has been successfully saved. \n You can view your report in the Saved Reports tab.',
            })
          )
        })
        .catch((error) => dispatch(showMessage({ body: errorToString(error) }))),
    downloadReport: (id) => dispatch(downloadReport(id)),
    deleteReport: (id) =>
      dispatch(deleteReport(id, false))
        .then(() => dispatch(setLastCreatedJobId(null)))
        .then(() => dispatch(resetReports()))
        .then(() => {
          dispatch(
            showMessage({
              body: 'Report successfully deleted.',
            })
          )
        })
        .catch((error) => dispatch(showMessage({ body: errorToString(error) }))),
    setCurrency: () => {
      dispatch((dispatch, getState) => {
        const selector = formValueSelector(props.form)
        const selectedPayrolls = selector(getState(), 'payroll')
        let payrollCurrency
        if (isArray(selectedPayrolls) && selectedPayrolls.length) {
          payrollCurrency = uniq(selectedPayrolls.map((payroll) => getCurrencyByPayroll(getState(), { payrollId: payroll })))

          dispatch(change(props.form, 'payrollCurrency', payrollCurrency))
          dispatch(change(props.form, 'reportingCurrency', payrollCurrency.length === 1 ? payrollCurrency : null))
        }

        if (!isArray(selectedPayrolls) && selectedPayrolls) {
          payrollCurrency = getCurrencyByPayroll(getState(), { payrollId: selectedPayrolls })

          dispatch(change(props.form, 'payrollCurrency', [payrollCurrency]))
          dispatch(change(props.form, 'reportingCurrency', [payrollCurrency]))
        }
      })
    },
    setCurrencyByPayrollInstance: () => {
      dispatch((dispatch, getState) => {
        const selector = formValueSelector(props.form)
        const selectedPayrollInstances = selector(getState(), 'payrollInstance')

        let payrollCurrency
        if (isArray(selectedPayrollInstances) && selectedPayrollInstances.length) {
          payrollCurrency = uniq(
            selectedPayrollInstances.map((payroll) =>
              getCurrencyByPayrollInstance(getState(), {
                payrollInstanceId: payroll,
              })
            )
          )

          dispatch(change(props.form, 'payrollCurrency', payrollCurrency))
          dispatch(change(props.form, 'reportingCurrency', payrollCurrency.length === 1 ? payrollCurrency : null))
        }
      })
    },
    /*
     * Change only the reporting currency, based on predefined or custom fx rate
     */
    setReportingCurrencyDetails: (predefinedFxRateIds, customRatesFieldsValues) => {
      dispatch((dispatch, getState) => {
        const selector = formValueSelector(props.form)
        const values = selector(getState(), 'payrollCurrency', 'toCurrency', 'rate')

        const fxRates = predefinedFxRateIds && predefinedFxRateIds.length ? getFxRatesByIds(getState(), { fxRateIds: predefinedFxRateIds }) : null

        if (fxRates) {
          dispatch(change(props.form, 'reportingCurrency', uniq(fxRates.map((rate) => rate.toCurrency))))
          dispatch(change(props.form, 'reportingRate', predefinedFxRateIds))
          dispatch(change(props.form, 'fxRate', predefinedFxRateIds))
        } else if (values.toCurrency && values.rate) {
          dispatch(change(props.form, 'reportingCurrency', [values.toCurrency]))
          dispatch(change(props.form, 'reportingRate', values.rate))
        } else {
          const toCurrencyObj = getCurrencyById(getState(), { currencyId: values.toCurrency })
          const customRates = customRatesFieldsValues.map((customRate) => {
            const currencyObj = getCurrencyById(getState(), { currencyId: customRate.id })

            return `${currencyObj.abbreviature}->${toCurrencyObj.abbreviature} rate: ${customRate.value}`
          })
          dispatch(change(props.form, 'reportingCurrency', [values.toCurrency]))
          dispatch(change(props.form, 'reportingRate', customRates.join(', ')))
        }
      })
    },
    /*
     * Create report
     */
    onSubmit: (data, saved) => {
      // The Audit Trail Report has to include Payslip actions as well
      // BE requires a globalOwner filter to be sent with the payload to send them
      if (data.type === 'AuditTrailReport') data['globalOwner'] = ['Payslip']
      return dispatch(createNewReportJob(data, saved)).then(() => dispatch(resetReports()))
    },
    /**
     * Here we sync `subcategory`, `termCategory` fields values,
     * according to `combinedSubcategory` field.
     *
     * The business requirement (BR) is to combine the report subcategories with terms categories.
     * So the user should interact only with one dropdown,
     * despite the fact we have to send two fields to the back-end API (`subcategory` and `termCategory`).
     * Also the BR is that - the user can select one-or-many categories, but only from one type.
     *
     * Sometimes the pseudo code will illustrate the case better, so here are the two possible request scenarios,
     * expected by the back-end API:
     *
     * ```
     * {
     *   "subcategory": "TotalEmployerCosts"
     * }
     *
     * or
     *
     * {
     *    "subcategory": "Terms",
     *    "filters: {
     *        "termCategory": [1, 2]
     *    }
     * }
     * ```
     *
     * From programming point of view, the data should be represented by two different fields,
     * therefore there won't by any problems, BUT of course we have to fulfill the BR.
     *
     * In order to achieve the BR we created `combinedSubcategory` field, that lists the both types of categories.
     * `getCombinedSubcategories` selector is responsible for creating the combined list
     * and manipulating the option's characteristics,
     * as `label`, `value`, `disable`.
     *
     * The key moment is that, we register two more hidden fields in the form: `subcategory` and `termCategory`,
     * and here we update their values, according to the `combinedSubcategory` field.
     * Because of this, we send the required parameters to the back-end API.
     *
     * Please follow the code flow and comments,
     * in order to understand what exactly happens on a change event.
     */
    onCombinedSubcategoryChange: (values, fieldsToNotReset = []) => {
      values = values || []
      // Reset the following fields,
      // because in certain circumstances, their values are kept in the store,
      // even though they are hidden / unregistered.
      let fieldsToReset = [
        'showOnlyTotals',
        'fromDate',
        'toDate',
        'country',
        'company',
        'payroll',
        'termSubcategory',
        'term',
        'payrollCurrency',
        'reportingCurrency',
        'reportingRate',
      ]
      if (fieldsToNotReset) {
        fieldsToReset = fieldsToReset.filter((item) => !fieldsToNotReset.includes(item))
      }
      resetFormFields(values, fieldsToReset)
      // Get term categories
      // Because the both report's subcategories and term categories are combined in one list,
      // we have to add a prefix to one of the categories, in order to differentiate them.
      // That's the reason why we add a prefix for term categories (i.e. `Terms-`)
      const termCategories = values.filter((value) => value.includes('Terms-')).map((category) => parseInt(replace(category, /Terms-/g, '')))

      // Sync `subcategory` and `termCategory`.
      // Please keep in mind that the user can select one-or-many categories, but for only one category type.
      // So if there're selected `termCategories`,
      // then there won't be any selected report's subcategories.
      if (termCategories.length) {
        dispatch(change(props.form, 'subcategory', 'Terms'))
        dispatch(change(props.form, 'termCategory', termCategories))
      } else if (values.length) {
        dispatch(change(props.form, 'subcategory', values[0]))
        dispatch(change(props.form, 'termCategory', null))
      } else {
        dispatch(change(props.form, 'subcategory', null))
        dispatch(change(props.form, 'termCategory', null))
      }
    },
  }
}

const mapStateToProps = (state, props) => {
  const lockedInReportFields = props.reportDetails.getLockedReportTypes(props.selectedReport)

  const {
    currencies,
    terms,
    termCategories: termCategoriesEntity,
    termSubCategories,
    countryTermPivot,
    companyCountryTermPivot: companyCountryTermPivotEntity,
    countries,
  } = state

  const entities = [currencies, terms, termCategoriesEntity, termSubCategories, countryTermPivot, companyCountryTermPivotEntity, countries]

  if (isFetching(entities)) return { isFetching: true }

  const savedReport = getRegenerateSavedReport(props?.history)
  const selector = formValueSelector(props.form)
  const values = selector(
    state,
    'type',
    'category',
    'subcategory',
    'combinedSubcategory',
    'fxRate',
    'payrollCurrency',
    'toCurrency',
    'rate',
    'showOnlyTotals',
    'country',
    'modifiedBy'
  )

  const type = types.find((type) => type.type === values.type)
  // let hasElementsSelections = false
  const tenantEmployeeNetDeductions = getCompanyCountryTermsPivotByCategory(state, {
    categoryName: termCategories.employeeNetDeductions,
  })

  const catNames = ['Pay elements', 'Employer Contributions', 'Employee Deductions']
  if (tenantEmployeeNetDeductions.length) catNames.push('Employee Net Deductions')

  // Get all registered fields with prefix "customRate" and
  // get all values of fields with prefix "customRate" and
  // check if their number is equal in order to show the save button
  const customRatesRegisteredFields = []
  const customRatesFieldsNames = []
  const customRatesFieldsValues = []

  if (state.form[props.form]) {
    for (let keyRegistered in state.form[props.form].registeredFields) {
      if (keyRegistered.includes('customRate')) {
        customRatesRegisteredFields.push(keyRegistered)
      }
    }
    for (let keyValue in state.form[props.form].values) {
      if (keyValue.includes('customRate') && state.form[props.form].values[keyValue]) {
        customRatesFieldsNames.push(keyValue)
        customRatesFieldsValues.push({ id: parseInt(keyValue.split('-')[1]), value: state.form[props.form].values[keyValue] })
      }
    }
  }

  const scaleValidation = makeScaleValidation(6)
  const areAllCustomRatesFilled = customRatesRegisteredFields.length === customRatesFieldsNames.length && customRatesFieldsNames.length > 0
  // In the case  there's a validation error,
  // `scaleValidation` will return the error as a String (redux-form requirement).
  // Otherwise (no error) - it returns `undefined`.
  // Because of this - we determine if there is an error, if some of the values is different from `undefined`.
  const areCustomRatesValuesValid = !customRatesFieldsValues.some((rate) => scaleValidation(rate.value) !== undefined)

  // In the case a single value is selected, the same logic as above is applied here too.
  const isCustomRateValueValid = !(scaleValidation(values.rate) !== undefined)
  const shouldShowCheckboxOptionsReports = ['PayrollReport', 'PayrollInstanceReport', 'EmployeeDataChangesReport', 'GlobalPayrunManagementReport']
  const shouldShowCheckboxOptions = shouldShowCheckboxOptionsReports.includes(values.type)

  const { initValues, hasElementsSelections } = buildUpInitialValuesForReports(state, lockedInReportFields, values, catNames)
  const user = !isCot(state) && getUser(state, { userId: state.auth.userId })
  const userHasAccessToChangeLogReport = isCot(state) || user.specialRight.accessChangeLogReport
  const reportsTypes = userHasAccessToChangeLogReport ? types : types.filter((t) => t.type !== 'ChangeLogReport')
  const job = state.pollingJob.lastCreatedId ? getMostRecentJobById(state, props.selectedReport) : []
  let reportId
  let mostRecentRunReport = null
  if (job?.length > 0) {
    reportId = job[0].entityId
    props.dispatch(fetchReportsIfNeeded())
  }
  mostRecentRunReport = !isEmpty(state.reports.filters) && !state.reports.isFetching && reportId && job ? getMostRecentRunReportByTypeID(state, reportId) : null

  return {
    initialValues: getInitialReportData({
      state,
      reportType: props.selectedReport,
      initValues,
      savedReport,
    }),
    mostRecentRunReport,
    readonlyFields: lockedInReportFields.readonlyFieldsObj,
    fieldsNotToReset: lockedInReportFields.fieldsNotToReset,
    lockedInReportFields: lockedInReportFields,
    // All selected values
    selected: values,
    // Type is required to display categories and subcategories described in report config
    categories: values.type ? type.categories : [],
    isCategoriesDisabled: isEmpty(values.type),
    subcategories: values.type ? type.subcategories.filter((subcategory) => subcategory.category === values.category) : [],
    isSubCategoriesDisabled: isEmpty(values.category),
    isPayrollReport: values.type ? type.type === 'PayrollReport' : false,
    isPayrollInstanceReport: values.type ? type.type === 'PayrollInstanceReport' : false,
    isEmployeeDataChangesReport: values.type ? type.type === 'EmployeeDataChangesReport' : false,
    shouldShowCheckboxOptions,
    // Please refer to `onCombinedSubcategoryChange` for more details,
    // about `combinedSubcategory, subcategory, termCategory` fields
    combinedSubcategories:
      values.type && values.category
        ? getCombinedSubcategories(state, {
          type: values.type,
          subcategory: values.subcategory,
          catNames,
        })
        : [],
    selectedType: values.type,
    types: reportsTypes,
    currencies: getCurrencies(state),
    areAllCustomRatesFilled,
    areCustomRatesValuesValid,
    isCustomRateValueValid,
    customRatesFieldsNames,
    customRatesFieldsValues,
    showRunReportBtn: true,
    navigateToParentOnClick: props.navigateToParentOnClick,
    hasAccess: hasAccess(state)(['REPORT_CREATE']),
    hasElementsSelections,
  }
}

ReportingFiltersContainer.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  hasAccess: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    // Because of the validation of custom rates and showing/hiding Save button depending on errors existing,
    // we want to show fields errors as soon as possible.
    // Otherwise - on an error, the button is hidden and no error is being shown.
    touchOnChange: true,
    enableReinitialize: true,
    touchOnBlur: true,
  })(ReportingFiltersContainer)
)
