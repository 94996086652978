import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import MessageModal from 'components/MessageModal'
import FieldEmployees from 'components/employees/FieldEmployees'
import ConfirmationModal from 'components/ConfirmationModal'
import CustomSelectField from 'components/form/select/CustomSelectField'
import {
  LEFT_CHEVRON,
  RIGHT_CHEVRON,
  LEFT_DOUBLE_CHEVRON,
  RIGHT_DOUBLE_CHEVRON
} from 'utils/enums/employeePaginationChevrons'
import { setPaginationControl } from 'utils/helperClasses/Pagination'
let classNames = require('classnames')
class PayrollPaygroupEmployees extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectAll: true,
      confirmButtonDisabled: true,
      pagination: {
        perPage: 24,
        currentPage: 1
      }
    }
  }

  getPaginationTotalPagesCount () {
    const { pagination: { perPage } } = this.state
    const { employeeSystemUsers } = this.props

    return Math.ceil(employeeSystemUsers.length / perPage)
  }

  renderPagination () {
    const { pagination: { currentPage } } = this.state
    const totalPages = this.getPaginationTotalPagesCount()
    if (totalPages === 1) return null

    let pageNeighbours = 2
    const totalNumbers = (pageNeighbours * 2) + 0
    const totalBlocks = totalNumbers + 1
    const pages = setPaginationControl(totalPages, currentPage, pageNeighbours, totalNumbers, totalBlocks)

    return <div className='c-custom-pagination u-float--left u-margin-none'>
      <ul>
        {
          pages.map((page, index) => {
            if (page === LEFT_CHEVRON) {
              return (
                <li
                  key={index}
                  className='c-custom-pagination__item u-cursor--pointer'
                  onClick={() => {
                    this.setState({
                      pagination: {
                        ...this.state.pagination,
                        currentPage: currentPage - 1
                      }
                    })
                  }}
                >
                  <span aria-hidden='true'>{'<'}</span>
                  <span className='sr-only'>Previous</span>
                </li>
              )
            }
            if (page === LEFT_DOUBLE_CHEVRON) {
              return (
                <li
                  key={index}
                  className='c-custom-pagination__item u-cursor--pointer'
                  onClick={() => {
                    this.setState({
                      pagination: {
                        ...this.state.pagination,
                        currentPage: 1
                      }
                    })
                  }}
                >
                  <span aria-hidden='true'>{'<<'}</span>
                  <span className='sr-only'>Go to first page</span>
                </li>
              )
            }
            if (page === RIGHT_CHEVRON) {
              return (
                <li
                  key={index}
                  className='c-custom-pagination__item u-cursor--pointer'
                  onClick={() => {
                    this.setState({
                      pagination: {
                        ...this.state.pagination,
                        currentPage: currentPage + 1
                      }
                    })
                  }}
                >
                  <span> {'>'} </span>
                  <span className='sr-only'>Next</span>
                </li>
              )
            }
            if (page === RIGHT_DOUBLE_CHEVRON) {
              return (
                <li key={index} className='c-custom-pagination__item u-cursor--pointer'
                  onClick={() => {
                    this.setState({
                      pagination: {
                        ...this.state.pagination,
                        currentPage: totalPages
                      }
                    })
                  }}
                >
                  <span>{'>>'}</span>
                  <span className='sr-only'>Go to last page</span>
                </li>
              )
            }
            if (currentPage === page
            ) {
              return <span key={index} className='c-custom-pagination__item c-custom-pagination__item--active'>
                {page}
              </span>
            }

            return (
              <li
                type='button'
                key={index}
                className={classNames({
                  'c-custom-pagination__item u-cursor--pointer': true,
                  'c-custom-pagination__item--clicked': this.state.candidateCurrentPage === page
                })}
                onClick={() => {
                  this.setState({
                    pagination: {
                      ...this.state.pagination,
                      currentPage: page
                    }
                  })
                }}
              >
                { page }
              </li>
            )
          })
        }
      </ul>
    </div>
  }

  getEmployees () {
    const { pagination: { perPage, currentPage } } = this.state
    const { employeeSystemUsers } = this.props

    const end = perPage * currentPage
    const start = end - perPage

    return employeeSystemUsers.slice(start, end)
  }

  getConfirmMessage (content) {
    const { employeesWithDifferTerms } = this.props

    // show panel only if there are employees with different terms
    if (employeesWithDifferTerms && employeesWithDifferTerms.length) {
      return <div>
        <p>
          Fixed Pay Element values are different, the paygroup ones will overwrite the employee ones.
        </p>
        <p>Do you agree to overwrite for the following employees?</p>

        <ul className='o-list-bare'>
          {employeesWithDifferTerms.map((employee, index) => (
            <li key={index} className='u-weight--medium'>{employee.getName()}</li>
          ))}
        </ul>
      </div>
    }

    return <p>{content}</p>
  }

  showModal () {
    if (this.props.hasEmployees()) {
      this.refs.confirmModal.showModal()
    } else {
      this.refs.messageModal.showModal()
    }
  }

  checkValue = (value, option) => {
    const hasSelectedOption = option || false
    this.setState({ confirmButtonDisabled: !hasSelectedOption })
  }

  render () {
    const {
      toggleAll,
      handleSubmit,
      payrollInstancePayPeriod,
      showPagination,
      employeeSystemUsers,
      isFetching,
      content,
      buttonName,
      ...rest
    } = this.props
    const isOnceOffPayroll = payrollInstancePayPeriod === 'Once off'

    const pagination = this.renderPagination()

    if (isFetching) return <div>Loading...</div>

    if (!employeeSystemUsers.length) return <div>Currently, there are no employees</div>

    return <span>
      <ConfirmationModal
        ref='confirmModal'
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={handleSubmit}
        confirmButtonText={isOnceOffPayroll ? 'Confirm' : 'Yes'}
        rejectButtonText={isOnceOffPayroll ? 'Cancel' : 'No'}
        disableConfirmButton={isOnceOffPayroll && this.state.confirmButtonDisabled}
      >
        {this.getConfirmMessage(content)}

        {
          isOnceOffPayroll &&
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small u-margin-large u-text--left'>
            <Field
              name='prePopulatedData'
              label=''
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--left'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              options={[
                {
                  value: 'all',
                  label: 'Both Fixed Pay Elements and Recurring Deductions'
                },
                {
                  value: 'payElements',
                  label: 'Fixed Pay Elements only'
                },
                {
                  value: 'recurringDeductions',
                  label: 'Recurring Deductions only'
                },
                {
                  value: 'no',
                  label: 'None'
                }
              ]}
              data-testid='prepopulated-data-wrapper'
              onChange={this.checkValue}
            />
          </div>
        }

      </ConfirmationModal>
      <MessageModal
        ref='messageModal'
        className='c-modal'
        modalHeading='Oops...'
        message='No employees selected. Please select an employee to continue'
      />
      <div className='o-grid o-grid--middle o-grid--center'>
        <button
          onClick={() => this.showModal()}
          className='c-btn c-btn--small c-btn--curious'
          data-testid='choose-single-button'>
          {buttonName}
        </button>
        <button
          onClick={() => {
            this.setState({ selectAll: !this.state.selectAll })
            toggleAll(this.state.selectAll, employeeSystemUsers)
          }}
          className='c-btn c-btn--small c-btn--curious u-margin-left'
          data-testid='choose-all-button'>
          {this.state.selectAll ? 'Select all' : 'Deselect all'}
        </button>
      </div>
      <div className='o-layout__item u-1/1 u-margin-top-small'>
        <div className='u-float--right'>
          { pagination }
        </div>
      </div>
      <form>
        <FieldEmployees slicedEmployeeSystemUsers={this.getEmployees()} {...rest} />
      </form>
      <div className='o-layout__item u-1/1 u-margin-top-small'>
        <div className='u-float--right'>
          { pagination }
        </div>
      </div>
    </span>
  }
}

PayrollPaygroupEmployees.propTypes = {
  toggleAll: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  hasEmployees: PropTypes.func,
  employeesWithDifferTerms: PropTypes.array,
  employeeSystemUsers: PropTypes.array,
  payrollInstancePayPeriod: PropTypes.string,
  showPagination: PropTypes.bool,
  buttonName: PropTypes.string,
  isFetching: PropTypes.bool,
  content: PropTypes.string
}

export default PayrollPaygroupEmployees
