export const categoriesTextLabel = {
  personalDetails: 'Personal Details',
  biographicalDetails: 'Biographical Details',
  contactDetails: 'Contact Details',
  addressDetails: 'Address Details',
  bankDetails: 'Bank Details',
  emergencyPersonContactDetails: 'Emergency Person Contact Details',
  taxAndIdDetails: 'Tax & ID Details',
  contractorsDetails: 'Contractors Details',
  employmentDetails: 'Employment Details',
  jobProfile: 'Job Profile',
  organization: 'Organization',
  employeeSelfService: 'Employee Self Service',
  payElements: 'Pay Elements',
  employeeDeductions: 'Employee Deductions',
  employeeNetDeductions: 'Employee Net Deductions',
  employerContributions: 'Employer Contributions',
  informationalElements: 'Informational Elements',
}
